import React from "react";
import Header1 from "../../Navigations/header/header1";
import Sidebar2 from "../../Navigations/sidebar/sidebar2";
import Footer1 from "../../Navigations/footer/footer1";

function ImportSpeaker() {
  return (
    <>
      <div className="body-main bg-grey">
        <Header1/>
        <div className="body-page">
          <div className="d-flex">
            <div className="sidebar-main">
              <Sidebar2/>
            </div>
            <div className="content-main">
              <div className="min-height-pagecontent">
                <div className="d_import_box">
                  <p>
                    {" "}
                    To import contacts to your Speaker List, "Download" the
                    blank spreadsheet, copy and paste the data in the column,
                    save the file. Once the data file ready to be imported,
                    click on "Import" and point to the file."
                  </p>
                </div>
                <div className="row d_justify_content_center">
                  <div className="col-4 d_import_iconbox1">
                    <i className="bi bi-cloud-arrow-down-fill" />
                    <p className="d_font_size14">
                      <b>Download Blank Spreadsheet</b>
                    </p>
                    <a>Download</a>
                  </div>
                  <div className="col-4 d_import_iconbox2">
                    <i className="bi bi-cloud-arrow-up-fill" />
                    <input
                      type="file"
                      name="image"
                      className="image"
                      id="upload_image"
                      style={{ display: "none" }}
                    />
                    <p className="d_font_size14">
                      <b>Upload Contact Spreadsheet</b>
                    </p>
                    <label htmlFor="upload_image" className="form-upload">
                      <a id="uploaded_image">Import</a>
                      <input
                        type="file"
                        name="image"
                        className="image"
                        id="upload_image"
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                </div>
                <div className="d_import_box">
                  <p className="d_margin_bottom20">
                    To update your current contact information, you can download
                    the current speaker list, update the information and
                    "import" that file to update information.
                  </p>
                  <p>
                    {" "}
                    To import new contacts, the mandatory fields are "Name" and
                    "E-Mail address."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-2">
       <Footer1/>
      </div>
    </>
  );
}

export default ImportSpeaker;
