import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Recaptcha = ({ onChange }) => {
  // Accessing the environment variable with a fallback value
  const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <ReCAPTCHA
      sitekey={reCaptchaSiteKey}
      onChange={onChange}
    />
  );
};

export default Recaptcha;
