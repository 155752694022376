import { executeGET, executePost } from "../API/ServicesMethods";

// SignUpApi
export const SignUpApi = async (data) => {
  try {
    const SignUp = await executePost("speaker-engage/signup", data);
    const SignUpData = SignUp.data ? SignUp.data : [];
    return SignUpData;
  } catch (error) {
    return false;
  }
};

