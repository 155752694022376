import React from "react";

function ExternalApplyToSpeaker() {
  return (
    <>
      <div className="external-form-paghe">
        <div className="row">
          <div className="col-md-9 offset-md-2">
            <div className="row">
              <div className="col-md-3 pt-3">
                <div className="setp-main mt-5">
                  <div className="step-inner">
                    <p className="step-fill">1</p>
                    <p className="step-text">About Me</p>
                    <p className="step-arrow">
                      <i className="bi bi-caret-right-fill sky-blue" />
                    </p>
                  </div>
                  <div className="step-inner">
                    <p className="step-nofill">2</p>
                    <p className="step-text">Additional Questions</p>
                    <p className="step-arrow">
                      <i className="bi bi-caret-right-fill white" />
                    </p>
                  </div>
                  <div className="step-inner">
                    <p className="step-nofill">3</p>
                    <p className="step-text">Acknowledgement</p>
                    <p className="step-arrow">
                      <i className="bi bi-caret-right-fill white" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <p className="external-form-title">
                  Apply to Speak at Test Event 2024 FEB
                </p>
                <div>
                  <form className="external-form-main mt-4" id="step1">
                    <div className="row">
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Name<span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="John Doe"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            E-mail <span className="red">*</span>
                          </label>
                          <input
                            type="email"
                            id="startDate"
                            className="form-control sp-form-input"
                            placeholder="name@mail.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Phone Number <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="Ex: (123) 456-7890"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Company <span className="red">*</span>
                          </label>
                          <input
                            type="email"
                            id="startDate"
                            className="form-control sp-form-input"
                            placeholder="Company Name"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Job Title <span className="red">*</span>
                          </label>
                          <input
                            type="email"
                            id="startDate"
                            className="form-control sp-form-input"
                            placeholder="Job Title"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Your Bio <span className="red">*</span>
                          </label>
                          <textarea
                            className="form-control sp-form-input"
                            placeholder="Let us get to know you"
                            rows={7}
                            defaultValue={"                                   "}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <div className="upload-label">
                            <label className="form-label sp-form-label">
                              Upload Headshot <span className="red">*</span>
                            </label>
                          </div>
                          <div className="image_area sp-form-uplopad">
                            <label
                              htmlFor="upload_image"
                              className="form-upload"
                            >
                              <img
                                src="{{ url_for('static', filename='images/cloud-computing.png') }}"
                                id="uploaded_image"
                                className="img-responsive img-circle"
                              />
                              <input
                                type="file"
                                name="image"
                                className="image"
                                id="upload_image"
                                style={{ display: "none" }}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Your Quote <span className="red">*</span>
                          </label>
                          <textarea
                            className="form-control sp-form-input"
                            placeholder="An inspirational quote you’d like to share with us"
                            rows={5}
                            defaultValue={"                                   "}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Address Line1 <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="House Number, Street"
                          />
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Address Line2 <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="Village/Country"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            City <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="City"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            State <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="State or Region"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Country <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="Country"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="form-title pb-3">Speaker Manager Details</p>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Speaker Manager Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Speaker Manager Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control sp-form-input"
                            placeholder="name@email.com"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Speaker Manager Phone Number
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="Ex: (123) 456-7890"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="form-title pb-3">Social Media Information</p>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            LinkedIn Profile <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Twitter Account <span className="red">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control sp-form-input"
                            placeholder="name@email.com"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Facebook Profile or Page{" "}
                            <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="Ex: (123) 456-7890"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Instagram Profile
                          </label>
                          <input
                            type="text"
                            className="form-control sp-form-input"
                            placeholder="Ex: (123) 456-7890"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <p class="form-title pb-3">Are you willing to promote this event to your community?</p>
                              <div class="d-flex mb-4 pb-3">
                                  <label class="radio-container">yes
                                      <input type="radio" checked="checked" name="radio">
                                      <span class="checkmark"></span>
                                    </label>
                                    <label class="radio-container">No
                                      <input type="radio" name="radio">
                                      <span class="checkmark"></span>
                                    </label>
                              </div>
                              <p class="form-title pb-3">What would you like us to provide you?</p>
                              <div class="row">
                                  <div class="col-4">
                                  <label class="check-container">Social Media Promo Kit
                                      <input type="checkbox" checked="checked">
                                      <span class="check-checkmark"></span>
                                    </label>
                                    </div>
                                    <div class="col-3">
                                    <label class="check-container">Discount Code
                                      <input type="checkbox">
                                      <span class="check-checkmark"></span>
                                    </label>
                                    </div>
                                    <div class="col-2">
                                    <label class="check-container">Flyer
                                      <input type="checkbox">
                                      <span class="check-checkmark"></span>
                                    </label>
                                    </div>
                                    <div class="col-3">
                                    <label class="check-container">Press Kit
                                      <input type="checkbox">
                                      <span class="check-checkmark"></span>
                                    </label>
                                    </div>
                              </div> */}
                    <div className="d-flex justify-content-center my-3">
                      <button type="submit" className="submit-btn">
                        NEXT
                      </button>
                    </div>
                  </form>
                  <form className="external-form-main mt-4" id="step2">
                    <div className="d-flex justify-content-center mt-5">
                      <button
                        type="submit"
                        className="submit-btn bg-violet margin-right-10"
                      >
                        PREVIOUS
                      </button>
                      <button type="submit" className="submit-btn">
                        NEXT
                      </button>
                    </div>
                  </form>
                  <form className="external-form-main mt-4" id="step3">
                    <p className="form-title pb-3">Acknowledgement</p>
                    <p className="ack-text mb-3">
                      Speakers acknowledge and agree that the Summit organizer
                      may take photographs/videos which could include images of
                      the Speaker while attending the Exhibition and Conference
                      (the 'Images'). The Speaker hereby consents to and grants
                      to the organizer, unrestricted, irrevocable, perpetual,
                      worldwide, royalty free and transferable right and license
                      to use (and grant others the right to use) the Images
                      worldwide without compensation.
                    </p>
                    <div className="row">
                      <div className="col-4">
                        <label className="check-container">
                          I Agree
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="check-checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                      <button
                        type="submit"
                        className="submit-btn bg-violet margin-right-10"
                      >
                        PREVIOUS
                      </button>
                      <button type="submit" className="submit-btn">
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExternalApplyToSpeaker;
