import React from 'react'
import Header1 from '../../Navigations/header/header1'
import Sidebar2 from '../../Navigations/sidebar/sidebar2'
import Footer1 from '../../Navigations/footer/footer1'
import editIcon from '../../../Assets/images/edit.svg';
import deleteIcon from '../../../Assets/images/delete.svg';

function SpeakerType() {
  return (
    <>
    <div className="body-main bg-grey">
  <Header1/>
  <div className="body-page">
    <div className="d-flex">
      <div className="sidebar-main">
       <Sidebar2/>
      </div>
      <div className="content-main">
        <div className="min-height-pagecontent">
          <div className="spk-main">
            <div className="spk-1">
              <div className="spk-inner-left">
                <p className="spk-title">
                  Sanity Check : Speaker Type Settings
                </p>
                <button
                  type="button"
                  className="no-btn"
                  s-toggle="tooltip"
                  data-bs-trigger="manual"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-html="true"
                  customclass="testr"
                  title="This feature lets you to create and manage speaker types. Based on the sessions that you are planning for your event, you can create new settings and associate speakers with those session types. To learn more, please refer to FAQ page."
                >
                  <i className="bi bi-info-circle sky-blue" />
                </button>
              </div>
              <div className="spk-inner-right">
                <a href="" className="violet-btn margin-right-10">
                  <i className="bi bi-pencil-fill padding-bootom-2" /> Create
                  New Speaker Type
                </a>
              </div>
            </div>
            <div className="spk-3 mt-4 d-none">
              <form>
                <div className="d-flex">
                  <button className="red-btn margin-right-10">
                    <i className="bi bi-trash padding-bootom-2" /> Delete
                    Selected
                  </button>
                  <select
                    className="form-select w-200 spk-select"
                    aria-label="Default select example"
                  >
                    <option selected=""> Change Status</option>
                    <option value={1}>One</option>
                    <option value={2}>Two</option>
                    <option value={3}>Three</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="spk-4 my-0 d_email_status_page">
              <table
                id="all-speakers"
                className="    stripe row-border order-column nowrap custom-table d_email_status_table"
                style={{ width: "100%" }}
              >
                <thead>
                 
                    <th />
                    <th className="d_width_400">Speaker Type</th>
                    <th className="d_width_400">#Speakers</th>
                    <th className="d_manage_before">Manage</th>
             
                </thead>
                <tbody>
                  <tr>
                    <td />
                    <td className="d_width_300 d_font_size14">Moderator</td>
                    <td className="d_font_size14">
                      <a>0</a>
                    </td>
                    <td>
                      <div className="action">
                        <a href="#" className="d_opacity">
                          <img src={editIcon} title="Edit" />
                        </a>
                        <a href="#" className="d_opacity">
                          <img src={deleteIcon} title="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td className="d_width_300 d_font_size14">Other</td>
                    <td className="d_font_size14">0</td>
                    <td>
                      <div className="action">
                        <a href="#" className="d_opacity">
                          <img src={editIcon} title="Edit" />
                        </a>
                        <a href="#" className="d_opacity">
                          <img src={deleteIcon} title="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td className="d_width_300 d_font_size14">
                      Executive Roundtable
                    </td>
                    <td className="d_font_size14">0</td>
                    <td>
                      <div className="action">
                        <a href="#" className="d_opacity">
                          <img src={editIcon} title="Edit" />
                        </a>
                        <a href="#" className="d_opacity">
                          <img src={deleteIcon} title="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td className="d_width_300 d_font_size14">
                      Immersive Experience
                    </td>
                    <td className="d_font_size14">0</td>
                    <td>
                      <div className="action">
                        <a href="#" className="d_opacity">
                          <img src={editIcon} title="Edit" />
                        </a>
                        <a href="#" className="d_opacity">
                          <img src={deleteIcon} title="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td className="d_width_300 d_font_size14">Power Talks</td>
                    <td className="d_font_size14">0</td>
                    <td>
                      <div className="action">
                        <a href="#" className="d_opacity">
                          <img src={editIcon} title="Edit" />
                        </a>
                        <a href="#" className="d_opacity">
                          <img src={deleteIcon} title="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td className="d_width_300 d_font_size14">Breakouts</td>
                    <td className="d_font_size14">0</td>
                    <td>
                      <div className="action">
                        <a href="#" className="d_opacity">
                          <img src={editIcon} title="Edit" />
                        </a>
                        <a href="#" className="d_opacity">
                          <img src={deleteIcon} title="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td className="d_width_300 d_font_size14">Workshop</td>
                    <td className="d_font_size14">0</td>
                    <td>
                      <div className="action">
                        <a href="#" className="d_opacity">
                          <img src={editIcon} title="Edit" />
                        </a>
                        <a href="#" className="d_opacity">
                          <img src={deleteIcon} title="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td className="d_width_300 d_font_size14">Power Panel</td>
                    <td className="d_font_size14">0</td>
                    <td>
                      <div className="action">
                        <a href="#" className="d_opacity">
                          <img src={editIcon} title="Edit" />
                        </a>
                        <a href="#" className="d_opacity">
                          <img src={deleteIcon} title="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td className="d_width_300 d_font_size14">Keynote</td>
                    <td className="d_font_size14">0</td>
                    <td>
                      <div className="action">
                        <a href="#" className="d_opacity">
                          <img src={editIcon} title="Edit" />
                        </a>
                        <a href="#" className="d_opacity">
                          <img src={deleteIcon} title="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="footer-2">
          <Footer1/>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default SpeakerType
