import React, { useState } from "react";
import speakerEngageLogo from "../../Assets/images/Speaker-Engage-Logo-svg.svg";
import { CaptchaCheck, EmailCheck, FirstNameCheck, LastNameCheck } from "../Utils/Validation";
import Loader from "../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import { SignUpApi } from "../Utils/API/ApiCall";
import Recaptcha from "../Components/Recaptcha";
import { useNavigate } from "react-router-dom";


function SignUp() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [first_name, setFirstName] = useState("");
  const [firstname_validation, setFirstnameValidation] = useState("");

  const [last_name, setLastName] = useState("");
  const [lastname_validation, setLastnameValidation] = useState("");

  const [email_address, setEmailAddress] = useState("");
  const [email_validation, setEmailValidation] = useState("");

  const [catpcha, setCaptcha] = useState(null);
  const [captcha_validation, setCaptchaValidation] = useState("")

  const onChange = (value) =>{
    setCaptcha(value)
  }

  const handleSignUp = async () => {
    setLoading(true);
    const firstNameValidation = FirstNameCheck(first_name);
    const lastNameValidation = LastNameCheck(last_name);
    const emailValidation = EmailCheck(email_address);
    const captchaValidation = CaptchaCheck(catpcha)

    if (firstNameValidation.err_display) {
      setFirstnameValidation(firstNameValidation.err_display);
    } else {
      setFirstnameValidation("");
    }

    if (lastNameValidation.err_display) {
      setLastnameValidation(lastNameValidation.err_display);
    } else {
      setLastnameValidation("");
    }

    if (emailValidation.err_display) {
      setEmailValidation(emailValidation.err_display);
    } else {
      setEmailValidation("");
    }


    if(captchaValidation.err_display) {
      setCaptchaValidation(captchaValidation.err_display)
    } else {
      setCaptchaValidation("")
    }




    if (
      firstNameValidation.validition &&
      lastNameValidation.validition &&
      emailValidation.validition && captchaValidation.validition
    ) {
      const data = {
        first_name:first_name, 
        last_name: last_name,    
        email:email_address,
        g_recaptcha_response:catpcha
      }
      const Response = await SignUpApi(data)
      console.log(Response,"Response")
      if(Response.status == 200){
        toast.success(Response.message);
        localStorage.setItem("UserEmailId",email_address)
        setTimeout(() => {
          navigate("/email-verify");
        }, 3000);
      }
      else{
        toast.error(Response.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="main d-flex align-items-center justify-content-center bg-gray signinmain main-height">
        <div className="container">
          <div className="row wrapper2 text-center">
            <p>
              <a href="https://www.speakerengage.com/">
                <img src={speakerEngageLogo} className="whitelogo-login" />
              </a>
            </p>
          </div>
          <div className="row wrapper1">
            <div className="col signinleft border-radius-25">
              <div className="signinleftinner w-300">
                <p className="signin-text">Welcome! Sign up here</p>
                <p className="error-msg" id="error-msg" />
                <div className="email-login">
                  <div className="mb-3">
                    <label className="sign-label">First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      id="first_name"
                      className="form-control signin-input"
                      onChange={(e) => [
                        setFirstName(e.target.value),
                        setFirstnameValidation(""),
                      ]}
                    />
                    {firstname_validation ? (
                      <p className="error-msg" id="error-msg-fname">
                        {firstname_validation}
                      </p>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label className="sign-label">Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      id="last_name"
                      className="form-control signin-input"
                      onChange={(e) => [
                        setLastName(e.target.value),
                        setLastnameValidation(""),
                      ]}
                    />
                    {lastname_validation ? (
                      <p className="error-msg" id="error-msg-lname">
                        {lastname_validation}
                      </p>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label className="sign-label">Email address</label>
                    <input
                      type="text"
                      placeholder="you@example.com"
                      name="email"
                      id="email"
                      className="form-control signin-input"
                      onChange={(e) => [
                        setEmailAddress(e.target.value),
                        setEmailValidation(""),
                      ]}
                    />
                    {email_validation ? (
                      <p className="error-msg" id="error-msg-email">
                        {email_validation}
                      </p>
                    ) : null}
                  </div>
                  <div className="mb-3">
                  <Recaptcha onChange={onChange}/>
                    {
                      captcha_validation ? (
                        <p className="error-msg">
                          {captcha_validation}
                        </p>
                      )  : null
                    }
                    </div>
                    
                    
                  <button
                    type="submit"
                    id="signupBtn"
                    className="signin-submit"
                    onClick={() => (!loading ? handleSignUp() : null)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
