import React from "react";
import Header1 from "../../Navigations/header/header1";
import Sidebar2 from "../../Navigations/sidebar/sidebar2";
import Footer1 from "../../Navigations/footer/footer1";
import editIconImg from "../../../Assets/images/edit.svg";
import deleteIconImg from "../../../Assets/images/delete.svg";

function StatusSettings() {
  return (
    <>
      <div className="body-main bg-grey">
        <Header1 />
        <div className="body-page">
          <div className="d-flex">
            <div className="sidebar-main">
              <Sidebar2 />
            </div>
            <div className="content-main">
              <div className="min-height-pagecontent">
                <div className="spk-main">
                  <div className="spk-1">
                    <div className="spk-inner-left">
                      <p className="spk-title">
                        Sanity Check : Speaker Status Settings
                      </p>
                      <button
                        type="button"
                        className="no-btn"
                        s-toggle="tooltip"
                        data-bs-trigger="manual"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-html="true"
                        customclass="testr"
                        title="<p>This feature lets you to create additional status to categorize your speakers for this event. To learn more, please refer to FAQ page."
                      >
                        <i className="bi bi-info-circle sky-blue" />
                      </button>
                    </div>
                    <div className="spk-inner-right">
                      <a
                        href="/new-status"
                        className="violet-btn margin-right-10"
                      >
                        <i className="bi bi-pencil-fill padding-bootom-2" />{" "}
                        Create New Status
                      </a>
                    </div>
                  </div>
                  <div className="spk-3 mt-4 d-none">
                    <form>
                      <div className="d-flex">
                        <button className="red-btn margin-right-10">
                          <i className="bi bi-trash padding-bootom-2" /> Delete
                          Selected
                        </button>
                        <select
                          className="form-select w-200 spk-select"
                          aria-label="Default select example"
                        >
                          <option selected=""> Change Status</option>
                          <option value={1}>One</option>
                          <option value={2}>Two</option>
                          <option value={3}>Three</option>
                        </select>
                      </div>
                    </form>
                  </div>
                  <div className="spk-4 my-0 d_email_status_page">
                    <table
                      id="all-speakers"
                      className="    stripe row-border order-column nowrap custom-table d_email_status_table"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        
                          <th />
                          <th className="d_width_400">Status Type</th>
                          <th className="d_width_400">Speakers</th>
                          <th
                            className="d_manage_before
                "
                          >
                            Manage
                          </th>
                        
                      </thead>
                      <tbody>
                        <tr>
                          <td />
                          <td className="d_width_300 d_font_size14">
                            Approved
                          </td>
                          <td className="d_font_size14">
                            <a
                              data-bs-toggle="modal"
                              data-bs-target="#Modal10"
                              className="sky-blue d_cursor_pointer"
                            >
                              1
                            </a>
                          </td>
                          <td>
                            <div className="action">
                              <a href="#" className="d_opacity">
                                <img
                                  src={editIconImg}
                                  title="Edit"
                                />
                              </a>
                              <a href="#" className="d_opacity">
                                <img
                                  src={deleteIconImg}
                                  title="Delete"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td className="d_width_300 d_font_size14">
                            In Contract
                          </td>
                          <td className="d_font_size14">0</td>
                          <td>
                            <div className="action">
                              <a href="#" className="d_opacity">
                                <img
                                  src={editIconImg}
                                  title="Edit"
                                />
                              </a>
                              <a href="#" className="d_opacity">
                                <img
                                  src={deleteIconImg}
                                  title="Delete"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td className="d_width_300 d_font_size14">
                            In-Review
                          </td>
                          <td className="d_font_size14">0</td>
                          <td>
                            <div className="action">
                              <a href="#" className="d_opacity">
                                <img
                                  src={editIconImg}
                                  title="Edit"
                                />
                              </a>
                              <a href="#" className="d_opacity">
                                <img
                                  src={deleteIconImg}
                                  title="Delete"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td className="d_width_300 d_font_size14">New</td>
                          <td className="d_font_size14">0</td>
                          <td>
                            <div className="action">
                              <a href="#" className="d_opacity">
                                <img
                                  src={editIconImg}
                                  title="Edit"
                                />
                              </a>
                              <a href="#" className="d_opacity">
                                <img
                                  src={deleteIconImg}
                                  title="Delete"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td className="d_width_300 d_font_size14">
                            Rejected
                          </td>
                          <td className="d_font_size14">0</td>
                          <td>
                            <div className="action">
                              <a href="#" className="d_opacity">
                                <img
                                  src={editIconImg}
                                  title="Edit"
                                />
                              </a>
                              <a href="#" className="d_opacity">
                                <img
                                  src={deleteIconImg}
                                  title="Delete"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td className="d_width_300 d_font_size14">
                            Research
                          </td>
                          <td className="d_font_size14">0</td>
                          <td>
                            <div className="action">
                              <a href="#" className="d_opacity">
                                <img
                                  src={editIconImg}
                                  title="Edit"
                                />
                              </a>
                              <a href="#" className="d_opacity">
                                <img
                                  src={deleteIconImg}
                                  title="Delete"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className="modal fade d_modal "
                id="Modal10"
                tabIndex={-1}
                aria-labelledby="Modal10"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md modal-dialog-centered">
                  <div className="modal-content d_padding_10">
                    <div className="modal-header d_light_yellow_border">
                      <h6
                        className="d_font_family text-center d_margin_auto modal-title"
                        id="Modal10"
                      >
                        Speaker List
                      </h6>
                      <button
                        type="button"
                        className="d_modal_closebtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x pink" />
                      </button>
                    </div>
                    <div className="modal-body d_padding_bottom0">
                      <div className="row">
                        <div className="col-12">
                          <table className="table table-responsive d-whitetable">
                            <thead className="d-whitetable-header">
                              <tr>
                                <th>
                                  <p>
                                    <b>Speaker Name</b>
                                  </p>
                                </th>
                                <th>
                                  <p>
                                    <b>Company</b>
                                  </p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <p>Prajna Shetty</p>
                                </td>
                                <td>
                                  <p />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-2">
                <Footer1 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StatusSettings;
