import React from "react";
import Header1 from "../../Navigations/header/header1";
import Sidebar2 from "../../Navigations/sidebar/sidebar2";
import Footer1 from "../../Navigations/footer/footer1";

function NewSpeakerType() {
  return (
    <>
      <div className="body-main bg-grey">
        <Header1 />
        <div className="body-page">
          <div className="d-flex">
            <div className="sidebar-main">
              <Sidebar2 />
            </div>
            <div className="content-main">
              <div className="min-height-pagecontent">
                <div className="full-page-form">
                  <div className="spk-1 dgrey_border_line d_padding_bottom20">
                    <p className="d_font_size ">New Speaker Type</p>
                  </div>
                  <form id="speakerForm" className="d_padding_top20">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Speaker Type
                          </label>{" "}
                          <span className="red">*</span>
                          <input
                            type="text"
                            name="speaker_name"
                            id="speaker_name"
                            className="form-control sp-form-input"
                            placeholder="Enter new speaker type"
                          />
                          <p className="error-msg" id="error-msg-name" />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-right ">
                      <button type="submit" className="submit-btn">
                        SAVE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-2">
        <Footer1 />
      </div>
    </>
  );
}

export default NewSpeakerType;
