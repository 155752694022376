import React from "react";
import Header1 from "../../Navigations/header/header1";
import Sidebar2 from "../../Navigations/sidebar/sidebar2";
import Footer1 from "../../Navigations/footer/footer1";
import viewIconImg from "../../../Assets/images/view.svg";
import editIconImg from "../../../Assets/images/edit.svg";
import cloneIconImg from "../../../Assets/images/clone-svg.svg";
import deleteIconImg from "../../../Assets/images/delete.svg";
import webBannerotn3bIconImg from "../../../Assets/images/web-banner_option3b.png";
import newUserBlueIconImg from "../../../Assets/images/new-user-blue.svg";
import dataBaseIconImg from "../../../Assets/images/database.svg";

function ListOfEmailTemplates() {
  return (
    <>
      <div className="body-main bg-grey">
       <Header1/>
        <div className="body-page">
          <div className="d-flex">
            <div className="sidebar-main">
              <Sidebar2/>
            </div>
            <div className="content-main">
              <div className="min-height-pagecontent">
                <div className="spk-main">
                  <div className="spk-1">
                    <div className="spk-inner-left">
                      <p className="spk-title">
                        Sanity Check : Email Templates
                      </p>
                      <button
                        type="button"
                        className="no-btn"
                        s-toggle="tooltip"
                        data-bs-trigger="manual"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-html="true"
                        customclass="testr"
                        title="<p>The list below are all the speakers that are associated with your event.  You can add, edit, manage, communicate and request for additional information from here.  To learn more, please refer to the <a href='/support/speaker.php, target='_blank'>FAQ</a> page.</p>"
                      >
                        <i className="bi bi-info-circle sky-blue" />
                      </button>
                    </div>
                    <div className="spk-inner-right">
                      <a
                        href=""
                        className="violet-btn margin-right-10"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal10"
                      >
                        <i className="bi bi-pencil-fill padding-bootom-2" />{" "}
                        Create New Template
                      </a>
                    </div>
                  </div>
                  <div
                    className="modal fade d_modal "
                    id="Modal10"
                    tabIndex={-1}
                    aria-labelledby="Modal10"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                      <div className="modal-content d_padding_10">
                        <div className="modal-header d_modal_border0">
                          <h6
                            className="d_font_family text-center d_margin_auto modal-title"
                            id="Modal10"
                          >
                            Create New Template{" "}
                          </h6>
                          <button
                            type="button"
                            className="d_modal_closebtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="bi bi-x pink" />
                          </button>
                        </div>
                        <div className="modal-body d_padding_bottom0 d_margin_top20 d_margin_bottom20">
                          <div className="row d_text_center">
                            <div className="col-12 d_margin_top10 d_margin_bottom10">
                              <a
                                className="d_cursor_pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#Modal7"
                              >
                                <img
                                  src={dataBaseIconImg}
                                  className="d_margin_bottom10"
                                />
                                <p className="sky-blue d_font_size13">
                                  <b>Search From Database</b>
                                </p>
                              </a>
                            </div>
                            <div className="d_orDivider font-sem-bold">OR</div>
                            <a className="col-12 d_margin_top10 d_margin_bottom10" />
                            <a href="/new-email-template">
                              <img
                                src={newUserBlueIconImg}
                                className="d_margin_bottom10"
                              />
                              <p className="pink d_font_size13">
                                <b>Create New</b>
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="spk-3 mt-4 d-none">
                    <form>
                      <div className="d-flex">
                        <button className="red-btn margin-right-10">
                          <i className="bi bi-trash padding-bootom-2" /> Delete
                          Selected
                        </button>
                        <select
                          className="form-select w-200 spk-select"
                          aria-label="Default select example"
                        >
                          <option selected=""> Change Status</option>
                          <option value={1}>One</option>
                          <option value={2}>Two</option>
                          <option value={3}>Three</option>
                        </select>
                      </div>
                    </form>
                  </div>
                  <div className="spk-4 my-0 d_table_search d_table_search_space">
                    <table
                      id="all-speakers"
                      className="stripe row-border order-column nowrap custom-table"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>Template Name</th>
                          <th>Template Type</th>
                          <th>Status Type</th>
                          <th>Usage</th>
                          <th>Opened</th>
                          <th className="d_text_center d_manage_before">
                            Created Date
                          </th>
                          <th className="d_text_center d_manage_before">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <p className="d_font_size14">
                              Welcome Reception Invitation{" "}
                              <span className="red">(Edited)</span>
                            </p>
                          </td>
                          <td>
                            <p className="d_font_size14">
                              Sponsor,speaker,master Template
                            </p>
                          </td>
                          <td>
                            <p className="d_font_size14">Approved</p>
                          </td>
                          <td>
                            <p className="d_font_size14">1</p>
                          </td>
                          <td>
                            <p className="d_font_size14">0</p>
                          </td>
                          <td>
                            <p className="d_font_size14"> 08-Jan-2020</p>
                          </td>
                          <td>
                            <div className="action">
                              <a
                                className="d_cursor_pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#Modal6"
                              >
                                <img
                                  src={viewIconImg}
                                  title="View"
                                />
                              </a>
                              <a href="/edit-email-template">
                                <img
                                  src={editIconImg}
                                  title="Edit"
                                />
                              </a>
                              <a href="/copy-email-template">
                                <img
                                  src={cloneIconImg}
                                  title="Notification"
                                />
                              </a>
                              <a href="#">
                                <img
                                  src={deleteIconImg}
                                  title="Delete"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className="modal fade d_modal "
                id="Modal6"
                tabIndex={-1}
                aria-labelledby="Modal6"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md modal-dialog-centered">
                  <div className="modal-content d_padding_10">
                    <div className="modal-header d_modal_border0">
                      <h5
                        className="d_font_family text-center d_margin_auto modal-title"
                        id="Modal6"
                      >
                        News Letter Template
                      </h5>
                      <button
                        type="button"
                        className="d_modal_closebtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x pink" />
                      </button>
                    </div>
                    <div className="modal-body d_padding_bottom0">
                      <div className="row d_margin_bottom20">
                        <div className="col-12 d_margin_bottom20">
                          <h6>
                            <b>Email Subject: </b>
                          </h6>
                          <p className="d_font_size14">Call for customers</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d_margin_bottom20">
                          <h6>
                            <b>Email Content </b>
                          </h6>
                          <div className="d_modal_email_template">
                            <img
                              src={webBannerotn3bIconImg}
                              className="img-responsive d_margin_bottom20"
                            />
                            <p className="d_font_size15">Dear Prajna, </p>
                            <p className="d_font_size15">
                              Thanks,[Event/Event Producer Company] Team
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade d_modal "
                id="Modal7"
                tabIndex={-1}
                aria-labelledby="Modal7"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content d_padding_10">
                    <div className="modal-header d_modal_border0">
                      <h5
                        className="d_font_family text-center d_margin_auto modal-title"
                        id="Modal7"
                      >
                        Search From Database
                      </h5>
                      <button
                        type="button"
                        className="d_modal_closebtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x pink" />
                      </button>
                    </div>
                    <div className="modal-body d_padding_bottom0">
                      <div className="spk-3 mt-4 d-none ">
                        <form>
                          <div className="d-flex">
                            <button className="red-btn margin-right-10">
                              <i className="bi bi-trash padding-bootom-2" />{" "}
                              Delete Selected
                            </button>
                            <select
                              className="form-select w-200 spk-select"
                              aria-label="Default select example"
                            >
                              <option selected=""> Change Status</option>
                              <option value={1}>One</option>
                              <option value={2}>Two</option>
                              <option value={3}>Three</option>
                            </select>
                          </div>
                        </form>
                      </div>
                      <div className="spk-4 my-0 d_email_status_page d_table_search">
                        <table
                          id="search_table"
                          className="stripe row-border order-column nowrap custom-table "
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th />
                              <th className="d_width_300">Template Name</th>
                              <th className="d_width_300">#Template Type</th>
                              <th className="d_width_300">Status</th>
                              <th className="d_width_300">Event Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td />
                              <td className="d_font_size14">Women In Cloud</td>
                              <td className="d_font_size14">
                                sponsor,speaker,master,attendee
                              </td>
                              <td className="d_font_size14">
                                New,Approved,In-Review,Rejected,
                                <br />
                                Research,In Contract
                              </td>
                              <td className="d_font_size14">
                                Test Schedule Email
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-2">
                <Footer1/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListOfEmailTemplates;
