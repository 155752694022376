import React from "react";
import Header1 from "../../Navigations/header/header1";
import Sidebar2 from "../../Navigations/sidebar/sidebar2";
import Footer1 from "../../Navigations/footer/footer1";
import defaultSpeakerImg from "../../../Assets/images/default-speaker.jpg";
import listViewIconImg from "../../../Assets/images/list-view.png"

function ApprovedSpeakerPage() {
  return (
    <>
      <div className="body-main bg-grey">
       <Header1/>
        <div className="body-page">
          <div className="d-flex">
            <div className="sidebar-main">
             <Sidebar2/>
            </div>
            <div className="content-main">
              <div className="min-height-pagecontent">
                <div className="spk-main">
                  <div className="spk-1">
                    <div className="spk-inner-left">
                      <p className="spk-title">
                        Sanity Check : Approved Speakers
                      </p>
                      <button
                        type="button"
                        className="no-btn"
                        s-toggle="tooltip"
                        data-bs-trigger="manual"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-html="true"
                        customclass="testr"
                        title="<p>The list below are all the speakers that are associated with your event.  You can add, edit, manage, communicate and request for additional information from here.  To learn more, please refer to the <a href='/support/speaker.php, target='_blank'>FAQ</a> page.</p>"
                      >
                        <i className="bi bi-info-circle sky-blue" />
                      </button>
                    </div>
                    <div className="spk-inner-right">
                      <a
                        href="/approved-speakers-list"
                        className="yellow-btn margin-right-10"
                      >
                        <img
                          src={listViewIconImg}
                          className="d_list_img"
                        />{" "}
                        List View
                      </a>
                      <a href="" className="violet-btn margin-right-10">
                        <i className="bi bi-download padding-bootom-2" /> Copy
                        Embed URL
                      </a>
                    </div>
                  </div>
                  <div className="spk-3 mt-4 d-none ">
                    <form>
                      <div className="d-flex">
                        <button className="red-btn margin-right-10">
                          <i className="bi bi-trash padding-bootom-2" /> Delete
                          Selected
                        </button>
                        <select
                          className="form-select w-200 spk-select"
                          aria-label="Default select example"
                        >
                          <option selected=""> Change Status</option>
                          <option value={1}>One</option>
                          <option value={2}>Two</option>
                          <option value={3}>Three</option>
                        </select>
                      </div>
                    </form>
                  </div>
                  <div className="spk-4 my-0 d_table_search d_table_search_space d_grid_view d_margin_top20">
                    <div className="row d_margin_top20">
                      <div className="col-3 d_grid_block d_margin_top20">
                        <a
                          href="/edit-speaker"
                          target="_blank"
                          className="editSpeaker"
                        >
                          <i className="bi bi-pencil-square" />
                        </a>
                        <img
                          style={{ width: "100%" }}
                          src={defaultSpeakerImg}
                          className="img-responsive d_border_radius10"
                        />
                        <div className="name-social">
                          <h4 className="d_text_center d_margin_top20 d_margin_bottom20">
                            <b>Prajna Shetty</b>
                          </h4>
                          <br />
                          <ul className="list-inline d-flex d_text_center d_justify_content_center d_listview_ul d_margin_top20">
                            <li>
                              <a href="#" className="">
                                <i className="bi bi-twitter" />
                              </a>
                            </li>
                            <li>
                              <a href="#" className="">
                                <i className="bi bi-linkedin" />
                              </a>
                            </li>
                            <li>
                              <a
                                title="prajna.s@iverbinde.co"
                                className="bg_blue"
                              >
                                <i className="bi bi-envelope" />
                              </a>
                            </li>
                            <li>
                              <a className="">
                                <i className="bi bi-telephone" />
                              </a>
                            </li>
                            <li>
                              {" "}
                              <a
                                className="d_cursor_pointer bg_yellow"
                                data-bs-toggle="modal"
                                data-bs-target="#Modal9"
                              >
                                {" "}
                                <i className="bi bi-eye" />
                              </a>
                            </li>
                            <li>
                              <a
                                className="bg_lightblue d_cursor_pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#Modal1"
                              >
                                <i className="bi bi-arrow-up-right-square" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade d_modal "
                id="Modal9"
                tabIndex={-1}
                aria-labelledby="Modal9"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered ">
                  <div className="modal-content">
                    <div className="modal-header d_modal_border0">
                      <h5
                        className="d_font_family text-center d_margin_auto modal-title"
                        id="Modal9"
                      >
                        Preview
                      </h5>
                      <button
                        type="button"
                        className="d_modal_closebtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x pink" />
                      </button>
                    </div>
                    <div className="modal-body d_padding_bottom0 d_padding0">
                      <div className="d_preview_modal">
                        <div className="d_bg_blue clearfix">
                          <div className="row">
                            <div className="col-8" />
                            <div className="col-4 d_margin_top20 ">
                              <div className="d-flex d_space_between">
                                <p className="d_text_white d_font_size14">
                                  Profile Complete{" "}
                                </p>
                                <div className="d_float_right d_margin_right10 d_light_yellow d_font_size14 ">
                                  <p>
                                    <i className="bi bi-exclamation-triangle-fill d_light_yellow">
                                      16%
                                    </i>
                                  </p>
                                </div>
                              </div>
                              <div className="progress d_margin_right10">
                                <div
                                  className="progress-bar bg-warning"
                                  style={{ width: "10%" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <h1 className="d_text_white d_text_center d_margin_top20 d_padding_bottom20">
                            <b>Prajna Shetty</b>
                          </h1>
                          <h6 className="d_text_white d_text_center d_padding_bottom20">
                            <b>|</b>
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <div className="d_preview_left">
                              <img
                                src="/static/images/default_avatar.jpg"
                                className="img-responsive d_width100"
                              />
                              <p>
                                <b>Email : </b>prajna.s@iverbinde.co
                              </p>
                              <p>
                                <b>Phone No:</b>
                              </p>
                              <p>
                                <b>Company:</b>
                              </p>
                              <hr className="d_borderwidth2" />
                              <p>
                                <b>Address</b>
                              </p>
                              <p>
                                <b>City</b>
                              </p>
                              <p>
                                <b>State</b>
                              </p>
                              <p>
                                <b>Country</b>
                              </p>
                              <p>
                                <b>Speaker Manager Details</b>
                              </p>
                              <p>Speaker Manager Name:</p>
                              <p>Speaker Manager Email Address:</p>
                              <p>Speaker Manager Phone Number:</p>
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="d_preview_right">
                              <div className="d_width100 clearfix">
                                <div className="d_border_radius10 d_nospeaker ">
                                  <a>
                                    <img src="/static/images/rtm_dashboard.png" />
                                    <span>
                                      <b>Novice Speaker</b>
                                    </span>
                                    <i className="bi bi-download" />
                                  </a>
                                </div>
                              </div>
                              <div id="" className="d_padding_top20 ">
                                <div className="row">
                                  <div className="col-12 d_padding_bottom20">
                                    <label className="form-label sp-form-label d_font_size15">
                                      <b>QUOTE</b>
                                    </label>
                                    <div className="d_border_blue d_bg_light_blue d_height_150">
                                      ""
                                    </div>
                                  </div>
                                  <div className="col-12 d_padding_bottom20">
                                    <label className="form-label sp-form-label d_font_size15">
                                      <b>BIO</b>
                                    </label>
                                    <div className="d_border_blue d_bg_light_blue d_height_150">
                                      ""
                                    </div>
                                  </div>
                                  <div className="col-12 d_padding_bottom20">
                                    <label className="form-label sp-form-label d_font_size15">
                                      <b> SOCIAL MEDIA INFORMATION</b>
                                    </label>
                                    <div className="d_border_blue d_bg_light_blue d_height_150">
                                      <div className="d-flex">
                                        <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                          Linked in
                                        </p>
                                        <p className="sky-blue d_font_size14" />
                                      </div>
                                      <div className="d-flex">
                                        <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                          Facebook
                                        </p>
                                        <p className="sky-blue d_font_size14">
                                          https://www.facebook.com
                                        </p>
                                      </div>
                                      <div className="d-flex">
                                        <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                          Twitter
                                        </p>
                                        <p className="sky-blue d_font_size14" />
                                      </div>
                                      <div className="d-flex">
                                        <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                          Instagram
                                        </p>
                                        <p className="sky-blue d_font_size14">
                                          https://www.instagram.com
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 d_preview_fullwidth">
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h6>
                                <b>
                                  ARE YOU WILLING TO PROMOTE THIS EVENT TO YOUR
                                  COMMUNITY?
                                </b>
                              </h6>
                              <p className="d_font_size14 ">no</p>
                            </div>
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h6>
                                <b>WHAT WOULD YOU LIKE US TO PROVIDE YOU?</b>
                              </h6>
                              <p className="d_font_size14 " />
                            </div>
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h6>
                                <b>SELECT PARTICIPATION TYPE</b>
                              </h6>
                              <p className="d_font_size14 " />
                            </div>
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h6>
                                <b>SPEAKER REQUESTS</b>
                              </h6>
                              <p className="d_font_size14 ">A\V Support</p>
                            </div>
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h6>
                                <b>SPEAKER EXPERTISE</b>
                              </h6>
                              <p className="d_font_size14 " />
                            </div>
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h5>
                                <b>PARTICIPATION TOPICS</b>
                              </h5>
                              <div className="d_participation_table d_border_radius10 d_padding_10 d_margin_top20 d_margin_bottom20">
                                <table className="table table-responsive d_border_radius10 ">
                                  <thead className="">
                                    <tr>
                                      <th>
                                        <p className="d_font_size14">1.</p>
                                      </th>
                                      <th>
                                        <p className="d_font_size14">2.</p>
                                      </th>
                                      <th>
                                        <p className="d_modal_border0 d_font_size14">
                                          3.
                                        </p>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="d_height_50" />
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h5>
                                <b>ACTIVITY MANAGEMENT STATUS</b>
                              </h5>
                              <div className="d_table_boxshadow d_border_radius10 d_padding_10 d_margin_top20 d_margin_bottom20">
                                <div className="row">
                                  <p className="d_font_size14 d_padding_10">
                                    <b>Activities</b>
                                  </p>
                                  <div className="col-6">
                                    <div className="d-flex">
                                      <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                        Social Media Sharing Completed
                                      </p>
                                      <p className="sky-blue d_font_size14">
                                        <b>No</b>
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                        Website Listing Completed
                                      </p>
                                      <p className="sky-blue d_font_size14">
                                        <b>No</b>
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                        Orientation Attended
                                      </p>
                                      <p className="sky-blue d_font_size14">
                                        <b>No</b>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="d-flex">
                                      <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                        Speaker Coach Assigned
                                      </p>
                                      <p className="sky-blue d_font_size14">
                                        <b>No</b>
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                        Video Promotion Completed
                                      </p>
                                      <p className="sky-blue d_font_size14">
                                        <b>No</b>
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                        Reception Invitation Accepted
                                      </p>
                                      <p className="sky-blue d_font_size14">
                                        <b>No</b>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h5>
                                <b>RECENT COMMUNICATIONS</b>
                              </h5>
                              <table className="table table-responsive d-bluetable ">
                                <thead className="d-bluetable-header ">
                                  <tr>
                                    <th className="d_modal_border0 d_width_700">
                                      <p className="d_modal_border0">Subject</p>
                                    </th>
                                    <th className="d_modal_border0" />
                                    <th className="d_modal_border0">
                                      <p>From | Date</p>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="d_modal_border0 d_padding0">
                                      <p>Thank You from ______________! </p>
                                    </td>
                                    <td className="d_modal_border0 d_padding0">
                                      <p>|</p>
                                    </td>
                                    <td className="d_modal_border0 d_padding0">
                                      <p>null | 10-Dec-202</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="d_modal_border0">
                                      <p>
                                        Call for Speakers: Apply to speak at the
                                        upcoming (insert event name) today{" "}
                                      </p>
                                    </td>
                                    <td className="d_modal_border0">
                                      <p>|</p>
                                    </td>
                                    <td className="d_modal_border0">
                                      <p>null | 29-Oct-202</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="d_modal_border0 d_padding0">
                                      <p>Call for customers</p>
                                    </td>
                                    <td className="d_modal_border0">
                                      <p>|</p>
                                    </td>
                                    <td className="d_modal_border0">
                                      <p>null | 07-Oct-202</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="d_modal_border0">
                                      <p>
                                        Your Speaker Application has been
                                        received
                                      </p>
                                    </td>
                                    <td className="d_modal_border0">
                                      <p>|</p>
                                    </td>
                                    <td className="d_modal_border0">
                                      <p>null | 07-Oct-202</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="d_modal_border0">
                                      <p>
                                        (Insert event name) Speaker Application
                                        Status Update
                                      </p>
                                    </td>
                                    <td className="d_modal_border0">
                                      <p>|</p>
                                    </td>
                                    <td className="d_modal_border0">
                                      <p>null | 25-Sep-202</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h5>
                                <b>NOTES</b>
                              </h5>
                              <div className="d_table_boxshadow d_border_radius10 d_padding_20 d_margin_top20 d_margin_bottom20">
                                <div className="row">
                                  <div className="col-12">
                                    <p className=" d_font_size14 ">
                                      welcome to speakerengage
                                    </p>
                                    <p className="d_font_size12">
                                      By Nataraj | 2023-11-02
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h5>
                                <b>Documents</b>
                              </h5>
                              <div className="d_margin_top20 d_margin_bottom20">
                                <div className="row">
                                  <div className="col-12">
                                    <p className=" d_font_size15 ">
                                      <a>
                                        <i className="bi bi-download sky-blue d_margin_right10" />
                                        Flyer
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade d_modal "
                id="Modal1"
                tabIndex={-1}
                aria-labelledby="Modal1"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header d_yellow_border">
                      <h5
                        className="d_font_family text-center d_margin_auto modal-title "
                        id="Modal1"
                      >
                        Send Request
                      </h5>
                      <button
                        type="button"
                        className="d_modal_closebtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x pink" />
                      </button>
                    </div>
                    <div className="modal-body d_padding_bottom0">
                      <form id="eventForm" className="d_padding_top20">
                        <div className="row">
                          <div className="col-4">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Trigger : <span className="red">*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-8">
                            <select
                              name="Trigger"
                              id="Trigger"
                              className="form-control form-select sp-form-input spk-select"
                            >
                              <option value="" selected="">
                                Request for Missing Information
                              </option>
                              <option value="">
                                Request for Missing Documentation
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Select : <span className="red">*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="multi-select dropdown d_width100 sp-form-input d_blue_multiselect">
                              <button
                                title="None Selected"
                                className="no-btn multi-select-btn sp-form-input sky-blue"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                None Selected{" "}
                                <span className="arrow-down">
                                  <i className="bi bi-chevron-down d_black" />
                                </span>
                              </button>
                              <ul
                                className="multi-select dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="selectAll"
                                  />
                                  <label
                                    className="form-check-label sky-blue"
                                    htmlFor="selectAll"
                                  >
                                    Select All
                                  </label>
                                </li>
                                <li>
                                  <hr className="dropdown-divider" />
                                </li>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="option1"
                                  />
                                  <label
                                    className="form-check-label sky-blue"
                                    htmlFor="option1"
                                  >
                                    Headshot
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="option2"
                                  />
                                  <label
                                    className="form-check-label sky-blue"
                                    htmlFor="option2"
                                  >
                                    Bio
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="option3"
                                  />
                                  <label
                                    className="form-check-label sky-blue"
                                    htmlFor="option3"
                                  >
                                    Quote
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="option1"
                                  />
                                  <label
                                    className="form-check-label sky-blue"
                                    htmlFor="option1"
                                  >
                                    Social Media
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="option2"
                                  />
                                  <label
                                    className="form-check-label sky-blue"
                                    htmlFor="option2"
                                  >
                                    Address
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="option3"
                                  />
                                  <label
                                    className="form-check-label sky-blue"
                                    htmlFor="option3"
                                  >
                                    Speaker Manager
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="option2"
                                  />
                                  <label
                                    className="form-check-label sky-blue"
                                    htmlFor="option2"
                                  >
                                    Presentation Table &amp; Abstract
                                  </label>
                                </li>
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="option3"
                                  />
                                  <label
                                    className="form-check-label sky-blue"
                                    htmlFor="option3"
                                  >
                                    Acknowledgment
                                  </label>
                                </li>
                                {/* Add more options as needed */}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="dgrey_border_line clearfix d_margin_bottom20">
                          <button
                            type="submit"
                            className="yellow-btn d_float_right d_margin_top20 d_margin_bottom20 "
                          >
                            <i className="bi bi-chat-dots d_margin_right10 d_action_icon" />
                            Request an Update
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Copy to speaker manager
                              </label>
                              <select
                                name="Trigger"
                                id="Trigger"
                                className="form-control form-select sp-form-input spk-select"
                              >
                                <option value="" selected="">
                                  No
                                </option>
                                <option value="">Yes</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <label className="form-label sp-form-label">
                              Schedule
                            </label>
                            <select
                              name="Trigger"
                              id="Trigger"
                              className="form-control form-select sp-form-input spk-select"
                            >
                              <option value="" selected="">
                                Send It Right Away
                              </option>
                              <option value="">Schedule It</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Message
                              </label>
                              <div className="input-main">
                                <p className="form-control sp-form-input">
                                  We are missing some data for managing
                                  communications and engagement. Can you please
                                  update your profile. Thank you.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="submit-btn">
                        <i className="bi bi-send" />
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-2">
                <Footer1/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApprovedSpeakerPage;
