import React from 'react'
import minuteEmailerImg from '../../../Assets/images/minutemailer.svg';
import menu4Img from '../../../Assets/images/menu-4.svg';
import menu2Img from '../../../Assets/images/menu-2.svg';
import settingsImg from '../../../Assets/images/settings.svg';
import menu3Img from '../../../Assets/images/menu-3.svg';
import menu6Img from '../../../Assets/images/menu-6.svg';
import menu7Img from '../../../Assets/images/menu-7.svg';
import logoutImg from '../../../Assets/images/logout.svg';

const Sidebar1 = () => {
  return (
    <>
  <div className="sidebar-menu">
    <section className="current-plan">
      <div className="current-plan-inner">
        <p className="plan-txt-1">Current Plan</p>
        <p className="plan-txt-2">Professional Plan</p>
      </div>
    </section>
    <ul
      className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
      id="menu"
    >
      <li className="nav-item main">
        <a
          href="#submenu1"
          data-bs-toggle="collapse"
          className="menu-item-new collapsed"
          aria-expanded="false"
        >
          <img
            title=""
            src={minuteEmailerImg}
          />
          Email Manager{" "}
          <span className="menu-arrow">
            <i className="bi bi-chevron-right" />
          </span>
        </a>
        <ul
          className="collapse nav-item list-unstyled sub-menu"
          id="submenu1"
          data-bs-parent="#menu"
          aria-expanded="false"
        >
          <li className="nav-item">
            <a href="#" className="menu-item-new">
              <img
                title=""
                src={minuteEmailerImg}
              />
              Email Manager
            </a>
          </li>
          <li>
            <a href="#" className="menu-item-new">
              <img
                title=""
                src={menu4Img}
              />
              Mass Mailing
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item main">
        <a
          href="#submenu2"
          data-bs-toggle="collapse"
          className="menu-item-new collapsed"
          aria-expanded="false"
        >
          <img
            title=""
            src={menu2Img}
          />
          All Speakers
          <span className="menu-arrow">
            <i className="bi bi-chevron-right" />
          </span>
        </a>
        <ul
          className="collapse nav-item list-unstyled sub-menu"
          id="submenu2"
          data-bs-parent="#menu"
          aria-expanded="false"
        >
          <li className="nav-item">
            <a href="#" className="menu-item-new">
              <img
                title=""
                src={menu2Img}
              />
              All Speakers
            </a>
          </li>
          <li>
            <a href="#" className="menu-item-new">
              <img
                title=""
                src={settingsImg}
              />
              Speaker Status
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item main">
        <a
          href="#submenu3"
          data-bs-toggle="collapse"
          className="menu-item-new collapsed"
          aria-expanded="false"
        >
          <img
            title=""
            src={menu3Img}
          />
          All Sponsors
          <span className="menu-arrow">
            <i className="bi bi-chevron-right" />
          </span>
        </a>
        <ul
          className="collapse nav-item list-unstyled sub-menu"
          id="submenu3"
          data-bs-parent="#menu"
          aria-expanded="false"
        >
          <li className="nav-item">
            <a href="#" className="menu-item-new">
              <img
                title=""
                src={menu3Img}
              />
              All Sponsors
            </a>
          </li>
          <li>
            <a href="#" className="menu-item-new">
              <img
                title=""
                src={settingsImg}
              />
              Sponsor Status
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item main">
        <a
          href="#submenu4"
          data-bs-toggle="collapse"
          className="menu-item-new collapsed"
          aria-expanded="false"
        >
          <img
            title=""
            src={menu4Img}
          />
          All Contacts
          <span className="menu-arrow">
            <i className="bi bi-chevron-right" />
          </span>
        </a>
        <ul
          className="collapse nav-item list-unstyled sub-menu"
          id="submenu4"
          data-bs-parent="#menu"
          aria-expanded="false"
        >
          <li className="nav-item">
            <a href="#" className="menu-item-new">
              <img
                title=""
                src={menu4Img}
              />
              All Contacts
            </a>
          </li>
          <li>
            <a href="#" className="menu-item-new">
              <img
                title=""
                src={settingsImg}
              />
              Contact Status
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item main">
        <a href="#" className="menu-item-new">
          <img
            title=""
            src={menu6Img}
          />
          Get Help
        </a>
      </li>
      <li className="nav-item main">
        <a href="#" className="menu-item-new">
          <img
            title=""
            src={menu7Img}
          />
          FAQs &amp; Support
        </a>
      </li>
    </ul>
  </div>
  <div className="logout">
    <ul className="nav">
      <li className="nav-item main">
        <a href="#" className="menu-item-new pink">
          <img
            title=""
            src={logoutImg}
          />
          Log Out
        </a>
      </li>
    </ul>
  </div>
</>

  )
}

export default Sidebar1