import React from 'react'

const Footer1 = () => {
  return (
    <div className="footer1">
    <div className="d-flex">
      <div className="col text-left poweredby">
        <span>
          Copyright © 2024{" "}
          <a href="http://meylah.com/" target="_blank" className="white-text">
            Meylah Corporation
          </a>
          . All rights reserved.
        </span>
      </div>
      <div className="col text-center">
        <a href="../privacy-policy" className="white-text" target="_blank">
          Privacy Policy
        </a>{" "}
        &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
        <a href="../terms-of-use" className="white-text" target="_blank">
          Terms and Conditions
        </a>
      </div>
      <div className="col text-right">Version 5.3.0</div>
    </div>
  </div>
  
  )
}

export default Footer1