import React from 'react'
import SpeakerEngageLogo from '../../Assets/images/Speaker-Engage-Logo-svg.svg'

function EmailVerfiy() {
  const UserEmailId = localStorage.getItem("UserEmailId")
  return (
   <>
   <div className="main d-flex align-items-center justify-content-center bg-gray signinmain main-height">
  <div className="container">
    <div className="row wrapper2 text-center">
      <p>
        <a href="https://www.speakerengage.com/">
          <img
            src={SpeakerEngageLogo}
            className="whitelogo-login"
          />
        </a>
      </p>
    </div>
    <div className="row wrapper1">
      <div className="col signinleft border-radius-25">
        <div className="signinleftinner w-300">
          <p className="signin-text">Verify your Email</p>
          <p className="signin-subtext">We sent a verification email to</p>
          <p className="text-center"></p>
          <p className="signinlink-textinner">
            {UserEmailId}
          </p>
          <p />
          <p className="signin-subtext pt-3">
            Click the link in the email to get started!
          </p>
          <hr className="hr-line" />
          <p className="signin-subtext">
            If you didn’t get the email, please check your Spam folder.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
   </>
  )
}

export default EmailVerfiy
