import axios from "axios";

 const BaseUrl = process.env.REACT_APP_API_BACKEND_URL

export const executePost = async (endpoint,data)=>{
    return await axios.post(BaseUrl + endpoint,data);
}

export const executeGET = async (endPoint) => {
    return await axios.get(BaseUrl + endPoint);
  };