import React from "react";
import SpeakerEnageLogo from "../../Assets/images/Speaker Engage Logo-svg.svg";
import Footer1 from "../Navigations/footer/footer1";

function CreateOrganizationName() {
  return (
    <>
      <div className="main d-flex align-items-center justify-content-center bg-gray signinmain main-height">
        <div className="container">
          <div className="row wrapper2 text-center">
            <p>
              <a href="https://www.speakerengage.com/">
                <img src={SpeakerEnageLogo} className="whitelogo-login" />
              </a>
            </p>
          </div>
          <div className="row wrapper1">
            <div className="col signinleft border-radius-25">
              <div className="signinleftinner w-500">
                <p className="signin-text">Get. Set. Go</p>
                <p className="signin-greytext">
                  Choose your Speaker Engage Organization Name
                </p>
                <p className="signin-lightgreytext">
                  https://www.speakerengage.com/
                </p>
                <p className="error-msg" id="error-msg" />
                <div className="email-login">
                  <form
                    id="companyform"
                    method="post"
                    action="{{ url_for('save_tenant') }}"
                  >
                    <div className="mb-3">
                      <input
                        type="text"
                        name="company_name"
                        id="company_name"
                        placeholder="Organization Name"
                        className="form-control signin-input"
                      />
                    </div>
                    <p className="signin-subtext text-left">
                      The organization name is used to create public facing web
                      pages on your behalf that are used for data collection
                      from Speakers, Sponsors and other data display pages.
                    </p>
                    <p className="attention-text">
                      Attention: Your choice of organization name can’t be
                      changed later, so choose wisely!
                    </p>
                    <p className="signin-subtext text-left">
                      * Organization name can contain alphabets and numbers
                      only. No spaces or special characters are allowed.
                    </p>
                    <button
                      type="submit"
                      id="tenantBtn"
                      className="signin-submit"
                    >
                      Continue
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  );
}

export default CreateOrganizationName;
