import React from "react";
import Header1 from "../../Navigations/header/header1";
import Sidebar2 from "../../Navigations/sidebar/sidebar2";
import Footer1 from "../../Navigations/footer/footer1";
import viewIcon from '../../../Assets/images/view.svg';
import defaultAvatarImg from "../../../Assets/images/default_avatar.jpg";
import rtmdashboardIcon from "../../../Assets/images/rtm_dashboard.png";

function SearchFromSpeakerDatabaseNew() {
  return (
    <>
      <div className="body-main bg-grey">
        <Header1 />
        <div className="body-page">
          <div className="d-flex">
            <div className="sidebar-main">
              <Sidebar2 />
            </div>
            <div className="content-main">
              <div className="min-height-pagecontent">
                <div className="spk-main">
                  <div className="spk-1">
                    <div className="spk-inner-left">
                      <p className="spk-title">Search From Speaker Database</p>
                      <button
                        type="button"
                        className="no-btn"
                        s-toggle="tooltip"
                        data-bs-trigger="manual"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-html="true"
                        customclass="testr"
                        title="You can use this feature to add speakers who have already participated in other events organized by your organization.
      "
                      >
                        <i className="bi bi-info-circle sky-blue" />
                      </button>
                    </div>
                    <div className="d-flex justify-content-right ">
                      <button type="submit" className="submit-btn">
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="spk-2 mt-4 ">
                    <form>
                      <div className="d_margin_top10 d_margin_bottom20 d_padding_bottom20">
                        <div className="multi-select dropdown d_width_300 d_float_right">
                          <button
                            title="None Selected"
                            className="no-btn multi-select-btn"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="bi bi-funnel" /> Filter By Event Name{" "}
                            <span className="arrow-down">
                              <i className="bi bi-chevron-down sky-blue" />
                            </span>
                          </button>
                          <ul
                            className="multi-select dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="selectAll"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="selectAll"
                              >
                                Select All
                              </label>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="option1"
                              >
                                Option 1
                              </label>
                            </li>
                            <li>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="option2"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="option2"
                              >
                                Option 2
                              </label>
                            </li>
                            <li>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="option3"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="option3"
                              >
                                Option 3
                              </label>
                            </li>
                            {/* Add more options as needed */}
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="spk-4 my-0 d_search_from d_table_search">
                    <table
                      id="all-speakers"
                      className="stripe row-border order-column nowrap custom-table d_margin_bottom20  "
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>
                            <b>Select</b>
                          </th>
                          <th>
                            <b>Speaker Name</b>
                          </th>
                          <th>
                            <b>Speaker Email</b>
                          </th>
                          <th>
                            <b>Contact</b>
                          </th>
                          <th>
                            <b>Manage</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td />
                          <td className="d_font_size14">Sreekar</td>
                          <td className="d_font_size14">
                            sreekar.s@iverbinden.com
                          </td>
                          <td className="d_font_size14">Verbinden</td>
                          <td>
                            <div className="action d_justify_content_left">
                              <a
                                className="d_cursor_pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#Modal9"
                              >
                                <img
                                  src={viewIcon}
                                  title="View"
                                />
                              </a>
                              <a href="#">
                                <i className="bi bi-calendar-event-fill sky-blue" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td className="d_font_size14">Sarfaraz</td>
                          <td className="d_font_size14">
                            sarfaraz.a.g@iverbinden.com
                          </td>
                          <td className="d_font_size14">--</td>
                          <td>
                            <div className="action d_justify_content_left">
                              <a
                                className="d_cursor_pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#Modal9"
                              >
                                <img
                                  src={viewIcon}
                                  title="View"
                                />
                              </a>
                              <a href="#">
                                <i className="bi bi-calendar-event-fill sky-blue" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td className="d_font_size14">James Smith</td>
                          <td className="d_font_size14">james.s@gmail.com</td>
                          <td className="d_font_size14">--</td>
                          <td>
                            <div className="action d_justify_content_left">
                              <a
                                className="d_cursor_pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#Modal9"
                              >
                                <img
                                  src={viewIcon}
                                  title="View"
                                />
                              </a>
                              <a href="#">
                                <i className="bi bi-calendar-event-fill sky-blue" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td className="d_font_size14">Prajna Shetty</td>
                          <td className="d_font_size14">janedoe@yahoo.com</td>
                          <td className="d_font_size14">janedoe@yahoo.com</td>
                          <td>
                            <div className="action d_justify_content_left">
                              <a
                                className="d_cursor_pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#Modal9"
                              >
                                <img
                                  src={viewIcon}
                                  title="View"
                                />
                              </a>
                              <a
                                className="d_cursor_pointer"
                                data-bs-toggle="modal"
                                data-bs-target="#Modal10"
                              >
                                <i className="bi bi-calendar-event-fill sky-blue" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <div className="d-flex justify-content-right d_margin_top20 ">
                      <button type="submit" className="submit-btn pink-btn">
                        Add Speakers
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade d_modal "
                id="Modal9"
                tabIndex={-1}
                aria-labelledby="Modal9"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered ">
                  <div className="modal-content">
                    <div className="modal-header d_modal_border0">
                      <h5
                        className="d_font_family text-center d_margin_auto modal-title"
                        id="Modal9"
                      >
                        Preview
                      </h5>
                      <button
                        type="button"
                        className="d_modal_closebtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x pink" />
                      </button>
                    </div>
                    <div className="modal-body d_padding_bottom0 d_padding0">
                      <div className="d_preview_modal">
                        <div className="d_bg_blue clearfix">
                          <div className="row">
                            <div className="col-8" />
                            <div className="col-4 d_margin_top20 ">
                              <p className="d_text_white d_font_size14">
                                Profile Complete{" "}
                                <span className="d_float_right d_margin_right10 d_light_yellow ">
                                  <i className="bi bi-exclamation-triangle-fill d_margin_right10 d_light_yellow " />
                                  15
                                </span>
                              </p>
                              <div className="progress d_margin_right10">
                                <div
                                  className="progress-bar bg-warning"
                                  style={{ width: "10%" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <h1 className="d_text_white d_text_center d_margin_top20 d_padding_bottom20">
                            <b>Prajna Shetty</b>
                          </h1>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            <div className="d_preview_left">
                              <img
                                src={defaultAvatarImg}
                                className="img-responsive d_width100"
                              />
                              <p>
                                <b>Email : </b>prajna.s@iverbinde.co
                              </p>
                              <p>
                                <b>Phone No:</b>
                              </p>
                              <p>
                                <b>Company:</b>
                              </p>
                              <hr />
                              <p>
                                <b>Address</b>
                              </p>
                              <p>
                                <b>City</b>
                              </p>
                              <p>
                                <b>State</b>
                              </p>
                              <p>
                                <b>Country</b>
                              </p>
                            </div>
                          </div>
                          <div className="col-8">
                            <div className="d_preview_right">
                              <div className="d_width100 clearfix">
                                <div className="d_border_radius10 d_nospeaker ">
                                  <a>
                                    <img src={rtmdashboardIcon} />
                                    <span>
                                      <b>Novice Speaker</b>
                                    </span>
                                    <i className="bi bi-download" />
                                  </a>
                                </div>
                              </div>
                              <div id="" className="d_padding_top20 ">
                                <div className="row">
                                  <div className="col-12 d_padding_bottom20">
                                    <label className="form-label sp-form-label d_font_size15">
                                      <b>QUOTE</b>
                                    </label>
                                    <div className="d_border_blue d_bg_light_blue d_height_150">
                                      ""
                                    </div>
                                  </div>
                                  <div className="col-12 d_padding_bottom20">
                                    <label className="form-label sp-form-label d_font_size15">
                                      <b>BIO</b>
                                    </label>
                                    <div className="d_border_blue d_bg_light_blue d_height_150">
                                      ""
                                    </div>
                                  </div>
                                  <div className="col-12 d_padding_bottom20">
                                    <label className="form-label sp-form-label d_font_size15">
                                      <b> SOCIAL MEDIA INFORMATION</b>
                                    </label>
                                    <div className="d_border_blue d_bg_light_blue d_height_150">
                                      <div className="d-flex">
                                        <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                          Linked in
                                        </p>
                                        <p className="sky-blue d_font_size14" />
                                      </div>
                                      <div className="d-flex">
                                        <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                          Facebook
                                        </p>
                                        <p className="sky-blue d_font_size14">
                                          https://www.facebook.com
                                        </p>
                                      </div>
                                      <div className="d-flex">
                                        <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                          Twitter
                                        </p>
                                        <p className="sky-blue d_font_size14" />
                                      </div>
                                      <div className="d-flex">
                                        <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                          Instagram
                                        </p>
                                        <p className="sky-blue d_font_size14">
                                          https://www.instagram.com
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 d_preview_fullwidth">
                            <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                              <h6>
                                <b>EVENT DETAILS</b>
                              </h6>
                              <div className=" d_padding_10 d_margin_top20 d_margin_bottom20">
                                <div className="row">
                                  <div className="col-4 text-center">
                                    <b>EVENT NAME</b>
                                  </div>
                                  <div className="col-4 text-center">
                                    <b>PRESENTATION TOPIC</b>
                                  </div>
                                  <div className="col-4 text-center">
                                    <b>PRESENTATION TYPE</b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade d_modal "
                id="Modal10"
                tabIndex={-1}
                aria-labelledby="Modal10"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header d_modal_border0">
                      <h5 className="d_font_family modal-title" id="Modal10">
                        PAST EVENTS
                      </h5>
                      <button
                        type="button"
                        className="d_modal_closebtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="bi bi-x pink" />
                      </button>
                    </div>
                    <div className="modal-body d_padding_bottom0 d_past_events">
                      <table className="table table-responsive d-whitetable ">
                        <thead className="d-whitetable-header">
                          <tr>
                            <th>
                              <p className="d_fontsize12">
                                <b>EVENT NAME</b>
                              </p>
                            </th>
                            <th>
                              <p className="d_fontsize12">
                                <b>EVENT START DATE</b>
                              </p>
                            </th>{" "}
                            <th>
                              <p className="d_fontsize12">
                                <b>EVENT END DATE</b>
                              </p>
                            </th>{" "}
                            <th>
                              <p className="d_fontsize12">
                                <b>PRESENTATION TOPIC</b>
                              </p>
                            </th>
                            <th>
                              <p className="d_fontsize12">
                                <b>PRESENTATION TYPE</b>
                              </p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Test Event 2024 FEB</td>
                            <td>31-Jan-2024</td>
                            <td>29-Mar-2024</td>
                            <td>NA</td>
                            <td>NA</td>
                          </tr>
                          <tr>
                            <td>Test Event 2024 FEB</td>
                            <td>31-Jan-2024</td>
                            <td>29-Mar-2024</td>
                            <td>NA</td>
                            <td>NA</td>
                          </tr>
                          <tr>
                            <td>Test Event 2024 FEB</td>
                            <td>31-Jan-2024</td>
                            <td>29-Mar-2024</td>
                            <td>NA</td>
                            <td>NA</td>
                          </tr>
                          <tr>
                            <td>Test Event 2024 FEB</td>
                            <td>31-Jan-2024</td>
                            <td>29-Mar-2024</td>
                            <td>NA</td>
                            <td>NA</td>
                          </tr>
                          <tr>
                            <td>Test Event 2024 FEB</td>
                            <td>31-Jan-2024</td>
                            <td>29-Mar-2024</td>
                            <td>NA</td>
                            <td>NA</td>
                          </tr>
                          <tr>
                            <td>Test Event 2024 FEB</td>
                            <td>31-Jan-2024</td>
                            <td>29-Mar-2024</td>
                            <td>NA</td>
                            <td>NA</td>
                          </tr>
                          <tr>
                            <td>Test Event 2024 FEB</td>
                            <td>31-Jan-2024</td>
                            <td>29-Mar-2024</td>
                            <td>NA</td>
                            <td>NA</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-2">
                <Footer1 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchFromSpeakerDatabaseNew;
