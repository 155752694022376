import React from 'react'
import SpeakerEngageLogo from '../../Assets/images/Speaker-Engage-Logo-svg.svg'

const LinkExpired = () => {
  return (
    <>
   <div className="main d-flex align-items-center justify-content-center bg-gray signinmain main-height">
  <div className="container">
    <div className="row wrapper2 text-center">
      <p>
        <a href="https://www.speakerengage.com/">
          <img
            src={SpeakerEngageLogo}
            className="whitelogo-login"
          />
        </a>
      </p>
    </div>
    <div className="row wrapper1">
      <div className="col signinleft border-radius-25">
        <div className="signinleftinner w-300">
          <p className="signin-text pink">Verify your Email</p>
          <p className="signin-subtext">The link has been expired or broken! Please create a new one and try again.</p>
        </div>
      </div>
    </div>
  </div>
</div>
   </>
  )
}

export default LinkExpired