import React from 'react'
import Header1 from '../Navigations/header/header1'
import Sidebar1 from '../Navigations/sidebar/sidebar1'
import Footer1 from '../Navigations/footer/footer1'
import totalEveImg from '../../Assets/images/total-eve.svg';
import dash2NewImg from '../../Assets/images/dash-2-new.svg';
import dash3Img from '../../Assets/images/dash-3.svg';
import dash4Img from '../../Assets/images/dash-4.svg';
import ImgNotAvailImg from '../../Assets/images/img-not-availabel.png';
import speakerIconImg from '../../Assets/images/speaker-icon.svg';
import editLineImg from '../../Assets/images/edit-line.svg';
import settingsLineImg from '../../Assets/images/settings-line.svg';

const DashboardEvent = () => {
  return (
    <>
  <div className="body-main bg-grey">
   <Header1/>
    <div className="body-page">
      <div className="d-flex">
        <div className="sidebar-main">
          <Sidebar1/>
        </div>
        <div className="content-main">
          <div className="min-height-pagecontent">
            <div className="welcomesec mb-4">
              <div className="welcomesec-left">
                <p className="wel-text1">Hello Kavitha,</p>
                <p className="wel-text2">
                  This is what we've got for you today.
                </p>
              </div>
              <div className="welcomesec-right">
                <a href="" className="pink-btn">
                  + Add New Event
                </a>
              </div>
            </div>
            <div className="totalsec">
              <div className="row">
                <div className="col-3 total-hover violet-card">
                  <div className="total-card">
                    <img
                      className="total-card1"
                      title=""
                      src={totalEveImg}
                    />
                    <p className="total-card2">Total Events</p>
                    <p className="total-card3">55</p>
                  </div>
                </div>
                <div className="col-3 total-hover  pink-card">
                  <div className="total-card ">
                    <img
                      className="total-card1"
                      title=""
                      src={dash2NewImg}
                    />
                    <p className="total-card2">Total Speakers</p>
                    <p className="total-card3">55</p>
                  </div>
                </div>
                <div className="col-3 total-hover  yellow-card">
                  <div className="total-card ">
                    <img
                      className="total-card1"
                      title=""
                      src={dash3Img}
                    />
                    <p className="total-card2">Total Sponsors</p>
                    <p className="total-card3">55</p>
                  </div>
                </div>
                <div className="col-3 total-hover  sky-blue-card">
                  <div className="total-card">
                    <img
                      className="total-card1"
                      title=""
                      src={dash4Img}
                    />
                    <p className="total-card2">Total Time Savings</p>
                    <p className="total-card3">55</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="progressec">
              <p className="progressectitle">Your usage at a glance</p>
              <div className="row">
                <div className="col-4 violet-card">
                  <div className="progress-main">
                    <div className="progressec1">
                      <p className="progressec1-text1">5.3%</p>
                      <p className="progressec1-text2">Out of 5000</p>
                    </div>
                    <div className="progressec2">
                      <div
                        className="progress violet"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: "25%" }}
                        />
                      </div>
                    </div>
                    <div className="progressec3">
                      <div className="progressec3-inner">
                        <p className="progressec3-text1">456</p>
                        <p className="progressec3-text2">Total Contacts</p>
                      </div>
                      <p className="progressec3-text3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={35}
                          height="100%"
                          viewBox="0 0 66.084 75.102"
                        >
                          <defs>
                            <style
                              dangerouslySetInnerHTML={{
                                __html: ".a{fill:#e4e4e4;}"
                              }}
                            />
                          </defs>
                          <g transform="translate(-809.629 -756.898)">
                            <path
                              className="a"
                              d="M875.7,766.364c0-.339-.011-.678-.027-1.016a8.844,8.844,0,0,0-8.324-8.408c-.364-.021-.729-.038-1.094-.038q-20.6,0-41.2,0a9.031,9.031,0,0,0-9.447,9.256c0,1.163,0,2.325,0,3.534-.635,0-1.154-.007-1.673,0-.91.015-1.827-.021-2.728.079a1.7,1.7,0,0,0-1.553,1.824,1.745,1.745,0,0,0,1.681,1.647c.649.044,1.3.019,1.954.021.747,0,1.494,0,2.272,0v11.729c-.712,0-1.337-.013-1.961,0a16.324,16.324,0,0,0-2.565.119,1.673,1.673,0,0,0-.9,2.837,2.744,2.744,0,0,0,1.61.6c1.261.086,2.532.027,3.789.027v11.69a1.947,1.947,0,0,1-.215.035q-1.643,0-3.284.005c-1.533,0-2.365.619-2.385,1.764-.019,1.167.844,1.82,2.409,1.821,1.163,0,2.325,0,3.469,0v11.729h-.534c-1.2,0-2.4-.026-3.6.019a1.754,1.754,0,0,0-1.744,1.671,1.721,1.721,0,0,0,1.492,1.8,9.29,9.29,0,0,0,1.475.085c.978.012,1.956,0,2.975,0,0,1.239,0,2.356,0,3.474a8.9,8.9,0,0,0,9.228,9.3c13.891.033,27.782.01,41.674.012a8.951,8.951,0,0,0,5.5-1.7,9.071,9.071,0,0,0,3.719-7.777Q875.68,794.44,875.7,766.364Zm-3.541,56.309a5.407,5.407,0,0,1-5.733,5.749q-20.762.023-41.524,0a5.419,5.419,0,0,1-5.715-5.768c0-1.116,0-2.232,0-3.45,1.337,0,2.563.044,3.783-.02a3.163,3.163,0,0,0,1.524-.459,1.551,1.551,0,0,0,.568-1.814,1.714,1.714,0,0,0-1.686-1.273c-1.353-.033-2.707-.009-4.125-.009V803.891c1.238,0,2.484.063,3.719-.027a3.294,3.294,0,0,0,1.688-.612,1.483,1.483,0,0,0,.353-1.8,1.764,1.764,0,0,0-1.712-1.139c-.859-.033-1.72-.01-2.58-.011H819.25v-11.73c1.257,0,2.528.059,3.789-.027a2.758,2.758,0,0,0,1.614-.592,1.677,1.677,0,0,0-.89-2.843,13.783,13.783,0,0,0-2.331-.12c-.722-.017-1.445,0-2.205,0V773.262c.762,0,1.508,0,2.254,0,.678,0,1.358.025,2.032-.028a1.725,1.725,0,0,0,1.622-1.7,1.655,1.655,0,0,0-1.454-1.745c-.927-.108-1.87-.079-2.806-.1-.52-.011-1.04,0-1.686,0,.1-2.021-.373-4.05.467-5.952,1.033-2.34,2.977-3.277,5.467-3.274q11.5.015,22.991,0,9.07,0,18.142,0c2.352,0,4.258.836,5.293,3.03a6.566,6.566,0,0,1,.592,2.708Q872.191,794.437,872.16,822.673Z"
                            />
                            <path
                              className="a"
                              d="M842.354,797.228a13.358,13.358,0,0,0-9.905,12.582c-.085,1.864.536,2.525,2.382,2.526h10.782q5.547,0,11.1,0c1.546,0,2.261-.726,2.19-2.264a15.948,15.948,0,0,0-.3-2.553A13.164,13.164,0,0,0,842.354,797.228Zm-6.109,11.5a9.4,9.4,0,0,1,8.722-8.306,9.5,9.5,0,0,1,10.166,8.306Z"
                            />
                            <path
                              className="a"
                              d="M834.831,812.336h10.782q5.547,0,11.1,0-5.549.012-11.1,0Z"
                            />
                            <path
                              className="a"
                              d="M845.659,793.908a8.664,8.664,0,1,0-8.651-8.641A8.667,8.667,0,0,0,845.659,793.908Zm.031-13.767a5.085,5.085,0,0,1-.01,10.169,5.059,5.059,0,0,1-5.088-5.036A5.12,5.12,0,0,1,845.69,780.141Z"
                            />
                          </g>
                        </svg>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4 yellow-card">
                  <div className="progress-main">
                    <div className="progressec1">
                      <p className="progressec1-text1">5.3%</p>
                      <p className="progressec1-text2">Out of 5000</p>
                    </div>
                    <div className="progressec2">
                      <div
                        className="progress yellow"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: "25%" }}
                        />
                      </div>
                    </div>
                    <div className="progressec3">
                      <div className="progressec3-inner">
                        <p className="progressec3-text1">456</p>
                        <p className="progressec3-text2">Total Storage</p>
                      </div>
                      <p className="progressec3-text3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={40}
                          height="100%"
                          viewBox="0 0 71 70.999"
                        >
                          <defs>
                            <style
                              dangerouslySetInnerHTML={{
                                __html: ".a{fill:#e4e4e4;}.b{fill:#f6007e;}"
                              }}
                            />
                          </defs>
                          <g transform="translate(-1286 -762.001)">
                            <path
                              className="a"
                              d="M1349.723,766.171A6.26,6.26,0,0,0,1343.48,762q-21.975,0-43.949,0a6.255,6.255,0,0,0-6.241,4.173c-2.226,5.321-4.468,10.635-6.632,15.981a9.2,9.2,0,0,0-.614,3.092c-.086,2.562-.016,5.13-.033,7.695a6.814,6.814,0,0,0,1.32,4.354.735.735,0,0,1-.006.691,6.747,6.747,0,0,0-1.313,4.286q.021,3.709,0,7.419a6.381,6.381,0,0,0,1.322,4.2.526.526,0,0,1-.014.779,6.273,6.273,0,0,0-1.306,4.071c0,2.5.052,4.993-.012,7.488a6.342,6.342,0,0,0,5.441,6.694c.041.006.078.046.117.07h59.893a7.412,7.412,0,0,0,3.24-1.425,6.191,6.191,0,0,0,2.2-3.8,2.066,2.066,0,0,1,.109-.324v-9.707a7.434,7.434,0,0,0-1.4-3.232.486.486,0,0,1,0-.468,7.443,7.443,0,0,0,1.4-3.233v-9.707a7.443,7.443,0,0,0-1.4-3.233.486.486,0,0,1,0-.468,7.434,7.434,0,0,0,1.4-3.232v-9.985c-1.557-3.918-3.1-7.84-4.676-11.752C1351.481,770.338,1350.6,768.256,1349.723,766.171Zm-55.433,8.423q1.41-3.386,2.819-6.773a2.244,2.244,0,0,1,2.493-1.659q21.9,0,43.808-.008a2.319,2.319,0,0,1,2.415,1.474q2.217,5.336,4.441,10.67c.034.081.049.169.088.3h-57.732C1293.195,777.224,1293.743,775.909,1294.29,774.594Zm56.127,54.245q-14.453,0-28.905,0h-28.7c-1.911,0-2.639-.726-2.639-2.631q0-3.709,0-7.418a2.139,2.139,0,0,1,2.422-2.43q28.905,0,57.811,0a2.14,2.14,0,0,1,2.431,2.422q0,3.813,0,7.626A2.14,2.14,0,0,1,1350.417,828.839Zm-60.242-19.274q0-3.71,0-7.419a2.14,2.14,0,0,1,2.426-2.426h57.811a2.141,2.141,0,0,1,2.427,2.426q0,3.813,0,7.627a2.141,2.141,0,0,1-2.427,2.426q-14.453,0-28.9,0h-28.7C1290.9,812.2,1290.175,811.473,1290.175,809.565Zm62.665-16.434a2.141,2.141,0,0,1-2.426,2.427q-14.452,0-28.906,0h-28.7c-1.908,0-2.635-.727-2.635-2.635q0-3.848,0-7.7a2.134,2.134,0,0,1,2.424-2.425q28.906,0,57.813,0a2.138,2.138,0,0,1,2.427,2.424Q1352.845,789.18,1352.84,793.131Z"
                            />
                            <path
                              className="b"
                              d="M1292.814,828.84h28.7q14.453,0,28.905,0-14.453,0-28.905,0Z"
                            />
                            <path
                              className="a"
                              d="M1346.807,786.98a6.9,6.9,0,0,0-.761-.018h-36.6c-1.663,0-2.63.766-2.63,2.08s.967,2.08,2.63,2.08h36.6a6.884,6.884,0,0,0,.761-.017,2.072,2.072,0,0,0,0-4.124Z"
                            />
                            <path
                              className="a"
                              d="M1296.417,786.967a2.094,2.094,0,0,0-2.078,2.064,2.092,2.092,0,1,0,4.149.006A2.094,2.094,0,0,0,1296.417,786.967Z"
                            />
                            <path
                              className="a"
                              d="M1309.447,808.039h36.6a6.908,6.908,0,0,0,.762-.017,2.073,2.073,0,0,0,0-4.125,7.166,7.166,0,0,0-.762-.017h-36.6c-1.664,0-2.631.764-2.631,2.079S1307.785,808.039,1309.447,808.039Z"
                            />
                            <path
                              className="a"
                              d="M1296.394,808.034a2.075,2.075,0,1,0-2.055-2.095A2.093,2.093,0,0,0,1296.394,808.034Z"
                            />
                            <path
                              className="a"
                              d="M1346.807,820.538a6.908,6.908,0,0,0-.762-.018h-36.6c-1.662,0-2.63.767-2.63,2.081s.967,2.079,2.631,2.079h36.6a6.884,6.884,0,0,0,.761-.017,2.072,2.072,0,0,0,0-4.124Z"
                            />
                            <path
                              className="a"
                              d="M1296.416,820.525a2.075,2.075,0,1,0,2.072,2.077A2.094,2.094,0,0,0,1296.416,820.525Z"
                            />
                          </g>
                        </svg>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4 sky-blue-card">
                  <div className="progress-main">
                    <div className="progressec1">
                      <p className="progressec1-text1">5.3%</p>
                      <p className="progressec1-text2">Out of 5000</p>
                    </div>
                    <div className="progressec2">
                      <div
                        className="progress sky-blue"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div
                          className="progress-bar"
                          style={{ width: "25%" }}
                        />
                      </div>
                    </div>
                    <div className="progressec3">
                      <div className="progressec3-inner">
                        <p className="progressec3-text1">456</p>
                        <p className="progressec3-text2">Total Storage</p>
                      </div>
                      <p className="progressec3-text3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={40}
                          height="100%"
                          viewBox="0 0 71 70.999"
                        >
                          <defs>
                            <style
                              dangerouslySetInnerHTML={{
                                __html: ".a{fill:#e4e4e4;}.b{fill:#f6007e;}"
                              }}
                            />
                          </defs>
                          <g transform="translate(-1286 -762.001)">
                            <path
                              className="a"
                              d="M1349.723,766.171A6.26,6.26,0,0,0,1343.48,762q-21.975,0-43.949,0a6.255,6.255,0,0,0-6.241,4.173c-2.226,5.321-4.468,10.635-6.632,15.981a9.2,9.2,0,0,0-.614,3.092c-.086,2.562-.016,5.13-.033,7.695a6.814,6.814,0,0,0,1.32,4.354.735.735,0,0,1-.006.691,6.747,6.747,0,0,0-1.313,4.286q.021,3.709,0,7.419a6.381,6.381,0,0,0,1.322,4.2.526.526,0,0,1-.014.779,6.273,6.273,0,0,0-1.306,4.071c0,2.5.052,4.993-.012,7.488a6.342,6.342,0,0,0,5.441,6.694c.041.006.078.046.117.07h59.893a7.412,7.412,0,0,0,3.24-1.425,6.191,6.191,0,0,0,2.2-3.8,2.066,2.066,0,0,1,.109-.324v-9.707a7.434,7.434,0,0,0-1.4-3.232.486.486,0,0,1,0-.468,7.443,7.443,0,0,0,1.4-3.233v-9.707a7.443,7.443,0,0,0-1.4-3.233.486.486,0,0,1,0-.468,7.434,7.434,0,0,0,1.4-3.232v-9.985c-1.557-3.918-3.1-7.84-4.676-11.752C1351.481,770.338,1350.6,768.256,1349.723,766.171Zm-55.433,8.423q1.41-3.386,2.819-6.773a2.244,2.244,0,0,1,2.493-1.659q21.9,0,43.808-.008a2.319,2.319,0,0,1,2.415,1.474q2.217,5.336,4.441,10.67c.034.081.049.169.088.3h-57.732C1293.195,777.224,1293.743,775.909,1294.29,774.594Zm56.127,54.245q-14.453,0-28.905,0h-28.7c-1.911,0-2.639-.726-2.639-2.631q0-3.709,0-7.418a2.139,2.139,0,0,1,2.422-2.43q28.905,0,57.811,0a2.14,2.14,0,0,1,2.431,2.422q0,3.813,0,7.626A2.14,2.14,0,0,1,1350.417,828.839Zm-60.242-19.274q0-3.71,0-7.419a2.14,2.14,0,0,1,2.426-2.426h57.811a2.141,2.141,0,0,1,2.427,2.426q0,3.813,0,7.627a2.141,2.141,0,0,1-2.427,2.426q-14.453,0-28.9,0h-28.7C1290.9,812.2,1290.175,811.473,1290.175,809.565Zm62.665-16.434a2.141,2.141,0,0,1-2.426,2.427q-14.452,0-28.906,0h-28.7c-1.908,0-2.635-.727-2.635-2.635q0-3.848,0-7.7a2.134,2.134,0,0,1,2.424-2.425q28.906,0,57.813,0a2.138,2.138,0,0,1,2.427,2.424Q1352.845,789.18,1352.84,793.131Z"
                            />
                            <path
                              className="b"
                              d="M1292.814,828.84h28.7q14.453,0,28.905,0-14.453,0-28.905,0Z"
                            />
                            <path
                              className="a"
                              d="M1346.807,786.98a6.9,6.9,0,0,0-.761-.018h-36.6c-1.663,0-2.63.766-2.63,2.08s.967,2.08,2.63,2.08h36.6a6.884,6.884,0,0,0,.761-.017,2.072,2.072,0,0,0,0-4.124Z"
                            />
                            <path
                              className="a"
                              d="M1296.417,786.967a2.094,2.094,0,0,0-2.078,2.064,2.092,2.092,0,1,0,4.149.006A2.094,2.094,0,0,0,1296.417,786.967Z"
                            />
                            <path
                              className="a"
                              d="M1309.447,808.039h36.6a6.908,6.908,0,0,0,.762-.017,2.073,2.073,0,0,0,0-4.125,7.166,7.166,0,0,0-.762-.017h-36.6c-1.664,0-2.631.764-2.631,2.079S1307.785,808.039,1309.447,808.039Z"
                            />
                            <path
                              className="a"
                              d="M1296.394,808.034a2.075,2.075,0,1,0-2.055-2.095A2.093,2.093,0,0,0,1296.394,808.034Z"
                            />
                            <path
                              className="a"
                              d="M1346.807,820.538a6.908,6.908,0,0,0-.762-.018h-36.6c-1.662,0-2.63.767-2.63,2.081s.967,2.079,2.631,2.079h36.6a6.884,6.884,0,0,0,.761-.017,2.072,2.072,0,0,0,0-4.124Z"
                            />
                            <path
                              className="a"
                              d="M1296.416,820.525a2.075,2.075,0,1,0,2.072,2.077A2.094,2.094,0,0,0,1296.416,820.525Z"
                            />
                          </g>
                        </svg>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="custom-hr" />
            <div className="eventsec">
              <ul className="event-tab nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true"
                  >
                    Current Events
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link no-border"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Past Events
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex={0}
                >
                  <div className="event-inner">
                    <div className="event-inner1">
                      <p>
                        All Events : <span className="pink">10</span>
                      </p>
                      <form className="row row-cols-lg-auto g-3 align-items-center">
                        <div className="col-12">
                          <div className="input-group search">
                            <div className="input-group-text">
                              <i className="bi bi-search" />
                            </div>
                            <input
                              type="text"
                              className="form-control search"
                              id="inlineFormInputGroupUsername"
                              placeholder="Search your event"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="event-inner-2 text-center">
                      <p className="no-event">You currently have no events.</p>
                      <p>
                        {" "}
                        <a href="" className="pink-btn">
                          + Add New Event
                        </a>
                      </p>
                    </div>
                    <div className="event-inner-3">
                      <div className="row">
                        <div className="col-4">
                          <div className="event-card-inner">
                            <a href="#">
                              <p className="d-flex mb-3">
                                <img
                                  className="event-card-thumb"
                                  src={ImgNotAvailImg}
                                />
                              </p>
                            </a>
                            <p className="event-title">
                              <a href="#">Event Industry</a>
                            </p>
                            <p className="event-date">Dates</p>
                            <p className="event-date1">
                              16-Jun-2022 | 30-Jun-2022
                            </p>
                            <div className="event-card-1">
                              <div className="event-card-2">
                                <img
                                  className="event-card-img"
                                  title=""
                                  src={speakerIconImg}
                                />
                                <div className="event-card-3">
                                  <p className="event-card-text1">1 Approved</p>
                                  <p className="event-card-text2">
                                    Out of 5 Speakers
                                  </p>
                                </div>
                              </div>
                              <div className="event-card-4">
                                <a href="#">
                                  <img
                                    className="event-card-img1"
                                    title=""
                                    src={editLineImg}
                                  />
                                </a>
                                <a href="#">
                                  <img
                                    className="event-card-img2"
                                    title=""
                                    src={settingsLineImg}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="event-card-inner">
                            <a href="#">
                              <p className="d-flex mb-3">
                                <img
                                  className="event-card-thumb"
                                  src={ImgNotAvailImg}
                                />
                              </p>
                            </a>
                            <p className="event-title">
                              <a href="#">Event Industry</a>
                            </p>
                            <p className="event-date">Dates</p>
                            <p className="event-date1">
                              16-Jun-2022 | 30-Jun-2022
                            </p>
                            <div className="event-card-1">
                              <div className="event-card-2">
                                <img
                                  className="event-card-img"
                                  title=""
                                  src={speakerIconImg}
                                />
                                <div className="event-card-3">
                                  <p className="event-card-text1">1 Approved</p>
                                  <p className="event-card-text2">
                                    Out of 5 Speakers
                                  </p>
                                </div>
                              </div>
                              <div className="event-card-4">
                                <a href="#">
                                  <img
                                    className="event-card-img1"
                                    title=""
                                    src={editLineImg}
                                  />
                                </a>
                                <a href="#">
                                  <img
                                    className="event-card-img2"
                                    title=""
                                    src={settingsLineImg}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="event-card-inner">
                            <a href="#">
                              <p className="d-flex mb-3">
                                <img
                                  className="event-card-thumb"
                                  src={ImgNotAvailImg}
                                />
                              </p>
                            </a>
                            <p className="event-title">
                              <a href="#">Event Industry</a>
                            </p>
                            <p className="event-date">Dates</p>
                            <p className="event-date1">
                              16-Jun-2022 | 30-Jun-2022
                            </p>
                            <div className="event-card-1">
                              <div className="event-card-2">
                                <img
                                  className="event-card-img"
                                  title=""
                                  src={speakerIconImg}
                                />
                                <div className="event-card-3">
                                  <p className="event-card-text1">1 Approved</p>
                                  <p className="event-card-text2">
                                    Out of 5 Speakers
                                  </p>
                                </div>
                              </div>
                              <div className="event-card-4">
                                <a href="#">
                                  <img
                                    className="event-card-img1"
                                    title=""
                                    src={editLineImg}
                                  />
                                </a>
                                <a href="#">
                                  <img
                                    className="event-card-img2"
                                    title=""
                                    src={settingsLineImg}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="event-card-inner">
                            <a href="#">
                              <p className="d-flex mb-3">
                                <img
                                  className="event-card-thumb"
                                  src={ImgNotAvailImg}
                                />
                              </p>
                            </a>
                            <p className="event-title">
                              <a href="#">Event Industry</a>
                            </p>
                            <p className="event-date">Dates</p>
                            <p className="event-date1">
                              16-Jun-2022 | 30-Jun-2022
                            </p>
                            <div className="event-card-1">
                              <div className="event-card-2">
                                <img
                                  className="event-card-img"
                                  title=""
                                  src={speakerIconImg}
                                />
                                <div className="event-card-3">
                                  <p className="event-card-text1">1 Approved</p>
                                  <p className="event-card-text2">
                                    Out of 5 Speakers
                                  </p>
                                </div>
                              </div>
                              <div className="event-card-4">
                                <a href="#">
                                  <img
                                    className="event-card-img1"
                                    title=""
                                    src={editLineImg}
                                  />
                                </a>
                                <a href="#">
                                  <img
                                    className="event-card-img2"
                                    title=""
                                    src={settingsLineImg}
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabIndex={0}
                >
                  ...
                </div>
              </div>
            </div>
          </div>
          <div className="footer-2">
            <Footer1/>
          </div>
        </div>
      </div>
    </div>
  </div>
  {"{"}% endblock %{"}"}
</>

  )
}

export default DashboardEvent