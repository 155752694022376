import React from "react";
import Footer1 from "../Navigations/footer/footer1";
import SpeakerEngageLogo from '../../Assets/images/Speaker Engage Logo-svg.svg'
import ThumpsUpImage from '../../Assets/images/meylah-sucess.jpg';

function OrganizationSuccess() {
  return (
    <>
      <div className="main d-flex align-items-center justify-content-center bg-gray signinmain main-height">
        <div className="container">
          <div className="row wrapper2 text-center">
            <p>
              <a href="https://www.speakerengage.com/">
                <img
                  src={SpeakerEngageLogo}
                  className="whitelogo-login"
                />
              </a>
            </p>
          </div>
          <div className="row wrapper1">
            <div className="col signinleft border-radius-25">
              <div className="signinleftinner w-300 text-center">
                <p className="sucess-text1">CONGRATULATIONS!!</p>
                <p className="sucess-text2">Organization Name</p>
                <img
                  src={ThumpsUpImage}
                  className="success-img"
                />
                <p className="signin-subtext font-14">
                  Your organization name has been choosen!
                  <br /> Please log in to continue.
                </p>
                <div className="email-login">
                  <button type="submit" className="signin-submit">
                    Log In
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  );
}

export default OrganizationSuccess;
