import React from 'react'
import Header1 from '../../Navigations/header/header1'
import Sidebar2 from '../../Navigations/sidebar/sidebar2'
import Footer1 from '../../Navigations/footer/footer1'

function EditSpeakerApplication() {
  return (
   <>
   <>
  <div className="body-main bg-grey">
    <Header1/>
    <div className="body-page">
      <div className="d-flex">
        <div className="sidebar-main">
          <Sidebar2/>
        </div>
        <div className="content-main">
          <div className="min-height-pagecontent">
            <div className="full-page-form app-tooltip-common">
              <div className="spk-1 dgrey_border_line d_padding_bottom20">
                <p className="d_font_size">
                  Edit Speaker Application{" "}
                  <i className="sky-blue bi bi-exclamation-circle d_notification_icon">
                    <span className="d-app-tooltip-commontext">
                      This feature lets you customize your speaker application
                      form. There are three sections to the speaker application.
                      Standard fields for personal information. Additional
                      Fields that consiste of three types of questions you can
                      use to customize application form. Acknowledgement section
                      so applicants can agree to your event terms.
                    </span>
                  </i>
                </p>
              </div>
              <ul
                className="nav nav-pills d_nav_tabs d_margin_bottom20"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Standard Fields
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Additional Fields
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Acknowledgement
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <h6 className="sky-blue">Personal Details</h6>
                  <form
                    id="speakerForm"
                    className="d_padding_top20 d_border_blue d_margin_top20"
                  >
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            {" "}
                            Name <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="speaker_name"
                            id="name"
                            className="form-control sp-form-input"
                            placeholder="Name"
                          />
                          <p className="error-msg" id="error-msg-name" />
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Email Address <span className="red">*</span>{" "}
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control sp-form-input"
                            placeholder="Email Address"
                          />
                          <p className="error-msg" id="error-msg-enddate" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Phone Number <span className="red">*</span>
                          </label>
                          <input
                            type="tel"
                            name="phone"
                            id="phone_number"
                            className="form-control sp-form-input"
                            placeholder="Phone Number"
                          />
                          <p className="error-msg" id="error-msg-elocation" />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Company <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="company_name"
                            id="company_name"
                            className="form-control sp-form-input"
                            placeholder="Company"
                          />
                          <p className="error-msg" id="error-msg-elocation" />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Job Title <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="job_title"
                            id="job_title"
                            className="form-control sp-form-input"
                            placeholder="Job Title"
                          />
                          <p className="error-msg" id="error-msg-elocation" />
                          <p className="error-msg" id="error-msg-etimezone" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Your Bio <span className="red">*</span>{" "}
                          </label>
                          <textarea
                            className="form-control sp-form-input"
                            placeholder="Your Bio"
                            rows={7}
                            name="your_quote"
                            id="your_quote"
                            defaultValue={"           "}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <div className="upload-label">
                            <label className="form-label sp-form-label">
                              Upload Headshot <span className="red">*</span>
                            </label>
                          </div>
                          <div className="image_area sp-form-uplopad">
                            <label
                              htmlFor="upload_image"
                              className="form-upload"
                            >
                              <img
                                src="{{ url_for('static', filename='images/cloud-computing.png') }}"
                                id="uploaded_image"
                                className="img-responsive img-circle"
                              />
                              <input
                                type="file"
                                name="image"
                                className="image"
                                id="upload_image"
                                style={{ display: "none" }}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Your Quote <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="quote"
                            id="quote"
                            className="form-control sp-form-input"
                            placeholder="Your Quote"
                          />
                          <p className="error-msg" id="error-msg-elocation" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Address Line 1 <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="address_line1"
                            id="address_line1"
                            className="form-control sp-form-input"
                            placeholder="Address Line 1"
                          />
                          <p className="error-msg" id="error-msg-elocation" />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Address Line 2 <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="address_line2"
                            id="address_line2"
                            className="form-control sp-form-input"
                            placeholder="Address Line 2"
                          />
                          <p className="error-msg" id="error-msg-elocation" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Country <span className="red">*</span>{" "}
                          </label>
                          <select
                            name="Country"
                            id="Country"
                            className="form-control form-select sp-form-input spk-select"
                          >
                            <option value="">Select Country</option>
                            <option value="">India</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            State <span className="red">*</span>{" "}
                          </label>
                          <select
                            name="State"
                            id="State"
                            className="form-control form-select sp-form-input spk-select"
                          >
                            <option value="">Select Country first</option>
                            <option value="">Karnataka</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            City <span className="red">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            name="City"
                            id="City"
                            className="form-control sp-form-input"
                            placeholder="City"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Zip Code <span className="red">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            name="Zip_code"
                            id="Zip_code"
                            className="form-control sp-form-input"
                            placeholder="Zip code"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="form-title text-left mb-4 mt-4">
                      Speaker Manager Details
                    </p>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            {" "}
                            Speaker Manager Name <span className="red">
                              *
                            </span>{" "}
                          </label>
                          <input
                            type="text"
                            name="speaker_manager_name"
                            id="speaker_manager_name"
                            className="form-control sp-form-input"
                            placeholder="Speaker Manager Name"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Speaker Manager Email Address{" "}
                            <span className="red">*</span>{" "}
                          </label>
                          <input
                            type="email"
                            name="speaker_manager_email"
                            id="speaker_manager_email"
                            className="form-control sp-form-input"
                            placeholder="Speaker Manager Email"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Speaker Manager Phone Number
                            <span className="red">*</span>{" "}
                          </label>
                          <input
                            type="tel"
                            name="speaker_manager_phone"
                            id="speaker_manager_phone"
                            className="form-control sp-form-input"
                            placeholder="Speaker Manager Phone"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="form-title text-left mb-4 mt-4">
                      Social Media Details
                    </p>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Twitter Handle <span className="red">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            name="twitter_handle"
                            id="twitter_handle"
                            className="form-control sp-form-input"
                            placeholder="https://www.twitter.com"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            LinkedIn URL{" "}
                            <small>
                              (Provide access to linkedin url for tagging the
                              post)
                            </small>{" "}
                            <span className="red">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            name="Linkedin_url"
                            id="Linkedin_url"
                            className="form-control sp-form-input"
                            placeholder="https://www.linkedin.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Instagram URL <span className="red">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            name="instagram_url"
                            id="instagram_url"
                            className="form-control sp-form-input"
                            placeholder="https://www.instagram.com"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Facebook URL <span className="red">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            name="Facebook_url"
                            id="Facebook_url"
                            className="form-control sp-form-input"
                            placeholder="https://www.facebook.com"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="form-title pb-3 ">
                      Are you willing to promote this event to your community?
                    </p>
                    <div className="d-flex mb-4 pb-3">
                      <label className="radio-container">
                        yes
                        <input
                          type="radio"
                          defaultChecked="checked"
                          name="radio"
                        />
                        <span className="checkmark" />
                      </label>
                      <label className="radio-container">
                        No
                        <input type="radio" name="radio" />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <p className="form-title pb-3">
                      What would you like us to provide you?
                    </p>
                    <div className="row d_margin_bottom20">
                      <div className="col-4">
                        <label className="check-container">
                          Social Media Promo Kit
                          <input type="checkbox" defaultChecked="checked" />
                          <span className="check-checkmark" />
                        </label>
                      </div>
                      <div className="col-3">
                        <label className="check-container">
                          Discount Code
                          <input type="checkbox" />
                          <span className="check-checkmark" />
                        </label>
                      </div>
                      <div className="col-2">
                        <label className="check-container">
                          Flyer
                          <input type="checkbox" />
                          <span className="check-checkmark" />
                        </label>
                      </div>
                      <div className="col-3">
                        <label className="check-container">
                          Press Kit
                          <input type="checkbox" />
                          <span className="check-checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-right d_margin_top40 ">
                      <button type="submit" className="submit-btn ">
                        NEXT
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <h6 className="sky-blue">Additional Fields</h6>
                  <form className="d_padding_top20 d_border_blue d_margin_top20">
                    <div className="row">
                      <div className="col-md-3">
                        <ul className="d_question_list">
                          <li>
                            <a
                              href="#"
                              className="question-link"
                              id="question1"
                              draggable="true"
                            >
                              <i className="bi bi-input-cursor" />
                              Questions
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="question-link"
                              id="question2"
                              draggable="true"
                            >
                              <i className="bi bi-card-list" />
                              Multiple Choice
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="question-link"
                              id="question2"
                              draggable="true"
                            >
                              <i className="bi bi-card-list" />
                              Choice
                            </a>
                          </li>
                        </ul>
                        {/* Add more question links as needed */}
                      </div>
                      <div className="col-md-9">
                        <div
                          id="question-container"
                          className="d_question-container"
                        >
                          Drag a field from the right to this area
                        </div>
                      </div>
                      <div className="d-flex justify-content-right d_margin_top40 ">
                        <button type="submit" className="submit-btn ">
                          SAVE
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-6">
                      <div className=" justify-content-left d_margin_top40">
                        <button type="submit" className="submit-btn violet-btn">
                          PREVIOUS
                        </button>
                      </div>
                    </div>
                    <div className="col-6 justify-content-right ">
                      <div className="justify-content-right d_margin_top40 d_float_right">
                        <button type="submit" className="submit-btn">
                          NEXT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <form
                    id="speakerForm"
                    className="d_padding_top20 d_margin_top20"
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="input-main">
                          <p className="form-control sp-form-input d_font_size15">
                            Speakers acknowledge and agree that the Summit
                            organizer may take photographs/videos which could
                            include images of the Speaker while attending the
                            Exhibition and Conference (the 'Images'). The
                            Speaker hereby consents to and grants to the
                            organizer, unrestricted, irrevocable, perpetual,
                            worldwide, royalty free and transferable right and
                            license to use (and grant others the right to use)
                            the Images worldwide without compensation.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <input
                            type="text"
                            name="quote"
                            id="quote"
                            className="form-control sp-form-input d_font_size15"
                            defaultValue="I Agree"
                          />
                          <p className="error-msg" id="error-msg-elocation" />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="d-flex justify-content-right d_margin_top40 ">
                    <button type="submit" className="submit-btn ">
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-2">
    <Footer1/>
  </div>
</>

   </>
  )
}

export default EditSpeakerApplication