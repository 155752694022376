import React from 'react'
import Header1 from '../../Navigations/header/header1'
import Sidebar2 from '../../Navigations/sidebar/sidebar2'
import Footer1 from '../../Navigations/footer/footer1';
import webBanner3bImg from "../../../Assets/images/default_avatar.jpg";

function NotifySpeaker() {
  return (
    <>
    <div className="body-main bg-grey">
      <Header1/>
      <div className="body-page">
        <div className="d-flex">
          <div className="sidebar-main">
            <Sidebar2/>
          </div>
          <div className="content-main">
            <div className="min-height-pagecontent">
              <div className="full-page-form">
                <div className="spk-1 d_padding_bottom20 d_margin_bottom20">
                  <p className="d_font_size text-center d_margin_auto">
                    <b>Sanity Check : Notify User</b>
                  </p>
                </div>
                <table className="table table-responsive d_notify_table">
                  <thead className="d-whitetable-header ">
                    <tr>
                      <th className="d_width_300">
                        <p>Name</p>
                      </th>
                      <th className="d_width_300">
                        <p>Email Address</p>
                      </th>
                      <th className="d_width_300">
                        <p>Company</p>
                      </th>
                      <th className="d_width_300">
                        <p>Status</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p className="sky-blue">Prajna Shetty</p>
                      </td>
                      <td>
                        <p className="sky-blue">prajna.s@iverbinde.co </p>
                      </td>
                      <td>
                        <p />
                      </td>
                      <td>
                        {" "}
                        <select
                          name="Status"
                          id="Status"
                          className="form-control form-select sp-form-input spk-select"
                        >
                          <option value="">Change the Status</option>
                          <option value="" selected="">
                            Approved
                          </option>
                          <option value="">In Contract</option>
                          <option value="">In-Review</option>
                          <option value="">New</option>
                          <option value="">Rejected</option>
                          <option value="">Research</option>
                        </select>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <form id="speakerForm" className="d_padding_top20">
                  <div className="row">
                    <div className="col-6">
                      <div className="input-main">
                        <label className="form-label sp-form-label">
                          Select Email Template<span className="red">*</span>{" "}
                        </label>
                        <select
                          name="Status"
                          id="Status"
                          className="form-control form-select sp-form-input spk-select"
                        >
                          <option value="">Pick an email template</option>
                          <option value="">Call for Speakers</option>
                          <option value="">Invitation to Speak</option>
                          <option value="">
                            Speaker Interest Application Received
                          </option>
                          <option value="">Submission Approved</option>
                          <option value="">Submission Denied</option>
                          <option value="">
                            Submit Your Speaker Bio &amp; Headshot
                          </option>
                          <option value="">Speaker Network Discount Code</option>
                          <option value="">Travel Discounts</option>
                          <option value="">
                            Join Us for Speaker Orientation
                          </option>
                          <option value="">Detailed Agenda Available</option>
                          <option value="">
                            Upload Your Speaker Presentation File (with PPT
                            Template)
                          </option>
                          <option value="">
                            Event Logistics - Here what you can expect.
                          </option>
                          <option value="">Welcome Reception Invitation </option>
                          <option value="">
                            Thank You - Photos &amp; Videos Now Available
                          </option>
                          <option value="">
                            Post-Event Celebration Invitation
                          </option>
                          <option value="">News Letter Template </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-main">
                        <label className="form-label sp-form-label">
                          Email Subject <span className="red">*</span>{" "}
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control sp-form-input"
                          placeholder="Email Subject"
                        />
                        <p className="error-msg" id="error-msg-enddate" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="input-main">
                        <label className="form-label sp-form-label">
                          Speaker Manager Email
                        </label>
                        <input
                          type="email"
                          name="phone"
                          id="phone_number"
                          className="form-control sp-form-input"
                          placeholder="Speaker Manager Email"
                        />
                        <p className="error-msg" id="error-msg-elocation" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="input-main">
                        <label className="form-label sp-form-label">
                          Cc Email Addresses (with comma separated){" "}
                        </label>
                        <input
                          type="text"
                          name="company_name"
                          id="company_name"
                          className="form-control sp-form-input"
                          placeholder="Cc Email Addresses"
                        />
                        <p className="error-msg" id="error-msg-elocation" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="input-main">
                        <label className="form-label sp-form-label d_width100">
                          Email Content <span className="red">*</span>
                          <div className="d_float_right ">
                            <a href="#" className="d_upload_files pull-right">
                              <span className=" d_dark_blue_text">
                                Text Editor Guide
                              </span>
                            </a>
                          </div>
                        </label>
                        <textarea
                          className="form-control sp-form-input"
                          placeholder="Your Quote"
                          rows={7}
                          name="your_quote"
                          id="your_quote"
                          defaultValue={"                  "}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d_hint d_margin_bottom20">
                    <b> Hint: </b>Please use [person-name] to fetch a person name
                    automatically!
                  </div>
                  <div className="clearfix d_width100">
                    <div className="d-flex d_vertical_middle  d_margin_top20 d_float_right d_margin_bottom20 d_justify_content">
                      <div className="d_font_size23 sky-blue d_margin_right10">
                        {" "}
                        <a
                          className="d_cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#Modal11"
                        >
                          {" "}
                          <i className="bi bi-paperclip sky-blue" />
                        </a>
                      </div>
                      <a className="submit-btn margin-right-10 d_font_size15 d_cursor_pointer">
                        <i className="bi bi-send" />
                        Send Now
                      </a>
                      <a
                        className="d_cursor_pointer yellow-btn margin-right-10 d_lineheight18"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal10"
                      >
                        <i className="bi bi-calendar-check-fill margin-right-10" />
                        Send Later
                      </a>
                      <a className="pink-btn margin-right-10 d_lineheight18">
                        <i className="bi bi-trash margin-right-10" />
                        Discard
                      </a>
                    </div>
                  </div>
                  <div
                    className="modal fade d_modal "
                    id="Modal10"
                    tabIndex={-1}
                    aria-labelledby="Modal10"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-md modal-dialog-centered">
                      <div className="modal-content d_padding_10">
                        <div className="modal-header d_modal_border0">
                          <h6
                            className="d_font_family text-center d_margin_auto modal-title"
                            id="Modal10"
                          >
                            SELECT THE DATE &amp; TIME{" "}
                          </h6>
                          <button
                            type="button"
                            className="d_modal_closebtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="bi bi-x pink" />
                          </button>
                        </div>
                        <div className="modal-body d_padding_bottom0">
                          <div className="row">
                            <div className="col-12">
                              <div className="">
                                <select
                                  name="timezone"
                                  id="timezone"
                                  className="form-control form-select sp-form-input"
                                  aria-label="Default select example"
                                >
                                  <option value="">
                                    Select Schedule date &amp; time
                                  </option>
                                  <option value={61}>(GMT+02:00) Cairo </option>
                                  <option value={32}>
                                    (GMT+00:00) Casablanca{" "}
                                  </option>
                                  <option value={62}>(GMT+02:00) Harare </option>
                                  <option value={67}>
                                    (GMT+02:00) Pretoria{" "}
                                  </option>
                                  <option value={57}>
                                    (GMT+01:00) West Central Africa{" "}
                                  </option>
                                  <option value={37}>
                                    (GMT+00:00) Monrovia{" "}
                                  </option>
                                  <option value={75}>(GMT+03:00) Nairobi </option>
                                  <option value={4}>(GMT-09:00) Alaska </option>
                                  <option value={26}>
                                    (GMT-03:00) Buenos Aires{" "}
                                  </option>
                                  <option value={27}>
                                    (GMT-03:00) Georgetown{" "}
                                  </option>
                                  <option value={16}>(GMT-05:00) Bogota </option>
                                  <option value={19}>(GMT-05:00) Quito </option>
                                  <option value={21}>(GMT-04:30) Caracas </option>
                                  <option value={12}>
                                    (GMT-06:00) Central Time (US &amp; Canada){" "}
                                  </option>
                                  <option value={7}>
                                    (GMT-07:00) Chihuahua{" "}
                                  </option>
                                  <option value={8}>(GMT-07:00) La Paz </option>
                                  <option value={10}>
                                    (GMT-07:00) Mountain Time (US &amp; Canada){" "}
                                  </option>
                                  <option value={28}>
                                    (GMT-03:00) Greenland{" "}
                                  </option>
                                  <option value={22}>(GMT-04:00) La Paz </option>
                                  <option value={18}>(GMT-05:00) Lima </option>
                                  <option value={5} selected="">
                                    (GMT-08:00) Pacific Time (US &amp; Canada){" "}
                                  </option>
                                  <option value={11}>
                                    (GMT-06:00) Central America{" "}
                                  </option>
                                  <option value={9}>(GMT-07:00) Mazatlan </option>
                                  <option value={13}>
                                    (GMT-06:00) Guadalajara{" "}
                                  </option>
                                  <option value={14}>
                                    (GMT-06:00) Mexico City{" "}
                                  </option>
                                  <option value={15}>
                                    (GMT-06:00) Monterrey{" "}
                                  </option>
                                  <option value={17}>
                                    (GMT-05:00) Eastern Time (US &amp; Canada){" "}
                                  </option>
                                  <option value={29}>
                                    (GMT-02:00) Mid-Atlantic{" "}
                                  </option>
                                  <option value={23}>
                                    (GMT-04:00) Santiago{" "}
                                  </option>
                                  <option value={25}>
                                    (GMT-03:00) Brasilia{" "}
                                  </option>
                                  <option value={24}>
                                    (GMT-03:30) Newfoundland{" "}
                                  </option>
                                  <option value={6}>(GMT-08:00) Tijuana </option>
                                  <option value={96}>(GMT+06:00) Almaty </option>
                                  <option value={72}>(GMT+03:00) Baghdad </option>
                                  <option value={80}>(GMT+04:00) Baku </option>
                                  <option value={101}>
                                    (GMT+07:00) Bangkok{" "}
                                  </option>
                                  <option value={102}>(GMT+07:00) Hanoi </option>
                                  <option value={90}>(GMT+05:30) Chennai </option>
                                  <option value={92}>(GMT+05:30) Mumbai </option>
                                  <option value={93}>
                                    (GMT+05:30) New Delhi{" "}
                                  </option>
                                  <option value={94}>
                                    (GMT+05:30) Sri Jayawardenepura{" "}
                                  </option>
                                  <option value={106}>
                                    (GMT+08:00) Chongqing{" "}
                                  </option>
                                  <option value={97}>(GMT+06:00) Astana </option>
                                  <option value={98}>(GMT+06:00) Dhaka </option>
                                  <option value={105}>
                                    (GMT+08:00) Beijing{" "}
                                  </option>
                                  <option value={107}>
                                    (GMT+08:00) Hong Kong{" "}
                                  </option>
                                  <option value={115}>
                                    (GMT+09:00) Irkutsk{" "}
                                  </option>
                                  <option value={103}>
                                    (GMT+07:00) Jakarta{" "}
                                  </option>
                                  <option value={65}>
                                    (GMT+02:00) Jerusalem{" "}
                                  </option>
                                  <option value={86}>(GMT+04:30) Kabul </option>
                                  <option value={134}>
                                    (GMT+12:00) Kamchatka{" "}
                                  </option>
                                  <option value={87}>
                                    (GMT+05:00) Islamabad{" "}
                                  </option>
                                  <option value={88}>(GMT+05:00) Karachi </option>
                                  <option value={95}>
                                    (GMT+05:45) Kathmandu{" "}
                                  </option>
                                  <option value={91}>(GMT+05:30) Kolkata </option>
                                  <option value={108}>
                                    (GMT+08:00) Krasnoyarsk{" "}
                                  </option>
                                  <option value={109}>
                                    (GMT+08:00) Kuala Lumpur{" "}
                                  </option>
                                  <option value={73}>(GMT+03:00) Kuwait </option>
                                  <option value={135}>
                                    (GMT+12:00) Magadan{" "}
                                  </option>
                                  <option value={137}>
                                    (GMT+12:00) New Caledonia{" "}
                                  </option>
                                  <option value={138}>
                                    (GMT+12:00) Solomon Is.{" "}
                                  </option>
                                  <option value={79}>
                                    (GMT+04:00) Abu Dhabi{" "}
                                  </option>
                                  <option value={82}>(GMT+04:00) Muscat </option>
                                  <option value={104}>
                                    (GMT+07:00) Novosibirsk{" "}
                                  </option>
                                  <option value={100}>
                                    (GMT+06:30) Rangoon{" "}
                                  </option>
                                  <option value={76}>(GMT+03:00) Riyadh </option>
                                  <option value={118}>(GMT+09:00) Seoul </option>
                                  <option value={111}>
                                    (GMT+08:00) Singapore{" "}
                                  </option>
                                  <option value={112}>(GMT+08:00) Taipei </option>
                                  <option value={89}>
                                    (GMT+05:00) Tashkent{" "}
                                  </option>
                                  <option value={84}>(GMT+04:00) Tbilisi </option>
                                  <option value={78}>(GMT+03:30) Tehran </option>
                                  <option value={116}>(GMT+09:00) Osaka </option>
                                  <option value={117}>
                                    (GMT+09:00) Sapporo{" "}
                                  </option>
                                  <option value={119}>(GMT+09:00) Tokyo </option>
                                  <option value={113}>
                                    (GMT+08:00) Ulaan Bataar{" "}
                                  </option>
                                  <option value={114}>(GMT+08:00) Urumqi </option>
                                  <option value={130}>
                                    (GMT+11:00) Vladivostok{" "}
                                  </option>
                                  <option value={129}>
                                    (GMT+10:00) Yakutsk{" "}
                                  </option>
                                  <option value={99}>
                                    (GMT+06:00) Ekaterinburg{" "}
                                  </option>
                                  <option value={85}>(GMT+04:00) Yerevan </option>
                                  <option value={30}>(GMT-01:00) Azores </option>
                                  <option value={31}>
                                    (GMT-01:00) Cape Verde Is.{" "}
                                  </option>
                                  <option value={120}>
                                    (GMT+09:30) Adelaide{" "}
                                  </option>
                                  <option value={122}>
                                    (GMT+10:00) Brisbane{" "}
                                  </option>
                                  <option value={123}>
                                    (GMT+10:00) Canberra{" "}
                                  </option>
                                  <option value={121}>(GMT+09:30) Darwin </option>
                                  <option value={125}>(GMT+10:00) Hobart </option>
                                  <option value={126}>
                                    (GMT+10:00) Melbourne{" "}
                                  </option>
                                  <option value={110}>(GMT+08:00) Perth </option>
                                  <option value={128}>(GMT+10:00) Sydney </option>
                                  <option value={20}>
                                    (GMT-04:00) Atlantic Time (Canada){" "}
                                  </option>
                                  <option value={39}>
                                    (GMT+01:00) Amsterdam{" "}
                                  </option>
                                  <option value={59}>(GMT+02:00) Athens </option>
                                  <option value={40}>
                                    (GMT+01:00) Belgrade{" "}
                                  </option>
                                  <option value={41}>(GMT+01:00) Berlin </option>
                                  <option value={42}>(GMT+01:00) Bern </option>
                                  <option value={43}>
                                    (GMT+01:00) Bratislava{" "}
                                  </option>
                                  <option value={44}>
                                    (GMT+01:00) Brussels{" "}
                                  </option>
                                  <option value={60}>
                                    (GMT+02:00) Bucharest{" "}
                                  </option>
                                  <option value={45}>
                                    (GMT+01:00) Budapest{" "}
                                  </option>
                                  <option value={46}>
                                    (GMT+01:00) Copenhagen{" "}
                                  </option>
                                  <option value={34}>(GMT+00:00) Dublin </option>
                                  <option value={63}>
                                    (GMT+02:00) Helsinki{" "}
                                  </option>
                                  <option value={66}>(GMT+02:00) Kyiv </option>
                                  <option value={64}>
                                    (GMT+02:00) Istanbul{" "}
                                  </option>
                                  <option value={35}>(GMT+00:00) Lisbon </option>
                                  <option value={47}>
                                    (GMT+01:00) Ljubljana{" "}
                                  </option>
                                  <option value={33}>
                                    (GMT+00:00) Edinburgh{" "}
                                  </option>
                                  <option value={36}>(GMT+00:00) London </option>
                                  <option value={48}>(GMT+01:00) Madrid </option>
                                  <option value={74}>(GMT+03:00) Minsk </option>
                                  <option value={81}>(GMT+04:00) Moscow </option>
                                  <option value={83}>
                                    (GMT+04:00) St. Petersburg{" "}
                                  </option>
                                  <option value={49}>(GMT+01:00) Paris </option>
                                  <option value={50}>(GMT+01:00) Prague </option>
                                  <option value={68}>(GMT+02:00) Riga </option>
                                  <option value={51}>(GMT+01:00) Rome </option>
                                  <option value={52}>
                                    (GMT+01:00) Sarajevo{" "}
                                  </option>
                                  <option value={53}>(GMT+01:00) Skopje </option>
                                  <option value={69}>(GMT+02:00) Sofia </option>
                                  <option value={54}>
                                    (GMT+01:00) Stockholm{" "}
                                  </option>
                                  <option value={70}>(GMT+02:00) Tallinn </option>
                                  <option value={55}>(GMT+01:00) Vienna </option>
                                  <option value={71}>(GMT+02:00) Vilnius </option>
                                  <option value={77}>
                                    (GMT+03:00) Volgograd{" "}
                                  </option>
                                  <option value={56}>(GMT+01:00) Warsaw </option>
                                  <option value={58}>(GMT+01:00) Zagreb </option>
                                  <option value={131}>
                                    (GMT+12:00) Auckland{" "}
                                  </option>
                                  <option value={139}>
                                    (GMT+12:00) Wellington{" "}
                                  </option>
                                  <option value={132}>(GMT+12:00) Fiji </option>
                                  <option value={136}>
                                    (GMT+12:00) Marshall Is.{" "}
                                  </option>
                                  <option value={124}>(GMT+10:00) Guam </option>
                                  <option value={3}>(GMT-10:00) Hawaii </option>
                                  <option value={133}>
                                    (GMT+12:00) International Date Line West{" "}
                                  </option>
                                  <option value={1}>
                                    (GMT-11:00) Midway Island{" "}
                                  </option>
                                  <option value={127}>
                                    (GMT+10:00) Port Moresby{" "}
                                  </option>
                                  <option value={2}>(GMT-11:00) Samoa </option>
                                  <option value={140}>
                                    (GMT+13:00) Nuku\alofa{" "}
                                  </option>
                                  <option value={38}>(GMT+00:00) UTC </option>
                                </select>
                                <p
                                  className="error-msg"
                                  id="error-msg-elocation"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex d_vertical_middle  d_margin_top20 d_float_right d_margin_bottom20 d_justify_content">
                            <a className="submit-btn margin-right-10 d_font_size15">
                              Schedule
                            </a>
                            <a className="pink-btn margin-right-10 d_lineheight18">
                              Cancel
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="d_margin_top20 d_margin_bottom10">
                    <b>Recent Communication</b>
                  </h6>
                  <div
                    className="modal fade d_modal "
                    id="Modal4"
                    tabIndex={-1}
                    aria-labelledby="Modal4"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg modal-dialog-centered ">
                      <div className="modal-content d_padding_10">
                        <div className="modal-header d_modal_border0">
                          <h5
                            className="d_font_family text-center d_margin_auto modal-title"
                            id="Modal4"
                          >
                            Thank You - Photos &amp; Videos Now Available{" "}
                          </h5>
                          <button
                            type="button"
                            className="d_modal_closebtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="bi bi-x pink" />
                          </button>
                        </div>
                        <div className="modal-body d_padding_bottom0">
                          <div className="row">
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Email Subject: </b>
                              </h6>
                              <p className="d_font_size14">
                                Thank You from ______________!
                              </p>
                            </div>
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Speaker Manager Email Address: </b>
                              </h6>
                              <p className="d_font_size14">Not Found</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b> Cc Email Address: </b>
                              </h6>
                              <p className="d_font_size14">Not Found</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b>Email Content </b>
                              </h6>
                              <div className="d_modal_email_template">
                                <img
                                  src={webBanner3bImg}
                                  className="img-responsive d_margin_bottom20"
                                />
                                <p className="d_font_size15">Hi Prajna,</p>
                                <p className="d_font_size15">
                                  We're happy to share with you the first look of
                                  the photos and videos for your enjoyment! Feel
                                  free to also share images and video of your talk
                                  with your network as you wish. Please be sure to
                                  tag us!
                                </p>
                                <p className="d_font_size15">
                                  LinkedIn &amp; Facebook:
                                </p>
                                <p>Twitter:</p>
                                <p>hashtag(s):</p>
                                <p>
                                  Take a look at all of the incredible coverage
                                  here – photos (insert link), videos (insert
                                  link)
                                </p>
                                <p>
                                  Thanks again for being a part of something big!
                                </p>
                                <p>Best regards,</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade d_modal "
                    id="Modal5"
                    tabIndex={-1}
                    aria-labelledby="Modal5"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                      <div className="modal-content d_padding_10">
                        <div className="modal-header d_modal_border0">
                          <h5
                            className="d_font_family text-center d_margin_auto modal-title"
                            id="Modal5"
                          >
                            Call for Speakers
                          </h5>
                          <button
                            type="button"
                            className="d_modal_closebtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="bi bi-x pink" />
                          </button>
                        </div>
                        <div className="modal-body d_padding_bottom0">
                          <div className="row">
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Email Subject: </b>
                              </h6>
                              <p className="d_font_size14">
                                Call for Speakers: Apply to speak at the upcoming
                                (insert event name) today
                              </p>
                            </div>
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Speaker Manager Email Address: </b>
                              </h6>
                              <p className="d_font_size14">Not Found</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b>Email Address: </b>
                              </h6>
                              <p className="d_font_size14">Not Found</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b>Email Content </b>
                              </h6>
                              <div className="d_modal_email_template">
                                <img
                                  src={webBanner3bImg}
                                  className="img-responsive d_margin_bottom20"
                                />
                                <p className="d_font_size15">Dear Prajna, </p>
                                <p className="d_font_size15">
                                  Welcome to the (insert event name)
                                </p>
                                <p className="d_font_size15">
                                  (insert event description) will be held on
                                  (date(s)). (Event description continued)
                                  covering the following areas: (Insert general
                                  topic areas)
                                </p>
                                <p>
                                  We are seeking leading experts in (subject
                                  matter) to present at our conference!
                                </p>
                                <p>The 2019 Conference will explore:</p>
                                <ul className="d_margin_top20 d_margin_bottom20">
                                  <li>Topic example</li>
                                  <li>Topic example</li>
                                  <li>Topic example</li>
                                  <li>Topic example</li>
                                  <li>Topic example</li>
                                </ul>
                                <p>
                                  <b>
                                    Apply to be a speaker today! (Insert link)
                                  </b>
                                </p>
                                <p>
                                  Who is (insert event producer company name)?
                                </p>
                                <p>(event producer company brand description)</p>
                                <p>
                                  Follow us on Facebook, LinkedIn &amp; Twitter
                                  and our website at (insert link)
                                </p>
                                <p>Best,</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade d_modal "
                    id="Modal6"
                    tabIndex={-1}
                    aria-labelledby="Modal6"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                      <div className="modal-content d_padding_10">
                        <div className="modal-header d_modal_border0">
                          <h5
                            className="d_font_family text-center d_margin_auto modal-title"
                            id="Modal6"
                          >
                            News Letter Template
                          </h5>
                          <button
                            type="button"
                            className="d_modal_closebtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="bi bi-x pink" />
                          </button>
                        </div>
                        <div className="modal-body d_padding_bottom0">
                          <div className="row">
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Email Subject: </b>
                              </h6>
                              <p className="d_font_size14">Call for customers</p>
                            </div>
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Speaker Manager Email Address: </b>
                              </h6>
                              <p className="d_font_size14">Not Found</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b>Email Address: </b>
                              </h6>
                              <p className="d_font_size14">Not Found</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b>Email Content </b>
                              </h6>
                              <div className="d_modal_email_template">
                                <img
                                  src={webBanner3bImg}
                                  className="img-responsive d_margin_bottom20"
                                />
                                <p className="d_font_size15">Dear Prajna, </p>
                                <p className="d_font_size15">
                                  Thanks,[Event/Event Producer Company] Team
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade d_modal "
                    id="Modal7"
                    tabIndex={-1}
                    aria-labelledby="Modal7"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                      <div className="modal-content d_padding_10">
                        <div className="modal-header d_modal_border0">
                          <h5
                            className="d_font_family text-center d_margin_auto modal-title"
                            id="Modal7"
                          >
                            Speaker Interest Application Received
                          </h5>
                          <button
                            type="button"
                            className="d_modal_closebtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="bi bi-x pink" />
                          </button>
                        </div>
                        <div className="modal-body d_padding_bottom0">
                          <div className="row">
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Email Subject: </b>
                              </h6>
                              <p className="d_font_size14">
                                Your Speaker Application has been received
                              </p>
                            </div>
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Speaker Manager Email Address: </b>
                              </h6>
                              <p className="d_font_size14">Not Found</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b>Email Address: </b>
                              </h6>
                              <p className="d_font_size14">
                                anweshan.developer@gmail.com
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b>Email Content </b>
                              </h6>
                              <div className="d_modal_email_template">
                                <img
                                  src={webBanner3bImg}
                                  className="img-responsive d_margin_bottom20"
                                />
                                <p className="d_font_size15">Dear Prajna, </p>
                                <p className="d_font_size15">
                                  Thank you for your interest in being a speaker
                                  at this year's [event name]. We have received
                                  your submitted form and it will now be moved
                                  into review. Once review is completed, we will
                                  contact you to let you know whether your
                                  application has been approved or denied for a
                                  speaking opportunity for this year's conference.
                                  Final speaker selections will be made by [date].
                                </p>
                                <p>
                                  Should you have any questions, please do not
                                  hesitate to contact [email address]
                                </p>
                                <p>
                                  Again, we appreciate your interest and support!
                                  Thanks for your patience and we will get back to
                                  you as soon as possible following our review!
                                </p>
                                <p>Thanks,</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="modal fade d_modal "
                    id="Modal8"
                    tabIndex={-1}
                    aria-labelledby="Modal8"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg modal-dialog-centered ">
                      <div className="modal-content d_padding_10">
                        <div className="modal-header d_modal_border0">
                          <h5
                            className="d_font_family text-center d_margin_auto modal-title"
                            id="Modal8"
                          >
                            Speaker Interest Application Received
                          </h5>
                          <button
                            type="button"
                            className="d_modal_closebtn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <i className="bi bi-x pink" />
                          </button>
                        </div>
                        <div className="modal-body d_padding_bottom0">
                          <div className="row">
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Email Subject: </b>
                              </h6>
                              <p className="d_font_size14">
                                (Insert event name) Speaker Application Status
                                Update
                              </p>
                            </div>
                            <div className="col-6 d_margin_bottom20">
                              <h6>
                                <b>Speaker Manager Email Address: </b>
                              </h6>
                              <p className="d_font_size14">Not Found</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b>Email Address: </b>
                              </h6>
                              <p className="d_font_size14">Not Found</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d_margin_bottom20">
                              <h6>
                                <b>Email Content </b>
                              </h6>
                              <div className="d_modal_email_template">
                                <img
                                  src={webBanner3bImg}
                                  className="img-responsive d_margin_bottom20"
                                />
                                <p className="d_font_size15">
                                  Dear (person-name),{" "}
                                </p>
                                <p className="d_font_size15">
                                  Thank you for your interest in participating in
                                  this year's [event name]. We regret to inform
                                  you that we are unable to approve your
                                  application at this time, but we will keep your
                                  details on file for any future opportunities. We
                                  would love it if you would still be able to
                                  attend the event and share your feedback on your
                                  experience. We invite you to use the discount
                                  code [discount code] when you register to get
                                  (discount amount) off of your ticket.
                                </p>
                                <p>Register here to attend: (ticket URL)</p>
                                <p>
                                  Again, thank you for your submission and we look
                                  forward to welcoming you as an attendee this
                                  year.
                                </p>
                                <p>Best regards,</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table table-responsive d-whitetable d_table_boxshadow d_border_radius10 d_notify_border">
                    <thead className="d-whitetable-header ">
                      <tr>
                        <th>
                          <p></p>
                        </th>
                        <th>
                          <p />
                        </th>
                        <th>
                          <p />
                        </th>
                        <th>
                          <p />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p>
                            <a
                              className="d_cursor_pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#Modal4"
                            >
                              Thank You - Photos &amp; Videos Now Available
                            </a>
                          </p>
                        </td>
                        <td>
                          <p> 10-Dec-2021</p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <a
                              className="d_cursor_pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#Modal5"
                            >
                              Call for Speakers
                            </a>
                          </p>
                        </td>
                        <td>
                          <p> 10-Dec-2021</p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <a
                              className="d_cursor_pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#Modal6"
                            >
                              News Letter Template
                            </a>
                          </p>
                        </td>
                        <td>
                          <p> 10-Dec-2021</p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <a
                              className="d_cursor_pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#Modal7"
                            >
                              Speaker Interest Application Received
                            </a>
                          </p>
                        </td>
                        <td>
                          <p> 10-Dec-2021</p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            <a
                              className="d_cursor_pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#Modal8"
                            >
                              Submission Denied
                            </a>
                          </p>
                        </td>
                        <td>
                          <p> 10-Dec-2021</p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                        <td>
                          <p>
                            <i className="bi bi-check-circle-fill" />
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal11"
      tabIndex={-1}
      aria-labelledby="Modal11"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content d_padding_10">
          <div className="modal-header d_modal_border0">
            <h6
              className="d_font_family text-center d_margin_auto modal-title"
              id="Modal11"
            >
              <b>Select Resources </b>{" "}
            </h6>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            <table className="table table-responsive d_notify_table">
              <thead className="d-whitetable-header ">
                <tr>
                  <th className="d_width_300">
                    <p>
                      <b>Select</b>
                    </p>
                  </th>
                  <th className="d_width_300">
                    <p>
                      <b>Resources Name</b>
                    </p>
                  </th>
                  <th className="d_width_300">
                    <p>
                      <b>Owner</b>
                    </p>
                  </th>
                  <th className="d_width_300">
                    <p>
                      <b>Last Updated</b>
                    </p>
                  </th>
                  <th className="d_width_300">
                    <p>
                      <b>Type</b>
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>No records found!</p>
                  </td>
                  <td />
                  <td />
                  <td> </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex d_vertical_middle  d_margin_top20 d_float_right d_margin_bottom20 d_justify_content">
              <a className="submit-btn margin-right-10 d_font_size15">Add</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-2">
     <Footer1/>
    </div>
  </>
  
  )
}

export default NotifySpeaker
