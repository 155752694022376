import React from 'react'
import Header1 from '../../Navigations/header/header1'
import Sidebar2 from '../../Navigations/sidebar/sidebar2'
import Footer1 from '../../Navigations/footer/footer1'
import emailSentIcon from '../../../Assets/images/email-sent.svg';
import notifyIcon from '../../../Assets/images/notify.svg';
import editIcon from '../../../Assets/images/edit.svg';
import viewIcon from '../../../Assets/images/view.svg';
import deleteIcon from '../../../Assets/images/delete.svg';
import requestIcon from '../../../Assets/images/request.svg';
import defaultAvatarImg from "../../../Assets/images/default_avatar.jpg";
import rtmdashboardIcon from "../../../Assets/images/rtm_dashboard.png";
import webBanner3bImg from "../../../Assets/images/default_avatar.jpg";

function NewSpeakers() {
  return (
    <>
    <div className="body-main bg-grey">
  <Header1/>
  <div className="body-page">
    <div className="d-flex">
      <div className="sidebar-main">
       <Sidebar2/>
      </div>
      <div className="content-main">
        <div className="min-height-pagecontent">
          <div className="spk-main">
            <div className="spk-1">
              <div className="spk-inner-left">
                <p className="spk-title">Sanity Check : New Speakers</p>
                <button
                  type="button"
                  className="no-btn"
                  s-toggle="tooltip"
                  data-bs-trigger="manual"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-html="true"
                  customclass="testr"
                  title="<p>The list below lists new speakers who have applied to participate in this event.  Review the new speaker list and change their status as needed.  To learn more, please refer to  <a href='/support/speaker.php, target='_blank'>FAQ</a> page.</p>"
                >
                  <i className="bi bi-info-circle sky-blue" />
                </button>
              </div>
            </div>
            <div className="spk-3 mt-4 d-none">
              <form>
                <div className="d-flex">
                  <button className="red-btn margin-right-10">
                    <i className="bi bi-trash padding-bootom-2" /> Delete
                    Selected
                  </button>
                  <select
                    className="form-select w-200 spk-select"
                    aria-label="Default select example"
                  >
                    <option selected=""> Change Status</option>
                    <option value={1}>One</option>
                    <option value={2}>Two</option>
                    <option value={3}>Three</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="spk-4 my-0 d_table_search">
              <table
                id="all-speakers"
                className="stripe row-border order-column nowrap custom-table "
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th />
                    <th>Speaker Name</th>
                    <th>Speaker Type</th>
                    <th>Status</th>
                    <th>Email Address</th>
                    <th>Contact Number</th>
                    <th>Company</th>
                    <th>
                      Latest Email
                      <br /> Sent
                    </th>
                    <th className="d_text_center d_manage_before">Manage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td />
                    <td>
                      <div>
                        <div className="spk-name">
                          <p className="spk-name-txt1">Kavitha</p>
                          <div className="spk-name">
                            <p>
                              <i className="bi bi-exclamation-triangle-fill sky-blue" />
                            </p>
                            <p className="spk-name-txt2 sky-blue">16%</p>
                          </div>
                        </div>
                        <div
                          className="progress sky-blue"
                          style={{ height: 2 }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "25%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="d_font_size14">Breakouts</p>
                    </td>
                    <td>
                      <p className="d_font_size14">Approved</p>
                    </td>
                    <td>
                      <p className="d_font_size14">prajna.s@iverbinde.co</p>
                    </td>
                    <td className="d_font_size14">None select ..</td>
                    <td className="d_font_size14">test company</td>
                    <td>
                      <div className="text-center" title="Preview">
                        <a
                          className="d_cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#Modal2"
                        >
                          {" "}
                          <p>
                            <img src={emailSentIcon} />
                          </p>
                          <p className="preview-text">Preview(0)</p>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="action">
                        <a href="/notify-speaker">
                          <img
                            src={notifyIcon}
                            title="Notification"
                          />
                        </a>
                        <a href="/edit-speaker">
                          <img
                            src={editIcon}
                            title="Edit"
                          />
                        </a>
                        <a
                          className="d_cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#Modal9"
                        >
                          <img
                            src={viewIcon}
                            title="View"
                          />
                        </a>
                        <a
                          className="d_cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#Modal1"
                        >
                          <img
                            src={requestIcon}
                            title="Request"
                          />
                        </a>
                        <a href="#">
                          <img
                            src={deleteIcon}
                            title="Delete"
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal9"
      tabIndex={-1}
      aria-labelledby="Modal9"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header d_modal_border0">
            <h5
              className="d_font_family text-center d_margin_auto modal-title"
              id="Modal9"
            >
              Preview
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0 d_padding0">
            <div className="d_preview_modal">
              <div className="d_bg_blue clearfix">
                <div className="row">
                  <div className="col-8" />
                  <div className="col-4 d_margin_top20 ">
                    <div className="d-flex d_space_between">
                      <p className="d_text_white d_font_size14">
                        Profile Complete{" "}
                      </p>
                      <div className="d_float_right d_margin_right10 d_light_yellow d_font_size14 ">
                        <p>
                          <i className="bi bi-exclamation-triangle-fill d_light_yellow">
                            16%
                          </i>
                        </p>
                      </div>
                    </div>
                    <div className="progress d_margin_right10">
                      <div
                        className="progress-bar bg-warning"
                        style={{ width: "10%" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <h1 className="d_text_white d_text_center d_margin_top20 d_padding_bottom20">
                  <b>Prajna Shetty</b>
                </h1>
                <h6 className="d_text_white d_text_center d_padding_bottom20">
                  <b>|</b>
                </h6>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="d_preview_left">
                    <img
                      src={defaultAvatarImg}
                      className="img-responsive d_width100"
                    />
                    <p>
                      <b>Email : </b>prajna.s@iverbinde.co
                    </p>
                    <p>
                      <b>Phone No:</b>
                    </p>
                    <p>
                      <b>Company:</b>
                    </p>
                    <hr className="d_borderwidth2" />
                    <p>
                      <b>Address</b>
                    </p>
                    <p>
                      <b>City</b>
                    </p>
                    <p>
                      <b>State</b>
                    </p>
                    <p>
                      <b>Country</b>
                    </p>
                    <p>
                      <b>Speaker Manager Details</b>
                    </p>
                    <p>Speaker Manager Name:</p>
                    <p>Speaker Manager Email Address:</p>
                    <p>Speaker Manager Phone Number:</p>
                  </div>
                </div>
                <div className="col-8">
                  <div className="d_preview_right">
                    <div className="d_width100 clearfix">
                      <div className="d_border_radius10 d_nospeaker ">
                        <a>
                          <img src={rtmdashboardIcon} />
                          <span>
                            <b>Novice Speaker</b>
                          </span>
                          <i className="bi bi-download" />
                        </a>
                      </div>
                    </div>
                    <div id="" className="d_padding_top20 ">
                      <div className="row">
                        <div className="col-12 d_padding_bottom20">
                          <label className="form-label sp-form-label d_font_size15">
                            <b>QUOTE</b>
                          </label>
                          <div className="d_border_blue d_bg_light_blue d_height_150">
                            ""
                          </div>
                        </div>
                        <div className="col-12 d_padding_bottom20">
                          <label className="form-label sp-form-label d_font_size15">
                            <b>BIO</b>
                          </label>
                          <div className="d_border_blue d_bg_light_blue d_height_150">
                            ""
                          </div>
                        </div>
                        <div className="col-12 d_padding_bottom20">
                          <label className="form-label sp-form-label d_font_size15">
                            <b> SOCIAL MEDIA INFORMATION</b>
                          </label>
                          <div className="d_border_blue d_bg_light_blue d_height_150">
                            <div className="d-flex">
                              <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                Linked in
                              </p>
                              <p className="sky-blue d_font_size14" />
                            </div>
                            <div className="d-flex">
                              <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                Facebook
                              </p>
                              <p className="sky-blue d_font_size14">
                                https://www.facebook.com
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                Twitter
                              </p>
                              <p className="sky-blue d_font_size14" />
                            </div>
                            <div className="d-flex">
                              <p className="d_width_300 d_font_size14 d_margin_bottom10">
                                Instagram
                              </p>
                              <p className="sky-blue d_font_size14">
                                https://www.instagram.com
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d_preview_fullwidth">
                  <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                    <h6>
                      <b>
                        ARE YOU WILLING TO PROMOTE THIS EVENT TO YOUR COMMUNITY?
                      </b>
                    </h6>
                    <p className="d_font_size14 ">no</p>
                  </div>
                  <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                    <h6>
                      <b>WHAT WOULD YOU LIKE US TO PROVIDE YOU?</b>
                    </h6>
                    <p className="d_font_size14 " />
                  </div>
                  <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                    <h6>
                      <b>SELECT PARTICIPATION TYPE</b>
                    </h6>
                    <p className="d_font_size14 " />
                  </div>
                  <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                    <h6>
                      <b>SPEAKER REQUESTS</b>
                    </h6>
                    <p className="d_font_size14 ">A\V Support</p>
                  </div>
                  <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                    <h6>
                      <b>SPEAKER EXPERTISE</b>
                    </h6>
                    <p className="d_font_size14 " />
                  </div>
                  <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                    <h5>
                      <b>ACTIVITY MANAGEMENT STATUS</b>
                    </h5>
                    <div className="d_table_boxshadow d_border_radius10 d_padding_10 d_margin_top20 d_margin_bottom20">
                      <div className="row">
                        <p className="d_font_size14 d_padding_10">
                          <b>Activities</b>
                        </p>
                        <div className="col-6">
                          <div className="d-flex">
                            <p className="d_width_300 d_font_size14 d_margin_bottom10">
                              Social Media Sharing Completed
                            </p>
                            <p className="sky-blue d_font_size14">
                              <b>No</b>
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="d_width_300 d_font_size14 d_margin_bottom10">
                              Website Listing Completed
                            </p>
                            <p className="sky-blue d_font_size14">
                              <b>No</b>
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="d_width_300 d_font_size14 d_margin_bottom10">
                              Orientation Attended
                            </p>
                            <p className="sky-blue d_font_size14">
                              <b>No</b>
                            </p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex">
                            <p className="d_width_300 d_font_size14 d_margin_bottom10">
                              Speaker Coach Assigned
                            </p>
                            <p className="sky-blue d_font_size14">
                              <b>No</b>
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="d_width_300 d_font_size14 d_margin_bottom10">
                              Video Promotion Completed
                            </p>
                            <p className="sky-blue d_font_size14">
                              <b>No</b>
                            </p>
                          </div>
                          <div className="d-flex">
                            <p className="d_width_300 d_font_size14 d_margin_bottom10">
                              Reception Invitation Accepted
                            </p>
                            <p className="sky-blue d_font_size14">
                              <b>No</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                    <h5>
                      <b>RECENT COMMUNICATIONS</b>
                    </h5>
                    <table className="table table-responsive d-bluetable ">
                      <thead className="d-bluetable-header ">
                        <tr>
                          <th className="d_modal_border0 d_width_700">
                            <p className="d_modal_border0">Subject</p>
                          </th>
                          <th className="d_modal_border0" />
                          <th className="d_modal_border0">
                            <p>From | Date</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="d_modal_border0 d_padding0">
                            <p>Thank You from ______________! </p>
                          </td>
                          <td className="d_modal_border0 d_padding0">
                            <p>|</p>
                          </td>
                          <td className="d_modal_border0 d_padding0">
                            <p>null | 10-Dec-202</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d_modal_border0">
                            <p>
                              Call for Speakers: Apply to speak at the upcoming
                              (insert event name) today{" "}
                            </p>
                          </td>
                          <td className="d_modal_border0">
                            <p>|</p>
                          </td>
                          <td className="d_modal_border0">
                            <p>null | 29-Oct-202</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d_modal_border0 d_padding0">
                            <p>Call for customers</p>
                          </td>
                          <td className="d_modal_border0">
                            <p>|</p>
                          </td>
                          <td className="d_modal_border0">
                            <p>null | 07-Oct-202</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d_modal_border0">
                            <p>Your Speaker Application has been received</p>
                          </td>
                          <td className="d_modal_border0">
                            <p>|</p>
                          </td>
                          <td className="d_modal_border0">
                            <p>null | 07-Oct-202</p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d_modal_border0">
                            <p>
                              (Insert event name) Speaker Application Status
                              Update
                            </p>
                          </td>
                          <td className="d_modal_border0">
                            <p>|</p>
                          </td>
                          <td className="d_modal_border0">
                            <p>null | 25-Sep-202</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                    <h5>
                      <b>NOTES</b>
                    </h5>
                    <div className="d_table_boxshadow d_border_radius10 d_padding_20 d_margin_top20 d_margin_bottom20">
                      <div className="row">
                        <div className="col-12">
                          <p className=" d_font_size14 ">
                            welcome to speakerengage
                          </p>
                          <p className="d_font_size12">
                            By Nataraj | 2023-11-02
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d_margin_top20 d_margin_bottom20 d_padding_bottom20">
                    <h5>
                      <b>Documents</b>
                    </h5>
                    <div className="d_margin_top20 d_margin_bottom20">
                      <div className="row">
                        <div className="col-12">
                          <p className=" d_font_size15 ">
                            <a>
                              <i className="bi bi-download sky-blue d_margin_right10" />
                              Flyer
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal1"
      tabIndex={-1}
      aria-labelledby="Modal1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d_yellow_border">
            <h5
              className="d_font_family text-center d_margin_auto modal-title "
              id="Modal1"
            >
              Send Request
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            <form id="eventForm" className="d_padding_top20">
              <div className="row">
                <div className="col-4">
                  <div className="input-main">
                    <label className="form-label sp-form-label">
                      Trigger : <span className="red">*</span>
                    </label>
                  </div>
                </div>
                <div className="col-8">
                  <select
                    name="Trigger"
                    id="Trigger"
                    className="form-control form-select sp-form-input spk-select"
                  >
                    <option value="" selected="">
                      Request for Missing Information
                    </option>
                    <option value="">Request for Missing Documentation</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="input-main">
                    <label className="form-label sp-form-label">
                      Select : <span className="red">*</span>
                    </label>
                  </div>
                </div>
                <div className="col-8">
                  <div className="multi-select dropdown d_width100 sp-form-input d_blue_multiselect">
                    <button
                      title="None Selected"
                      className="no-btn multi-select-btn sp-form-input sky-blue"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      None Selected{" "}
                      <span className="arrow-down">
                        <i className="bi bi-chevron-down d_black" />
                      </span>
                    </button>
                    <ul
                      className="multi-select dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="selectAll"
                        />
                        <label
                          className="form-check-label sky-blue"
                          htmlFor="selectAll"
                        >
                          Select All
                        </label>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="option1"
                        />
                        <label
                          className="form-check-label sky-blue"
                          htmlFor="option1"
                        >
                          Headshot
                        </label>
                      </li>
                      <li>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="option2"
                        />
                        <label
                          className="form-check-label sky-blue"
                          htmlFor="option2"
                        >
                          Bio
                        </label>
                      </li>
                      <li>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="option3"
                        />
                        <label
                          className="form-check-label sky-blue"
                          htmlFor="option3"
                        >
                          Quote
                        </label>
                      </li>
                      <li>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="option1"
                        />
                        <label
                          className="form-check-label sky-blue"
                          htmlFor="option1"
                        >
                          Social Media
                        </label>
                      </li>
                      <li>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="option2"
                        />
                        <label
                          className="form-check-label sky-blue"
                          htmlFor="option2"
                        >
                          Address
                        </label>
                      </li>
                      <li>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="option3"
                        />
                        <label
                          className="form-check-label sky-blue"
                          htmlFor="option3"
                        >
                          Speaker Manager
                        </label>
                      </li>
                      <li>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="option2"
                        />
                        <label
                          className="form-check-label sky-blue"
                          htmlFor="option2"
                        >
                          Presentation Table &amp; Abstract
                        </label>
                      </li>
                      <li>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="option3"
                        />
                        <label
                          className="form-check-label sky-blue"
                          htmlFor="option3"
                        >
                          Acknowledgment
                        </label>
                      </li>
                      {/* Add more options as needed */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="dgrey_border_line clearfix d_margin_bottom20">
                <button
                  type="submit"
                  className="yellow-btn d_float_right d_margin_top20 d_margin_bottom20 "
                >
                  <i className="bi bi-chat-dots d_margin_right10 d_action_icon" />
                  Request an Update
                </button>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="input-main">
                    <label className="form-label sp-form-label">
                      Copy to speaker manager
                    </label>
                    <select
                      name="Trigger"
                      id="Trigger"
                      className="form-control form-select sp-form-input spk-select"
                    >
                      <option value="" selected="">
                        No
                      </option>
                      <option value="">Yes</option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <label className="form-label sp-form-label">Schedule</label>
                  <select
                    name="Trigger"
                    id="Trigger"
                    className="form-control form-select sp-form-input spk-select"
                  >
                    <option value="" selected="">
                      Send It Right Away
                    </option>
                    <option value="">Schedule It</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="input-main">
                    <label className="form-label sp-form-label">Message</label>
                    <div className="input-main">
                      <p className="form-control sp-form-input">
                        We are missing some data for managing communications and
                        engagement. Can you please update your profile. Thank
                        you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="submit" className="submit-btn">
              <i className="bi bi-send" />
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal3"
      tabIndex={-1}
      aria-labelledby="Modal3"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d_modal_border0 d_margin_bottom20">
            <h5
              className="d_font_family text-center d_margin_auto modal-title"
              id="Modal3"
            >
              Submission Denied
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            <p className="d_font_size14 d_margin_bottom20">
              {" "}
              This feature is implemented on 20th Dec 2018, Previously sent
              email notifications are not captured properly, so please try to
              send new notifications and check it!
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal4"
      tabIndex={-1}
      aria-labelledby="Modal4"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content d_padding_10">
          <div className="modal-header d_modal_border0">
            <h5
              className="d_font_family text-center d_margin_auto modal-title"
              id="Modal4"
            >
              Thank You - Photos &amp; Videos Now Available{" "}
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            <div className="row d_margin_bottom20">
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Email Subject: </b>
                </h6>
                <p className="d_font_size14">Thank You from ______________!</p>
              </div>
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Speaker Manager Email Address: </b>
                </h6>
                <p className="d_font_size14">Not Found</p>
              </div>
            </div>
            <div className="row d_margin_bottom20">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b> Cc Email Address: </b>
                </h6>
                <p className="d_font_size14">Not Found</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b>Email Content </b>
                </h6>
                <div className="d_modal_email_template">
                  <img
                    src={webBanner3bImg}
                    className="img-responsive d_margin_bottom20"
                  />
                  <p className="d_font_size15">Hi Prajna,</p>
                  <p className="d_font_size15">
                    We're happy to share with you the first look of the photos
                    and videos for your enjoyment! Feel free to also share
                    images and video of your talk with your network as you wish.
                    Please be sure to tag us!
                  </p>
                  <p className="d_font_size15">LinkedIn &amp; Facebook:</p>
                  <p>Twitter:</p>
                  <p>hashtag(s):</p>
                  <p>
                    Take a look at all of the incredible coverage here – photos
                    (insert link), videos (insert link)
                  </p>
                  <p>Thanks again for being a part of something big!</p>
                  <p>Best regards,</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal6"
      tabIndex={-1}
      aria-labelledby="Modal6"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content d_padding_10">
          <div className="modal-header d_modal_border0">
            <h5
              className="d_font_family text-center d_margin_auto modal-title"
              id="Modal6"
            >
              News Letter Template
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            <div className="row d_margin_bottom20">
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Email Subject: </b>
                </h6>
                <p className="d_font_size14">Call for customers</p>
              </div>
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Speaker Manager Email Address: </b>
                </h6>
                <p className="d_font_size14">Not Found</p>
              </div>
            </div>
            <div className="row d_margin_bottom20">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b>Email Address: </b>
                </h6>
                <p className="d_font_size14">Not Found</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b>Email Content </b>
                </h6>
                <div className="d_modal_email_template">
                  <img
                    src={webBanner3bImg}
                    className="img-responsive d_margin_bottom20"
                  />
                  <p className="d_font_size15">Dear Prajna, </p>
                  <p className="d_font_size15">
                    Thanks,[Event/Event Producer Company] Team
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal7"
      tabIndex={-1}
      aria-labelledby="Modal7"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content d_padding_10">
          <div className="modal-header d_modal_border0">
            <h5
              className="d_font_family text-center d_margin_auto modal-title"
              id="Modal7"
            >
              Speaker Interest Application Received
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            <div className="row d_margin_bottom20">
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Email Subject: </b>
                </h6>
                <p className="d_font_size14">
                  Your Speaker Application has been received
                </p>
              </div>
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Speaker Manager Email Address: </b>
                </h6>
                <p className="d_font_size14">Not Found</p>
              </div>
            </div>
            <div className="row d_margin_bottom20">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b>Email Address: </b>
                </h6>
                <p className="d_font_size14">anweshan.developer@gmail.com</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b>Email Content </b>
                </h6>
                <div className="d_modal_email_template">
                  <img
                    src={webBanner3bImg}
                    className="img-responsive d_margin_bottom20"
                  />
                  <p className="d_font_size15">Dear Prajna, </p>
                  <p className="d_font_size15">
                    Thank you for your interest in being a speaker at this
                    year's [event name]. We have received your submitted form
                    and it will now be moved into review. Once review is
                    completed, we will contact you to let you know whether your
                    application has been approved or denied for a speaking
                    opportunity for this year's conference. Final speaker
                    selections will be made by [date].
                  </p>
                  <p>
                    Should you have any questions, please do not hesitate to
                    contact [email address]
                  </p>
                  <p>
                    Again, we appreciate your interest and support! Thanks for
                    your patience and we will get back to you as soon as
                    possible following our review!
                  </p>
                  <p>Thanks,</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal8"
      tabIndex={-1}
      aria-labelledby="Modal8"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content d_padding_10">
          <div className="modal-header d_modal_border0">
            <h5
              className="d_font_family text-center d_margin_auto modal-title"
              id="Modal8"
            >
              Submission Denied
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            <div className="row d_margin_bottom20">
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Email Subject: </b>
                </h6>
                <p className="d_font_size14">
                  (Insert event name) Speaker Application Status Update
                </p>
              </div>
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Speaker Manager Email Address: </b>
                </h6>
                <p className="d_font_size14">Not Found</p>
              </div>
            </div>
            <div className="row d_margin_bottom20">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b>Email Address: </b>
                </h6>
                <p className="d_font_size14">Not Found</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b>Email Content </b>
                </h6>
                <div className="d_modal_email_template">
                  <img
                    src={webBanner3bImg}
                    className="img-responsive d_margin_bottom20"
                  />
                  <p className="d_font_size15">Dear (person-name), </p>
                  <p className="d_font_size15">
                    Thank you for your interest in participating in this year's
                    [event name]. We regret to inform you that we are unable to
                    approve your application at this time, but we will keep your
                    details on file for any future opportunities. We would love
                    it if you would still be able to attend the event and share
                    your feedback on your experience. We invite you to use the
                    discount code [discount code] when you register to get
                    (discount amount) off of your ticket.
                  </p>
                  <p>Register here to attend: (ticket URL)</p>
                  <p>
                    Again, thank you for your submission and we look forward to
                    welcoming you as an attendee this year.
                  </p>
                  <p>Best regards,</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal5"
      tabIndex={-1}
      aria-labelledby="Modal5"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content d_padding_10">
          <div className="modal-header d_modal_border0">
            <h5
              className="d_font_family text-center d_margin_auto modal-title"
              id="Modal5"
            >
              Call for Speakers
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            <div className="row d_margin_bottom20">
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Email Subject: </b>
                </h6>
                <p className="d_font_size14">
                  Call for Speakers: Apply to speak at the upcoming (insert
                  event name) today
                </p>
              </div>
              <div className="col-6 d_margin_bottom20">
                <h6>
                  <b>Speaker Manager Email Address: </b>
                </h6>
                <p className="d_font_size14">Not Found</p>
              </div>
            </div>
            <div className="row d_margin_bottom20">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b>Email Address: </b>
                </h6>
                <p className="d_font_size14">Not Found</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d_margin_bottom20">
                <h6>
                  <b>Email Content </b>
                </h6>
                <div className="d_modal_email_template">
                  <img
                    src={webBanner3bImg}
                    className="img-responsive d_margin_bottom20"
                  />
                  <p className="d_font_size15">Dear Prajna, </p>
                  <p className="d_font_size15">
                    Welcome to the (insert event name)
                  </p>
                  <p className="d_font_size15">
                    (insert event description) will be held on (date(s)). (Event
                    description continued) covering the following areas: (Insert
                    general topic areas)
                  </p>
                  <p>
                    We are seeking leading experts in (subject matter) to
                    present at our conference!
                  </p>
                  <p>The 2019 Conference will explore:</p>
                  <ul className="d_margin_top20 d_margin_bottom20">
                    <li>Topic example</li>
                    <li>Topic example</li>
                    <li>Topic example</li>
                    <li>Topic example</li>
                    <li>Topic example</li>
                  </ul>
                  <p>
                    <b>Apply to be a speaker today! (Insert link)</b>
                  </p>
                  <p>Who is (insert event producer company name)?</p>
                  <p>(event producer company brand description)</p>
                  <p>
                    Follow us on Facebook, LinkedIn &amp; Twitter and our
                    website at (insert link)
                  </p>
                  <p>Best,</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal3"
      tabIndex={-1}
      aria-labelledby="Modal3"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="d_font_family text-center d_margin_auto modal-title"
              id="Modal3"
            >
              Submission Denied
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            This feature is implemented on 20th Dec 2018, Previously sent email
            notifications are not captured properly, so please try to send new
            notifications and check it!
          </div>
          <div className="modal-footer">
            <button type="submit" className="submit-btn">
              <i className="bi bi-send" />
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade d_modal "
      id="Modal2"
      tabIndex={-1}
      aria-labelledby="Modal2"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d_modal_border0">
            <h5 className="d_font_family modal-title" id="Modal2">
              Previous Emails List
            </h5>
            <button
              type="button"
              className="d_modal_closebtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x pink" />
            </button>
          </div>
          <div className="modal-body d_padding_bottom0">
            <table className="table table-responsive d-whitetable">
              <thead className="d-whitetable-header">
                <tr>
                  <th>
                    <p>Email Subject</p>
                  </th>
                  <th>
                    <p>Sent By</p>
                  </th>
                  <th>
                    <p>Date</p>
                  </th>
                  <th>
                    <p>Action</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <a
                        className="d_cursor_pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal3"
                      >
                        Submission Denied
                      </a>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>24-Feb-2022</p>
                  </td>
                  <td>
                    <p className="d_green_btn">Sent</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <a
                        className="d_cursor_pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal4"
                      >
                        Thank You - Photos &amp; Videos Now Available
                      </a>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>24-Feb-2022</p>
                  </td>
                  <td>
                    <p className="d_green_btn">Opened</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <a
                        className="d_cursor_pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal5"
                      >
                        Call for Speakers
                      </a>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>24-Feb-2022</p>
                  </td>
                  <td>
                    <p className="d_green_btn">Sent</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <a
                        className="d_cursor_pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal6"
                      >
                        News Letter Template
                      </a>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>24-Feb-2022</p>
                  </td>
                  <td>
                    <p className="d_green_btn">Sent</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <a
                        className="d_cursor_pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal7"
                      >
                        Speaker Interest Application Received
                      </a>
                    </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p>24-Feb-2022</p>
                  </td>
                  <td>
                    <p className="d_green_btn">Sent</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <a
                        className="d_cursor_pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#Modal8"
                      >
                        Submission Denied
                      </a>
                    </p>
                  </td>
                  <td>
                    <p />
                  </td>
                  <td>
                    <p>24-Feb-2022</p>
                  </td>
                  <td>
                    <p className="d_green_btn">Sent</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Request missing information</p>
                  </td>
                  <td>
                    <p />
                  </td>
                  <td>
                    <p>24-Feb-2022</p>
                  </td>
                  <td>
                    <p className="d_green_btn">Sent</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-2">
      <Footer1/>
    </div>
  </div>
</div>

    </>
  )
}

export default NewSpeakers
