import React, { useState } from 'react'
import Footer1 from '../Navigations/footer/footer1'
import speakerEngageLogoImg from "../../Assets/images/Speaker Engage Logo-svg.svg"
import { EmailCheck } from '../Utils/Validation'

const ForgotPassword = () =>{
  const [email_address, setEmailAddress] = useState("");
  const [email_validation, setEmailValidation] = useState("");

  const handleForgotPassword = () =>{
    const emailValidation = EmailCheck(email_address);
    if (emailValidation.err_display) {
      setEmailValidation(emailValidation.err_display);
    } else {
      setEmailValidation("");
    }
  };
  return (
    <>
  <div className="main d-flex align-items-center justify-content-center bg-gray signinmain main-height">
    <div className="container">
      <div className="row wrapper2 text-center">
        <p>
          <a href="https://www.speakerengage.com/">
            <img
              src={speakerEngageLogoImg}
              className="whitelogo-login"
            />
          </a>
        </p>
      </div>
      <div className="row wrapper1">
        <div className="col signinleft border-radius-25">
          <div className="signinleftinner w-300">
            <p className="signin-text">Forgot password?</p>
            <p className="signin-subtext">
              We just need your registered email address to send you reset
              instructions
            </p>
            <div className="email-login">
                <div className="mb-3">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="you@example.com"
                    className="form-control signin-input"
                    onChange={(e) => [
                      setEmailAddress(e.target.value),
                      setEmailValidation(""),
                    ]}
                  />
                  {
                    email_validation ? <p className="error-msg" id="error-msg-email">{email_validation}</p>:null
                  }
                </div>
                <div className="mb-3">
                  <p className="error-msg" id="error-msg" />
                </div>
                <button type="submit" className="signin-submit" id="loginBtn" onClick={handleForgotPassword}>
                  Reset Password
                </button>
                <p className="text-center pt-2">
                  <a
                    href="{{ url_for('signin') }}"
                    className="signinlink-textinner"
                  >
                    Already have an account? Log In
                  </a>
                </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      {/* <div class="row wrapper1">
              <div class="col signinleft border-radius-25">
                <div class="signinleftinner w-300">
                    <p class="signin-subtext green-text">Password reset link has been sent to your email address!</p>
                    <div class="email-login"> 
                            <button type="submit" class="signin-submit">Click here to login</button>
                    </div>
                </div>
              </div>
            </div> */}
    </div>
  </div>
  <Footer1/>
</>

  )
}

export default ForgotPassword