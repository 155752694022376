import React from "react";
import Footer1 from "../Navigations/footer/footer1";
import Header1 from "../Navigations/header/header1";
import Sidebar2 from "../Navigations/sidebar/sidebar2";
import speakerEngageLogo from '../../Assets/images/Speaker-Engage-Logo-svg.svg';

function CreatePassword() {
  return (
    <>
      <div className="main d-flex align-items-center justify-content-center bg-gray signinmain main-height">
        <div className="container">
          <div className="row wrapper2 text-center">
            <p>
              <a href="https://www.speakerengage.com/">
                <img
                  src={speakerEngageLogo}
                  className="whitelogo-login"
                />
              </a>
            </p>
          </div>
          <div className="row wrapper1">
            <div className="col signinleft border-radius-25">
              <div className="signinleftinner w-350">
                <p className="signin-text">Your email has been verified!</p>
                <p className="signin-subtext">
                  Set up your password to get started
                </p>
                <div className="email-login pt-3">
                  <p className="error-msg" id="error-msg" />
                  <p className="success-msg" id="success-msg" />
                  <form method="post" id="passwordForm">
                    <div className="mb-3">
                      <div className="password-eye">
                        <input
                          type="password"
                          name="password1"
                          id="password1"
                          className="form-control signin-input"
                          placeholder="Create password"
                        />
                        <i
                          className="password-icon"
                          onclick="togglePasswordVisibility('password1')"
                        >
                          <span className="bi bi-eye-slash" />
                        </i>
                      </div>
                      <p className="error-msg" id="error-msg-pass1" />
                      <p className="help-text pt-2">
                        Minimum 8 characters (alpha numeric+ special characters)
                      </p>
                    </div>
                    <div className="mb-3 password-eye">
                      <input
                        type="password"
                        name="password2"
                        id="password2"
                        className="form-control signin-input"
                        placeholder="Confirm password"
                      />
                      <i
                        className="password-icon"
                        onclick="togglePasswordVisibility('password2')"
                      >
                        <span className="bi bi-eye-slash" />
                      </i>
                    </div>
                    <p className="error-msg" id="error-msg-pass2" />
                    <div className="form-check pb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue=""
                      />
                      <p className="agree">
                        I agree to{" "}
                        <a href="../terms-of-use" target="_blank">
                          Speaker Engage Terms &amp; Conditions
                        </a>{" "}
                        and acknowledge that Speaker Engage will process my
                        personal data in compliance with the{" "}
                        <a href="../privacy-policy" target="_blank">
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                    <input
                      type="hidden"
                      name="token"
                      defaultValue="{{ token }}"
                    />
                    <button type="submit" className="signin-submit">
                      Continue
                    </button>
                    {/* <a type="submit" class="signin-submit text-center" href="/set-tenant-url">Continue</a> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  );
}

export default CreatePassword;
