import React from "react";
import Header1 from "../../Navigations/header/header1";
import Sidebar2 from "../../Navigations/sidebar/sidebar2";
import Footer1 from "../../Navigations/footer/footer1";

function EditSpeaker() {
  return (
    <>
        <div className="body-main bg-grey">
          <Header1 />
          <div className="body-page">
            <div className="d-flex">
              <div className="sidebar-main">
                <Sidebar2 />
              </div>
              <div className="content-main">
                <div className="min-height-pagecontent">
                  <div className="full-page-form">
                    <div className="spk-1 dgrey_border_line d_padding_bottom20">
                      <p className="d_font_size text-center d_margin_auto">
                        Speaker Details
                      </p>
                      <div className="justify-content-right d-flex">
                        <button
                          type="submit"
                          className="submit-btn margin-right-10"
                        >
                          SAVE
                        </button>
                        <button className="submit-btn pink-btn">EXIT</button>
                      </div>
                    </div>
                    <form id="speakerForm" className="d_padding_top20">
                      <div className="row">
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Speaker Name <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              name="speaker_name"
                              id="speaker_name"
                              className="form-control sp-form-input"
                              placeholder="Speaker Name"
                              defaultValue="Prajna Shetty"
                            />
                            <p className="error-msg" id="error-msg-name" />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Status <span className="red">*</span>{" "}
                            </label>
                            <select
                              name="Status"
                              id="Status"
                              className="form-control form-select sp-form-input spk-select"
                            >
                              <option value="">Change the Status</option>
                              <option value="" selected="">
                                Approved
                              </option>
                              <option value="">In Contract</option>
                              <option value="">In-Review</option>
                              <option value="">New</option>
                              <option value="">Rejected</option>
                              <option value="">Research</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Speaker Email Address{" "}
                              <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              className="form-control sp-form-input"
                              placeholder="Speaker Email Address"
                              defaultValue="prajna.s@iverbinde.co"
                            />
                            <p className="error-msg" id="error-msg-enddate" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Phone Number <span className="red">*</span>
                            </label>
                            <input
                              type="tel"
                              name="phone"
                              id="phone_number"
                              className="form-control sp-form-input"
                              placeholder="Phone Number"
                            />
                            <p className="error-msg" id="error-msg-elocation" />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Company Name <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              name="company_name"
                              id="company_name"
                              className="form-control sp-form-input"
                              placeholder="Company Name"
                            />
                            <p className="error-msg" id="error-msg-elocation" />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Job Title <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              name="job_title"
                              id="job_title"
                              className="form-control sp-form-input"
                              placeholder="Job Title"
                            />
                            <p className="error-msg" id="error-msg-elocation" />
                            <p className="error-msg" id="error-msg-etimezone" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Address Line 1 <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              name="address_line1"
                              id="address_line1"
                              className="form-control sp-form-input"
                              placeholder="Address Line 1"
                            />
                            <p className="error-msg" id="error-msg-elocation" />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Address Line 2 <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              name="address_line2"
                              id="address_line2"
                              className="form-control sp-form-input"
                              placeholder="Address Line 2"
                            />
                            <p className="error-msg" id="error-msg-elocation" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Country <span className="red">*</span>{" "}
                            </label>
                            <select
                              name="Country"
                              id="Country"
                              className="form-control form-select sp-form-input spk-select"
                            >
                              <option value="">Select Country</option>
                              <option value="">India</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              State <span className="red">*</span>{" "}
                            </label>
                            <select
                              name="State"
                              id="State"
                              className="form-control form-select sp-form-input spk-select"
                            >
                              <option value="">Select State</option>
                              <option value="">Karnataka</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              City <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="City"
                              id="City"
                              className="form-control sp-form-input"
                              placeholder="City"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Zip Code <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="Zip_code"
                              id="Zip_code"
                              className="form-control sp-form-input"
                              placeholder="Zip code"
                            />
                          </div>
                        </div>
                      </div>
                      <p className="form-title text-left mb-4 mt-4 d_margin_bottom20">
                        Speaker Manager Details
                      </p>
                      <div className="row">
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Name <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="speaker_manager_name"
                              id="speaker_manager_name"
                              className="form-control sp-form-input"
                              placeholder="Speaker Manager Name"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Email Address <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="email"
                              name="speaker_manager_email"
                              id="speaker_manager_email"
                              className="form-control sp-form-input"
                              placeholder="Speaker Manager Email"
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Phone Number (Mobile){" "}
                              <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="tel"
                              name="speaker_manager_phone"
                              id="speaker_manager_phone"
                              className="form-control sp-form-input"
                              placeholder="Speaker Manager Phone"
                            />
                          </div>
                        </div>
                      </div>
                      <p className="form-title text-left mb-4 mt-4">
                        Social Media Details
                      </p>
                      <div className="row">
                        <div className="col-6">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Twitter Handle <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="twitter_handle"
                              id="twitter_handle"
                              className="form-control sp-form-input"
                              placeholder="https://www.twitter.com"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              LinkedIn URL{" "}
                              <small>
                                (Provide access to linkedin url for tagging the
                                post)
                              </small>{" "}
                              <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="Linkedin_url"
                              id="Linkedin_url"
                              className="form-control sp-form-input"
                              placeholder="https://www.linkedin.com"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Instagram URL <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="instagram_url"
                              id="instagram_url"
                              className="form-control sp-form-input"
                              placeholder="https://www.instagram.com"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Facebook URL <span className="red">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="Facebook_url"
                              id="Facebook_url"
                              className="form-control sp-form-input"
                              placeholder="https://www.facebook.com"
                            />
                          </div>
                        </div>
                      </div>
                      <p className="form-title text-left mb-4 mt-4">
                        Select Participation Type
                      </p>
                      <div className="row d_margin_bottom20">
                        <div className="col-3">
                          <label className="check-container">
                            Keynote
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Power Panel
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Workshop
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Breakouts
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                      </div>
                      <div className="row d_margin_bottom20">
                        <div className="col-3">
                          <label className="check-container">
                            Power Talks
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Immersive Experience
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Executive Roundtable
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Other
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                      </div>
                      <div className="row d_margin_bottom20">
                        <div className="col-3">
                          <label className="check-container">
                            Moderator
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                      </div>
                      <p className="form-title text-left mb-4 mt-4">
                        Speaker Requests
                      </p>
                      <div className="row d_margin_bottom20">
                        <div className="col-3">
                          <label className="check-container">
                            Airfare
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            A\V Support
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Events Tickets
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Hotel
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                      </div>
                      <div className="row d_margin_bottom20">
                        <div className="col-3">
                          <label className="check-container">
                            Speaker Fees
                            <input type="checkbox" defaultChecked="checked" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Stipend
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            Transportation
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                        <div className="col-3">
                          <label className="check-container">
                            VIP Amenities
                            <input type="checkbox" />
                            <span className="check-checkmark" />
                          </label>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-9 d_margin_top20">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Speaker Expertise <span className="red">*</span>{" "}
                            </label>
                            <textarea
                              className="form-control sp-form-input"
                              placeholder="Speaker Expertise"
                              rows={8}
                              name="speaker_expertise"
                              id="speaker_expertise"
                              defaultValue={"                  "}
                            />
                          </div>
                        </div>
                        <div className="col-3 d_margin_top20">
                          <div className="input-main">
                            <div className="upload-label">
                              <label className="form-label sp-form-label">
                                Upload Headshot <span className="red">*</span>
                              </label>
                            </div>
                            <div className="image_area sp-form-uplopad">
                              <label
                                htmlFor="upload_image"
                                className="form-upload"
                              >
                                <img
                                  src="{{ url_for('static', filename='images/cloud-computing.png') }}"
                                  id="uploaded_image"
                                  className="img-responsive img-circle"
                                />
                                <input
                                  type="file"
                                  name="image"
                                  className="image"
                                  id="upload_image"
                                  style={{ display: "none" }}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Your Quote{" "}
                            </label>
                            <textarea
                              className="form-control sp-form-input"
                              placeholder="Your Quote"
                              rows={5}
                              name="your_quote"
                              id="your_quote"
                              defaultValue={"            "}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Short Bio
                            </label>
                            <textarea
                              className="form-control sp-form-input"
                              placeholder="Short Bio"
                              rows={5}
                              name="short_bio"
                              id="short_bio"
                              defaultValue={"            "}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-3">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Acknowledgements? <span className="red">*</span>
                            </label>
                          </div>
                        </div>
                        <div className="d-flex col-9">
                          <label className="radio-container">
                            {" "}
                            yes
                            <input
                              type="radio"
                              defaultChecked="checked"
                              name="radio"
                            />
                            <span className="checkmark" />
                          </label>
                          <label className="radio-container">
                            {" "}
                            No
                            <input
                              type="radio"
                              defaultChecked="checked"
                              name="radio"
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                      <p className="form-title text-left mb-4 mt-4">
                        {" "}
                        Activity Engagement Status
                      </p>
                      <h4 className="form-title d_font_size15">Activities</h4>
                      <div className="row ">
                        <div className="col-6 d-flex">
                          <div className="col-6">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Social Media Sharing Completed{" "}
                              </label>
                            </div>
                          </div>
                          <div className="d-flex col-6">
                            <label className="radio-container">
                              {" "}
                              yes
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="radio-container">
                              {" "}
                              No
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 d-flex">
                          <div className="col-6">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Speaker Coach Assigned
                              </label>
                            </div>
                          </div>
                          <div className="d-flex col-6">
                            <label className="radio-container">
                              {" "}
                              yes
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="radio-container">
                              {" "}
                              No
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-6 d-flex">
                          <div className="col-6">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Website Listing Completed{" "}
                              </label>
                            </div>
                          </div>
                          <div className="d-flex col-6">
                            <label className="radio-container">
                              {" "}
                              yes
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="radio-container">
                              {" "}
                              No
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 d-flex">
                          <div className="col-6">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Video Promotion Completed
                              </label>
                            </div>
                          </div>
                          <div className="d-flex col-6">
                            <label className="radio-container">
                              {" "}
                              yes
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="radio-container">
                              {" "}
                              No
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-6 d-flex">
                          <div className="col-6">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Orientation Attended
                              </label>
                            </div>
                          </div>
                          <div className="d-flex col-6">
                            <label className="radio-container">
                              {" "}
                              yes
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="radio-container">
                              {" "}
                              No
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 d-flex">
                          <div className="col-6">
                            <div className="input-main">
                              <label className="form-label sp-form-label">
                                Reception Invitation Accepted
                              </label>
                            </div>
                          </div>
                          <div className="d-flex col-6">
                            <label className="radio-container">
                              {" "}
                              yes
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                            <label className="radio-container">
                              {" "}
                              No
                              <input
                                type="radio"
                                defaultChecked="checked"
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row d_margin_top20">
                        <div className="col-10 ">
                          <p className="form-title text-left mb-4 mt-4">
                            Presentation &amp; Other Documents
                          </p>
                        </div>
                        <div className="col-2 text-right mb-4 mt-4">
                          <a
                            id="upload_files"
                            className="d_upload_files pull-right"
                            data-bs-toggle="modal"
                            data-bs-target="#Modal1"
                          >
                            <i className="bi bi-cloud-upload-fill  d_dark_blue_text" />
                            <span className="font-sem-bold  d_dark_blue_text">
                              Upload Files
                            </span>
                          </a>
                        </div>
                      </div>
                      {/* Modal */}
                      <div
                        className="modal fade d_modal "
                        id="Modal1"
                        tabIndex={-1}
                        aria-labelledby="Modal1"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-md modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="d_font_family text-center d_margin_auto modal-title"
                                id="Modal1"
                              >
                                Upload Files
                              </h5>
                              <button
                                type="button"
                                className="d_modal_closebtn"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="bi bi-x pink" />
                              </button>
                            </div>
                            <div className="modal-body d_padding_bottom0">
                              <div className="row">
                                <div className="col-6">
                                  <div className="input-main">
                                    <label className="form-label sp-form-label">
                                      Name <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="file_name"
                                      id="file_name"
                                      className="form-control sp-form-input"
                                      placeholder="File Name"
                                    />
                                    <p
                                      className="error-msg"
                                      id="error-msg-name"
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="input-main">
                                    <label className="form-label sp-form-label">
                                      URL <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="file_url"
                                      id="file_url"
                                      className="form-control sp-form-input"
                                      placeholder="URL"
                                    />
                                    <p
                                      className="error-msg"
                                      id="error-msg-name"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-5">
                                  <div className="input-main">
                                    <label className="form-label sp-form-label">
                                      Publish Externally{" "}
                                    </label>
                                  </div>
                                </div>
                                <div className="d-flex col-7">
                                  <label className="radio-container">
                                    {" "}
                                    yes
                                    <input
                                      type="radio"
                                      defaultChecked="checked"
                                      name="radio"
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <label className="radio-container">
                                    {" "}
                                    No
                                    <input
                                      type="radio"
                                      defaultChecked="checked"
                                      name="radio"
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="input-main d_margin_bottom10 ">
                                    <div className="image_area sp-form-uplopad d_modal_upload_image">
                                      <label
                                        htmlFor="upload_image"
                                        className="form-upload"
                                      >
                                        <img
                                          src="/static/images/cloud-computing.png"
                                          id="uploaded_image"
                                          className="img-responsive img-circle"
                                        />
                                        <p className="">
                                          Drop files here to upload or Browse
                                          Images
                                        </p>
                                        <input
                                          type="file"
                                          name="image"
                                          className="image"
                                          id="upload_image"
                                          style={{ display: "none" }}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <span className="red progressec1-text2">
                                  Note: Upload one file at a time
                                </span>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="submit" className="submit-btn">
                                SAVE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <table className="table table-responsive d-bluetable">
                      <thead className="d-bluetable-header">
                        <tr>
                          <th>
                            <p>Name</p>
                          </th>
                          <th>
                            <p>File Name</p>
                          </th>
                          <th>
                            <p>Url</p>
                          </th>
                          <th>
                            <p>Publish Externally</p>
                          </th>
                          <th>
                            <p>File Type</p>
                          </th>
                          <th>
                            <p>Last Updated</p>
                          </th>
                          <th>
                            <p>Actions</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <p>Divya RB</p>
                          </td>
                          <td>
                            <p>1707385089.png </p>
                          </td>
                          <td>
                            <p>google.com</p>
                          </td>
                          <td>
                            <p>yes</p>
                          </td>
                          <td>
                            <p>Docs</p>
                          </td>
                          <td>
                            <p>08-Feb-2024 09:08:50</p>
                          </td>
                          <td>
                            <a>
                              <i className="bi bi-download d_action_icon violet margin-right-10" />
                            </a>
                            <a
                              className="d_cursor_pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#Modal3"
                            >
                              <i className="bi bi-pencil-square sky-blue d_action_icon margin-right-10" />
                            </a>{" "}
                            <a>
                              <i className="bi bi-trash d_action_icon pink" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="form-title text-left mb-4 mt-4">Website</p>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Website Name <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="website_name"
                            id="website_name"
                            className="form-control sp-form-input"
                            placeholder="Website Name"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Website URL <span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="website_url"
                            id="website_url"
                            className="form-control sp-form-input"
                            placeholder="Website URL"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-10">
                        <p className="form-title text-left mb-4 mt-4">Notes</p>
                      </div>
                      <div className="col-2 text-right mb-4 mt-4">
                        <a
                          id="upload_files"
                          className="d_upload_files pull-right"
                          data-bs-toggle="modal"
                          data-bs-target="#Modal2"
                        >
                          <i className="bi bi-plus  d_dark_blue_text" />
                          <span className="font-sem-bold  d_dark_blue_text">
                            Edit Note
                          </span>
                        </a>
                      </div>
                    </div>
                    <div
                      className="modal fade d_modal "
                      id="Modal2"
                      tabIndex={-1}
                      aria-labelledby="Modal2"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="d_font_family text-center d_margin_auto modal-title"
                              id="Modal2"
                            >
                              Edit Note
                            </h5>
                            <button
                              type="button"
                              className="d_modal_closebtn"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i className="bi bi-x pink" />
                            </button>
                          </div>
                          <div className="modal-body d_padding_bottom0">
                            <form id="eventForm" className="d_padding_top20">
                              <div className="row">
                                <div className="col-12">
                                  <div className="input-main">
                                    <label className="form-label sp-form-label">
                                      WRITE YOUR NOTE{" "}
                                    </label>
                                    <textarea
                                      className="form-control sp-form-input"
                                      placeholder=""
                                      rows={5}
                                      name="add_note"
                                      id="add_note"
                                      defaultValue={"            "}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button type="submit" className="submit-btn">
                              SAVE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <table className="table table-responsive d-bluetable ">
                      <thead className="d-bluetable-header">
                        <tr>
                          <th>
                            <p>Note</p>
                          </th>
                          <th>
                            <p>Added by</p>
                          </th>
                          <th>
                            <p>Date</p>
                          </th>
                          <th>
                            <p>Date</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <p>test </p>
                          </td>
                          <td>
                            <p>Nataraj</p>
                          </td>
                          <td>
                            <p>08-Feb-202</p>
                          </td>
                          <td>
                            <a
                              className="d_cursor_pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#Modal4"
                            >
                              <i className="bi bi-pencil-square d_action_icon sky-blue " />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      className="modal fade d_modal "
                      id="Modal3"
                      tabIndex={-1}
                      aria-labelledby="Modal3"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="d_font_family text-center d_margin_auto modal-title"
                              id="Modal3"
                            >
                              Upload Files
                            </h5>
                            <button
                              type="button"
                              className="d_modal_closebtn"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i className="bi bi-x pink" />
                            </button>
                          </div>
                          <div className="modal-body d_padding_bottom0">
                            <form id="eventForm" className="d_padding_top20">
                              <div className="row">
                                <div className="col-6">
                                  <div className="input-main">
                                    <label className="form-label sp-form-label">
                                      Name <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="file_name"
                                      id="file_name"
                                      className="form-control sp-form-input"
                                      placeholder="File Name"
                                    />
                                    <p
                                      className="error-msg"
                                      id="error-msg-name"
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="input-main">
                                    <label className="form-label sp-form-label">
                                      URL <span className="red">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="file_url"
                                      id="file_url"
                                      className="form-control sp-form-input"
                                      placeholder="URL"
                                    />
                                    <p
                                      className="error-msg"
                                      id="error-msg-name"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-5">
                                  <div className="input-main">
                                    <label className="form-label sp-form-label">
                                      Publish Externally{" "}
                                    </label>
                                  </div>
                                </div>
                                <div className="d-flex col-7">
                                  <label className="radio-container">
                                    {" "}
                                    yes
                                    <input
                                      type="radio"
                                      defaultChecked="checked"
                                      name="radio"
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <label className="radio-container">
                                    {" "}
                                    No
                                    <input
                                      type="radio"
                                      defaultChecked="checked"
                                      name="radio"
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="input-main d_margin_bottom10 ">
                                    <div className="image_area sp-form-uplopad d_modal_upload_image">
                                      <label
                                        htmlFor="upload_image"
                                        className="form-upload"
                                      >
                                        <img
                                          src="/static/images/cloud-computing.png"
                                          id="uploaded_image"
                                          className="img-responsive img-circle"
                                        />
                                        <p className="">
                                          Drop files here to upload or Browse
                                          Images
                                        </p>
                                        <input
                                          type="file"
                                          name="image"
                                          className="image"
                                          id="upload_image"
                                          style={{ display: "none" }}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <span className="red progressec1-text2">
                                  Note: Upload one file at a time
                                </span>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button type="submit" className="submit-btn">
                              SAVE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal fade d_modal "
                      id="Modal4"
                      tabIndex={-1}
                      aria-labelledby="Modal2"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="d_font_family text-center d_margin_auto modal-title"
                              id="Modal4"
                            >
                              Edit Note
                            </h5>
                            <button
                              type="button"
                              className="d_modal_closebtn"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i className="bi bi-x pink" />
                            </button>
                          </div>
                          <div className="modal-body d_padding_bottom0">
                            <form id="eventForm" className="d_padding_top20">
                              <div className="row">
                                <div className="col-12">
                                  <div className="input-main">
                                    <label className="form-label sp-form-label">
                                      WRITE YOUR NOTE{" "}
                                    </label>
                                    <textarea
                                      className="form-control sp-form-input"
                                      placeholder=""
                                      rows={5}
                                      name="add_note"
                                      id="add_note"
                                      value="test"
                                      defaultValue={"            "}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button type="submit" className="submit-btn">
                              SAVE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-right d_margin_top40">
                      <button type="submit" className="submit-btn">
                        SAVE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-2">
          <Footer1 />
        </div>
    </>
  );
}

export default EditSpeaker;
