import React from 'react'
import Footer1 from '../Navigations/footer/footer1';
import speakerEngageLogoImg from "../../Assets/images/Speaker Engage Logo-svg.svg"

function ForgotPasswordEmailVerfy() {
  return (
   <>
   <>
  <div className="main d-flex align-items-center justify-content-center bg-gray signinmain main-height">
    <div className="container">
      <div className="row wrapper2 text-center">
        <p>
          <a href="https://www.speakerengage.com/">
            <img
              src={speakerEngageLogoImg}
              className="whitelogo-login"
            />
          </a>
        </p>
      </div>
      <div className="row wrapper1">
        <div className="col signinleft border-radius-25">
          <div className="signinleftinner w-300">
            <p className="signin-text">
              Reset password link sent to your Email
            </p>
            {/* <p class="signin-subtext">We sent a verification email to</p> */}
            <p className="text-center"></p>
            <p className="signinlink-textinner">
              {"{"}
              {"{"} email if email else '' {"}"}
              {"}"}
            </p>
            <p />
            <p className="signin-subtext pt-3">
              Click the link in the email to reset the password!
            </p>
            <hr className="hr-line" />
            <p className="signin-subtext">
              If you didn’t get the email, please check your Spam folder.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
 <Footer1/>
</>

   </>
  )
}

export default ForgotPasswordEmailVerfy
