export const FirstNameCheck = (event) => {
    let firstNameCheck = {};
    if (!event) {
      return (firstNameCheck = {
        input: "empty",
        err_display: "User First Name is Required!.",
      });
    }
    return (firstNameCheck = {
      validition: true,
    });
  
  }
  
  export const LastNameCheck = (event) => {
    let LastNameCheck = {};
    if (!event) {
      return (LastNameCheck = {
        input: "empty",
        err_display: "Last Name is required!",
      });
    }
    return (LastNameCheck = {
      validition: true,
    });
  }

  export const LoginPasswordCheck = (event) => {
    let Password = {};
    if (!event) {
      return (Password = {
        input: "empty",
        err_display: "Password is required!",
      });
    }
    return (Password = {
      validition: true,
    });
  }
  
  export const MobileNumberCheck = (value) => {
    let mobileNumberCheck = {};
    const mobileCond = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!value) {
      return (mobileNumberCheck = {
        input: "empty",
        err_display: "Mobile number is required!",
      });
    } else if (mobileCond.test(value) === false) {
      return (mobileNumberCheck = {
        input: "invalid",
        err_display: "Enter a valid Mobile number!",
      });
    }
    return (mobileNumberCheck = {
      validition: true,
    });
  };
  
  
  export const EmailCheck = (value) => {
    let emailCheck = {};
    const emailCond =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
    if (!value) {
      return (emailCheck = {
        input: "empty",
        err_display: "Email is required!",
      });
    } else if (emailCond.test(value) === false) {
      return (emailCheck = {
        input: "invalid",
        err_display: "Enter a valid email address!",
      });
    }
    return (emailCheck = {
      validition: true,
    });
  };
  
  
  export const PasswordCheck = (value) => {
    let createPasswordCheck = {};
    // const passwordCond = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    const passwordCond =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;
  
    if (!value) {
      return (createPasswordCheck = {
        input: "empty",
        err_display: "Create a Password!",
      });
    } else if (value.length < 8) {
      return (createPasswordCheck = {
        input: "lesscharacters",
        err_display:
          "Should contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character!",
      });
    } else if (passwordCond.test(value) === false) {
      return (createPasswordCheck = {
        input: "invalid",
        err_display:
          "Should contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character!",
      });
    }
    return (createPasswordCheck = {
      validition: true,
    });
  };
  
  export const ConfirmPasswordCheck = (value1, value2) => {
    let confirmPasswordCheck = {};
    if (!value2) {
      return (confirmPasswordCheck = {
        input: "empty",
        err_display: "Confirm the Password!",
      });
    } else if (value1 != value2) {
      return (confirmPasswordCheck = {
        input: "deosnotmatch",
        err_display: "Password did not match!",
      });
    }
    return (confirmPasswordCheck = {
      validition: true,
    });
  };

  export const CaptchaCheck = (event) => {
    let Captcha = {};
    if (!event) {
      return (Captcha = {
        input: "empty",
        err_display: "Captcha is required!",
      });
    }
    return (Captcha = {
      validition: true,
    });
  }