import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./Pages/Publicpages/SignIn";
import "../src/Assets/css/common.css";
import '../src/Assets/css/common1.css';
import DashboardEvent from "./Pages/Privatepages/dashboardEvent";
import AllSpeakers from "./Pages/Privatepages/Speakers/allSpeakers";
import SignUp from "./Pages/Publicpages/SignUp";
import CreatePassword from "./Pages/Publicpages/CreatePassword";
import EmailVerfiy from "./Pages/Publicpages/EmailVerfiy";
import CreateOrganizationName from "./Pages/Publicpages/CreateOrganizationName";
import OrganizationSuccess from "./Pages/Publicpages/OrganizationSuccess";
import SpeakerEngageLandingpage from "./Pages/Privatepages/GeneralPages/SpeakerEngageLandingpage";
import CreateEvent from "./Pages/Privatepages/Events/CreateEvent";
import AddSpeaker from "./Pages/Privatepages/Speakers/AddSpeaker";
import ExternalApplyToSpeaker from "./Pages/Privatepages/GeneralPages/ExternalApplyToSpeaker";
import ApprovedSpeakersList from "./Pages/Privatepages/Speakers/ApprovedSpeakersList";
import ApprovedSpeakerPage from "./Pages/Privatepages/Speakers/ApprovedSpeakerPage";
import SpeakerBulkNotify from "./Pages/Privatepages/Speakers/SpeakerBulkNotify";
import CopyEmailTemplate from "./Pages/Privatepages/ManageEmailTemplates/CopyEmailTemplate";
import EditEmailTemplate from "./Pages/Privatepages/ManageEmailTemplates/EditEmailTemplate";
import EditSpeakerApplication from "./Pages/Privatepages/Speakers/EditSpeakerApplication";
import EditSpeakerType from "./Pages/Privatepages/Speakers/EditSpeakerType";
import EditSpeaker from "./Pages/Privatepages/Speakers/EditSpeaker";
import StatusSettings from "./Pages/Privatepages/Speakers/StatusSettings";
import ListOfEmailTemplates from "./Pages/Privatepages/ManageEmailTemplates/ListOfEmailTemplates";
import ForgotPasswordEmailVerfy from "./Pages/Publicpages/ForgotPasswordEmailVerfy";
import ForgotPassword from "./Pages/Publicpages/ForgotPassword";
import ImportSpeaker from "./Pages/Privatepages/Speakers/ImportSpeaker";
import NewEmailTemplate from "./Pages/Privatepages/ManageEmailTemplates/NewEmailTemplate";
import NewPassword from "./Pages/Publicpages/NewPassword";
import NewSpeakerType from "./Pages/Privatepages/Speakers/NewSpeakerType";
import NewSpeakers from "./Pages/Privatepages/Speakers/NewSpeakers";
import NewSpeakerStatus from "./Pages/Privatepages/Speakers/NewSpeakerStatus";
import NotifySpeaker from "./Pages/Privatepages/Speakers/NotifySpeaker";
import SearchFromSpeakerDatabaseNew from "./Pages/Privatepages/Speakers/SearchFromSpeakerDatabaseNew";
import SpeakerType from "./Pages/Privatepages/Speakers/SpeakerType";
import ViewSpeakers from "./Pages/Privatepages/Speakers/ViewSpeakers";
import EditEvent from "./Pages/Privatepages/Events/EditEvent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinkExpired from "./Pages/Publicpages/LinkExpired";


function App() {
  return (
    <>
    <div className="App">
    <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={1}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/create-password" element={<CreatePassword />} />
          <Route path="/set-tenant-organization-name" element={<CreateOrganizationName />} />
          <Route path="/success-tenant-organizationname" element={<OrganizationSuccess />} />
          <Route path="/speaker-engage-landing" element={<SpeakerEngageLandingpage />} />
          <Route path="/email-verify" element={<EmailVerfiy />} />
          <Route path="/forgot-password-verify-email" element={<ForgotPasswordEmailVerfy />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/dashboard-event" element={<DashboardEvent />} />
          <Route path="/all-speakers" element={<AllSpeakers />} />         
          <Route path="/add-speaker" element={<AddSpeaker />} />
          <Route path="/apply-to-speaker" element={<ExternalApplyToSpeaker />} />
          <Route path="/approved-speakers-list" element={<ApprovedSpeakersList />} />
          <Route path="/approved-speaker" element={<ApprovedSpeakerPage />} />
          <Route path="/speaker-bulk-notify" element={<SpeakerBulkNotify />} />
          <Route path="/copy-email-template" element={<CopyEmailTemplate />} />
          <Route path="/edit-email-template" element={<EditEmailTemplate />} />
          <Route path="/edit-speaker-application" element={<EditSpeakerApplication />} />
          <Route path="/edit-speaker-types" element={<EditSpeakerType />} />
          <Route path="/edit-speaker" element={<EditSpeaker />} />
          <Route path="/speaker-status-settings" element={<StatusSettings />} />
          <Route path="/email-templates" element={<ListOfEmailTemplates />} />
          <Route path="/import-speakers" element={<ImportSpeaker />} />
          <Route path="/new-email-template" element={<NewEmailTemplate />} />
          <Route path="/new-speaker-type" element={<NewSpeakerType />} />
          <Route path="/new-speakers" element={<NewSpeakers />} />
          <Route path="/new-speaker-status" element={<NewSpeakerStatus />} />
          <Route path="/notify-speaker" element={<NotifySpeaker />} />
          <Route path="/search-from-speaker-database-new" element={<SearchFromSpeakerDatabaseNew />} />
          <Route path="/speaker-types" element={<SpeakerType />} />
          <Route path="/view-speakers" element={<ViewSpeakers />} />
          <Route path="/create-event" element={<CreateEvent />} />
          <Route path="/edit-event" element={<EditEvent />} />
          <Route path="/link-expired" element={<LinkExpired />} />
          </Routes>
          </BrowserRouter>
    </div>
    </>
  );
}

export default App;
