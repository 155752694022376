import React from 'react'
import speakerENgageLogo from '../../../Assets/images/Speaker-Engage-Logo-svg.svg';
import upgradeIconImg from '../../../Assets/images/upgrade-icon.svg';
import helpImg from '../../../Assets/images/help.svg';
import userDefaultImg from '../../../Assets/images/user-default-icon.jpg'

const Header1 = () => {
  return (
    <nav className="navbar fixed-top navbar-expand-lg bg-white main-header">
  <div className="container-fluid">
    <a href="https://www.speakerengage.com/" className="navbar-brand">
      <img
        src={speakerENgageLogo}
        className="dashboard-logo"
      />
    </a>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarScroll"
      aria-controls="navbarScroll"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarScroll">
      <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"></ul>
      <ul className="d-flex list-none align-items-center header-left">
        <li>
          <a href="plan-and-billing-info.php" className="upgradebtn">
            <img
              src={upgradeIconImg}
              className="upgrade-img"
            />
            <span style={{ color: "#fff" }}>days to Upgrade</span>
          </a>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle nav-arrow-none"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <img
              src={helpImg}
              className="w-30"
            />
          </a>
          <ul className="dropdown-menu dropdown-menu-help">
            <li>
              <a href="request-demo.php">Request Demo</a>
            </li>
            <li>
              <a href="speaker-engage-tutorial.php">Tutorials</a>
            </li>
            <li>
              <a href="../faqs/" target="_blank">
                FAQs
              </a>
            </li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle nav-arrow-none d-flex"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <img
              src={userDefaultImg}
              className="user-image"
            />
            <div>
              <p className="user-name">
                Kavitha
                <br />
              </p>
              <p className="user-orgname">Kavithasolutions</p>
              <p />
            </div>
          </a>
          <ul className="dropdown-menu dropdown-menu-user ">
            <li className="profile-text">
              <a href="edit-user-management.php?id=NTQx">Profile</a>
            </li>
            <hr className="dropdown-divider" />
            <li>
              <a href="plan-and-billing-info.php">Plan &amp; Billing Info</a>
            </li>
            <li>
              <a href="admin-user-management.php">User Management</a>
            </li>
            <li>
              <a href="logon_history.php">Login History</a>
            </li>
            <li>
              <a href="user-notification.php">User Notification</a>
            </li>
            <li>
              <a href="logout.php?eid=NDI=:NTMx">Logout</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

  )
}

export default Header1