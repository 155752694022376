import React from "react";
import Footer1 from "../../Navigations/footer/footer1";
import Header1 from "../../Navigations/header/header1";
import UploadImg from "../../../Assets/images/UploadLight.png";
import cloudComputingImg from '../../../Assets/images/cloud-computing.png';

function CreateEvent() {
  return (
    <>
      <>
        <div className="body-main bg-grey">
          <Header1 />
          <div className="body-page main-height">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="full-page-form">
                  <p className="form-title text-center">Create Event</p>
                  <div className="event-switch form-check form-switch my-4">
                    <label
                      className="form-check-label switch"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Check if start and end dates are the same
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <form id="eventForm">
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Event Name<span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="event_name"
                            id="event_name"
                            className="form-control sp-form-input"
                            placeholder="Event Name"
                          />
                          <p className="error-msg" id="error-msg-name" />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Event Start Date{" "}
                          </label>
                          <input
                            type="date"
                            id="startDate"
                            name="startDate"
                            className="form-control sp-form-input"
                            placeholder="Start Date"
                          />
                          <p className="error-msg" id="error-msg-startdate" />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Event End Date{" "}
                          </label>
                          <input
                            type="date"
                            id="endDate"
                            name="endDate"
                            className="form-control sp-form-input"
                            placeholder="End Date"
                          />
                          <p className="error-msg" id="error-msg-enddate" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Event Location Name<span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            name="event_location"
                            id="event_location"
                            className="form-control sp-form-input"
                            placeholder="Event Location"
                          />
                          <p className="error-msg" id="error-msg-elocation" />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Timezone{" "}
                          </label>
                          <select
                            name="timezone"
                            id="timezone"
                            className="form-control form-select sp-form-input"
                            aria-label="Default select example"
                          >
                            <option value="">Select Timezone</option>
                            {"{"}% for timezoneinfo in timezone_data %{"}"}
                            <option value="{{ timezoneinfo['se_timezone_id'] }}">
                              {"{"}
                              {"{"} timezoneinfo['se_timezone'] {"}"}
                              {"}"}
                            </option>
                            {"{"}% endfor %{"}"}
                          </select>
                          <p className="error-msg" id="error-msg-etimezone" />
                        </div>
                      </div>
                    </div>
                    <p className="form-title text-left mb-4 mt-4">
                      Event Location
                    </p>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Address Line1
                          </label>
                          <input
                            type="text"
                            name="address1"
                            id="address1"
                            className="form-control sp-form-input"
                            placeholder="Address"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Address Line2
                          </label>
                          <input
                            type="text"
                            name="address2"
                            id="address2"
                            className="form-control sp-form-input"
                            placeholder="Address"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Country <span className="red">*</span>{" "}
                          </label>
                          <select
                            name="Country"
                            id="Country"
                            className="form-control form-select sp-form-input"
                          >
                            <option value="">Select Country</option>
                            {"{"}% for countryinfo in country_data %{"}"}
                            <option value="{{ countryinfo['se_country_id'] }}">
                              {"{"}
                              {"{"} countryinfo['se_country_name'] {"}"}
                              {"}"}
                            </option>
                            {"{"}% endfor %{"}"}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            State{" "}
                          </label>
                          <select
                            name="state"
                            id="state"
                            className="form-control form-select sp-form-input"
                          ></select>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className="form-control sp-form-input"
                            placeholder="City"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            ZIP
                          </label>
                          <input
                            type="text"
                            name="zip"
                            id="zip"
                            className="form-control sp-form-input"
                            placeholder="ZIP"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="form-title text-left mb-4 mt-4">
                      Event Details
                    </p>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Event Registration URL
                          </label>
                          <input
                            type="text"
                            name="event_url"
                            id="event_url"
                            className="form-control sp-form-input"
                            placeholder="Event Registration URL"
                          />
                        </div>
                        <div className="copy-main">
                          <div className="input-main">
                            <label className="form-label sp-form-label">
                              Speaker Application URL{" "}
                            </label>
                            <input
                              type="text"
                              name="spk_app_url"
                              id="spk_app_url"
                              className="form-control sp-form-input"
                              placeholder="Event Application URL"
                            />
                          </div>
                          <div className="copy">
                            <button className="copy-btn">Copy</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-main">
                          <label className="form-label sp-form-label">
                            Event Quote/Description{" "}
                          </label>
                          <textarea
                            className="form-control sp-form-input"
                            placeholder="Event Quote/Description"
                            rows={5}
                            name="event_desc"
                            id="event_desc"
                            defaultValue={"                           "}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="form-title text-left mb-4 mt-4">
                      Image Upload
                    </p>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-main">
                          <div className="upload-label">
                            <label className="form-label sp-form-label">
                              Email Banner Image
                            </label>
                            <p className="help-imgtext">
                              Recommended size 1500X430 Px
                            </p>
                          </div>
                          <div className="image_area sp-form-uplopad">
                            {/* <form method="post"> */}
                            <label
                              htmlFor="upload_image"
                              className="form-upload"
                            >
                              <img
                                src={UploadImg}
                                id="uploaded_image"
                                className="img-responsive img-circle"
                              />
                              <input
                                type="file"
                                name="upload_image"
                                className="image"
                                id="upload_image"
                                style={{ display: "none" }}
                              />
                              <input
                                type="hidden"
                                name="event_banner_image"
                                id="event_banner_image"
                                defaultValue=""
                              />
                            </label>
                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-main">
                          <div className="upload-label">
                            <label className="form-label sp-form-label">
                              Apply Form Image
                            </label>
                            <p className="help-imgtext">
                              Recommended size 300X300 Px
                            </p>
                          </div>
                          <div className="image_area sp-form-uplopad">
                            {/* <form method="post"> */}
                            <label htmlFor="form_image" className="form-upload">
                              <img
                                src={cloudComputingImg}
                                id="form_uploaded_image"
                                className="img-responsive img-circle"
                              />
                              <input
                                type="file"
                                name="form_image"
                                className="form_image"
                                id="form_image"
                                style={{ display: "none" }}
                              />
                              <input
                                type="hidden"
                                name="event_form_image"
                                id="event_form_image"
                                defaultValue=""
                              />
                            </label>
                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="input-main">
                          <label className="form-label sp-form-label form-title">
                            Speaker Acknowledgement{" "}
                          </label>
                          <textarea
                            className="form-control sp-form-input"
                            rows={4}
                            name="event_ack"
                            id="event_ack"
                            defaultValue={
                              "Speakers acknowledge and agree that the Summit organizer may take photographs/videos which could include images of the Speaker while attending the Exhibition and Conference (the 'Images'). The Speaker hereby consents to and grants to the organizer, unrestricted, irrevocable, perpetual, worldwide, royalty free and transferable right and license to use (and grant others the right to use) the Images worldwide without compensation.\n                        "
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="input-main">
                          <label className="form-label sp-form-label form-title">
                            Thank You Message: Apply Speaker Form{" "}
                          </label>
                          <textarea
                            className="form-control sp-form-input"
                            rows={4}
                            name="event_message"
                            id="event_message"
                            defaultValue={
                              "Thank you for applying. We will get back to you in 24 hours.\n                        "
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-right">
                      <button type="submit" className="submit-btn">
                        SAVE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal1*/}
        <div
          className="modal fade"
          id="modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Crop Image Before Upload</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="img-container">
                  <div className="row">
                    <div className="col-md-8">
                      <img src="" id="banner_image" />
                    </div>
                    <div className="col-md-4">
                      <div className="preview" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" id="crop" className="btn btn-primary">
                  Crop
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal 2*/}
        <div
          className="modal fade"
          id="modal2"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Crop Image Before Upload</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="img-container">
                  <div className="row">
                    <div className="col-md-8">
                      <img src="" id="modal_form_image" />
                    </div>
                    <div className="col-md-4">
                      <div className="preview2" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" id="crop2" className="btn btn-primary">
                  Crop
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal2 End */}
        <div className="footer-2">
          <Footer1 />
        </div>
      </>
    </>
  );
}

export default CreateEvent;
