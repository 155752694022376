import React, { useEffect, useRef, useState } from "react";
import Footer1 from "../../Navigations/footer/footer1";
import Header1 from "../../Navigations/header/header1";
import welcome1Img from "../../../Assets/images/wel-1.svg";
import welcome2Img from "../../../Assets/images/wel-2.svg";
import welcome3Img from "../../../Assets/images/wel-3.svg";
import videoThumbImg from "../../../Assets/images/video-thump.png";
import speakerEngageVideo from "../../../Assets/images/Speaker-Engage-Explainer-Video-V3.mp4";
// import { Modal } from "bootstrap";
import { Modal } from "react-bootstrap";
import interviewIcon from "../../../Assets/images/interview.svg";
import folderIcon from "../../../Assets/images/folder.svg";
import uiIcon from "../../../Assets/images/ui.svg";
import splashWelIcon from "../../../Assets/images/splash-wel.png";
import iconOneIcon from "../../../Assets/images/icon-one.png";
import checklistIcon from "../../../Assets/images/checklis.svg";
import report2XIcon from "../../../Assets/images/report@2x.svg";
import iconsRoboIcon from "../../../Assets/images/icons-robo.svg";
import slpash2Icon from "../../../Assets/images/splash–2.svg";
import investorIcon from "../../../Assets/images/investor.svg";
import surface1Icon from "../../../Assets/images/surface1.svg";
import splash3Icon from "../../../Assets/images/splash–3.svg";

function SpeakerEngageLandingpage() {
  const [showModal, setShowModal] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    // Check if the video can autoplay and mute it
    const canAutoplay = video && video.autoplay !== undefined;
    if (canAutoplay) {
      video.muted = true;
      video.play().catch(error => {
        // Autoplay was prevented, handle accordingly
        console.error('Autoplay was prevented: ', error);
      });
    }
  }, []);

  useEffect(() => {
    // This runs when the component mounts
    setShowModal(true); // You can set this to false if you want to hide the modal initially
  }, []);
  // useEffect(() => {
  //   const infoModal = new Modal(document.getElementById('infoModal'));
  //   infoModal.show();

  //   // Function to dismiss the modal
  //   const dismissModal = () => {
  //     infoModal.hide(); // Hides the modal

  //   };

  //   // Attach event listener to the button
  //   const closeButton = document.querySelector('.btn-close');
  //   closeButton.addEventListener('click', dismissModal);

  //   // Clean up the event listener
  //   return () => {
  //     infoModal.dispose();
  //     closeButton.removeEventListener('click', dismissModal);
  //   };
  // }, []);

  return (
    <>
      <div className="body-main bg-grey">
        <Header1 />
        <div className="body-page main-height">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <p className="landing-text1">
                Welcome to Speaker Engage Dashboard!
              </p>
              <p className="landing-text2">Let's get started</p>
              <div className="row mt-5 pt-3">
                <div className="col text-center">
                  <a href="/create-event">
                    <img src={welcome1Img} className="land-img w-70" />
                    <p className="land-text violet">CREATE EVENT</p>
                  </a>
                </div>
                <div className="col text-center">
                  <a href="/create-event">
                    <img src={welcome2Img} className="land-img w-70" />
                    <p className="land-text pink">ADD USERS</p>
                  </a>
                </div>
                <div className="col text-center">
                  <a href="/create-event">
                    <img src={welcome3Img} className="land-img w-55" />
                    <p className="land-text sky-blue">REQUEST A DEMO</p>
                  </a>
                </div>
              </div>
              <p className="landing-text-3 mt-5 pt-5">Need help?</p>
              <div
                data-bs-toggle="modal"
                data-bs-target="#videoModal"
                className="mt-4 video"
              >
                <img src={videoThumbImg} className="demo-thumb" />
              </div>
              {/* Modal */}
              <div
                className="modal fade"
                id="videoModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered w-1000 max-w-1000">
                  <div className="modal-content bg-black">
                    <div className="text-right mx-3 mt-3">
                      <button
                        type="button"
                        className="btn-close white-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body mb-4">
                      <div className="embed-responsive embed-responsive-16by9">
                        <video  ref={videoRef} id="video1" width="100%" height={500} controls>
                          <source src={speakerEngageVideo} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <div
        className="modal fade info-modal"
        id="infoModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      > */}

        <div className="modal-dialog-centered w-100 max-w-800 info-modal">
          <div className="modal-content">
            <div className="text-right mt-3">
              <button
                type="button"
                className="btn-close pink-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              />
            </div>
            <div className="modal-body">
              <div
                id="carouselExampleDark"
                className="carousel carousel-dark slide info-slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to={0}
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to={1}
                    aria-label="Slide 2"
                  />
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to={2}
                    aria-label="Slide 3"
                  />
                </div>
                <div className="carousel-inner">
                  <div
                    className="carousel-item active"
                    data-bs-interval={10000}
                  >
                    <div className="carousel-caption d-none d-md-block">
                      <p className="info-title">WELCOME TO SPEAKER ENGAGE!</p>
                      <div className="row mt-4">
                        <p className="info-subtitle">
                          Automated Workflows with AI Predictive Analytics
                        </p>
                        <div className="col-6 pt-5 mt-3">
                          <div className="d-flex align-items-center mb-5">
                            <img src={interviewIcon} className="info-icon" />
                            <p className="info-text">
                              Centrally curate and manage ongoing interaction
                              with Speakers
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-5">
                            <img src={folderIcon} className="info-icon" />
                            <p className="info-text">
                              Eliminate spreadsheets and multiple folders to
                              manage
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-5">
                            <img src={uiIcon} className="info-icon" />
                            <p className="info-text">
                              Access to real time dashboard
                            </p>
                          </div>
                        </div>
                        <div className="col-6">
                          <img src={splashWelIcon} className="info-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval={2000}>
                    <div className="carousel-caption d-none d-md-block">
                      <p className="info-title">WELCOME TO SPEAKER ENGAGE!</p>
                      <div className="row mt-4">
                        <p className="info-subtitle">
                          Automated Workflows with AI Predictive Analytics
                        </p>
                        <div className="col-6 pt-5 mt-3">
                          <div className="d-flex align-items-center mb-5">
                            <img src={iconOneIcon} className="info-icon" />
                            <p className="info-text">
                              Automated workflow to collect speaker profiles and
                              content by event
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-5">
                            <img src={checklistIcon} className="info-icon" />
                            <p className="info-text">
                              Agenda management via automated curation of
                              speakers
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-5">
                            <img src={report2XIcon} className="info-icon" />
                            <p className="info-text">
                              Speaker schedule management and communications
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-5">
                            <img src={iconsRoboIcon} className="info-icon" />
                            <p className="info-text">
                              AI predictive notifications for taking next best
                              actions
                            </p>
                          </div>
                        </div>
                        <div className="col-6">
                          <img src={slpash2Icon} className="info-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="carousel-caption d-none d-md-block">
                      <p className="info-title">WELCOME TO SPEAKER ENGAGE!</p>
                      <div className="row mt-4">
                        <p className="info-subtitle">Data Compliance</p>
                        <div className="col-6 pt-5 mt-3">
                          <div className="d-flex align-items-center mb-5">
                            <img src={investorIcon} className="info-icon" />
                            <p className="info-text">
                              Create a data lake of speakers and sponsors
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-5">
                            <img src={surface1Icon} className="info-icon" />
                            <p className="info-text">
                              Realize data standards, compliance and security
                              through the event execution without violating GDPR
                              and PII policies
                            </p>
                          </div>
                        </div>
                        <div className="col-6">
                          <img src={splash3Icon} className="info-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer" onClick={() => setShowModal(false)}>
              <button type="button" className="skip" data-bs-dismiss="modal">
                SKIP
              </button>
            </div>
          </div>
        </div>

        {/* </div> */}
      </Modal>
      <Footer1 />
    </>
  );
}

export default SpeakerEngageLandingpage;
