import React, { useState } from "react";
import logo from "../../Assets/images/se-logo-white.png";
import { MICROSOFT_AUTH_HANDLER, MICROSOFT_CLIENT_ID } from "../Utils/Constant";
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  LoginSocialApple,
  IResolveParams,
} from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  GithubLoginButton,
  AmazonLoginButton,
  InstagramLoginButton,
  LinkedInLoginButton,
  MicrosoftLoginButton,
  TwitterLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";
import { EmailCheck, LoginPasswordCheck } from "../Utils/Validation";

const SignIn = () => {
  const [email_address, setEmailAddress] = useState("");
  const [email_validation, setEmailValidation] = useState("");

  const [password, setPassword] = useState("");
  const [password_validation, setPasswordValidation] = useState("");

  const handleSignIn = () => {
    const emailValidation = EmailCheck(email_address);
    const passwordValidation = LoginPasswordCheck(password);
    if (emailValidation.err_display) {
      setEmailValidation(emailValidation.err_display);
    } else {
      setEmailValidation("");
    }
    if (passwordValidation.err_display) {
      setPasswordValidation(passwordValidation.err_display);
    } else {
      setPasswordValidation("");
    }
  };
  return (
    <div className="main vh-100 d-flex align-items-center justify-content-center bg-gray signinmain">
      <div className="container">
        <div className="row wrapper">
          <div className="col signinleft">
            <div className="signinleftinner">
              <p className="signin-text">Sign in to Get Started</p>
              <div className="social-login-main">
                <div className="d-flex social-login-inner">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px"
                    viewBox="0 0 48 48"
                    className="abcRioButtonSvg"
                  >
                    <g>
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      />
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      />
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      />
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      />
                      <path fill="none" d="M0 0h48v48H0z" />
                    </g>
                  </svg>
                  <p className="social-text">Sign in</p>
                </div>
                <div className="d-flex social-login-inner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            ".a{fill:#4caf50;}.b{fill:#f44336;}.c{fill:#2196f3;}.d{fill:#ffc107;}",
                        }}
                      />
                    </defs>
                    <path
                      className="a"
                      d="M272,9.375h9.375V.625A.625.625,0,0,0,280.75,0H272Z"
                      transform="translate(-261.375)"
                    />
                    <path
                      className="b"
                      d="M9.375,9.375V0H.625A.625.625,0,0,0,0,.625v8.75Z"
                    />
                    <path
                      className="c"
                      d="M9.375,272H0v8.75a.625.625,0,0,0,.625.625h8.75Z"
                      transform="translate(0 -261.375)"
                    />
                    <path
                      className="d"
                      d="M272,272v9.375h8.75a.625.625,0,0,0,.625-.625V272Z"
                      transform="translate(-261.375 -261.375)"
                    />
                  </svg>
                  <p className="social-text">Work Account</p>
                </div>
              </div>
              <div className="text-center orDivider font-bold">
                <p>
                  <span>or Sign in with Email</span>
                </p>
              </div>
              <div className="text-center mt-2">
                <p className="error-msg" id="error-msg" />
              </div>
              <div className="email-login py-4">
                <div className="mb-3">
                  <label className="sign-label">Email address</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    className="form-control signin-input"
                    onChange={(e) => [
                      setEmailAddress(e.target.value),
                      setEmailValidation(""),
                    ]}
                  />
                  {
                    email_validation ? <p className="error-msg" id="error-msg-email">{email_validation}</p>:null
                  }
                  
                </div>
                <div className="mb-3">
                  <label className="sign-label">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    className="form-control signin-input"
                    onChange={(e) => [
                      setPassword(e.target.value),
                      setPasswordValidation(""),
                    ]}
                  />
                  {
                    password_validation ?   <p className="error-msg" id="error-msg-password">{password_validation}</p>:null
                  }
                 
                  <p className="text-right">
                    <a className="forgotPassword" href="/forgot_password">
                      Forgot Password?
                    </a>
                  </p>
                </div>
                <button
                  type="submit"
                  id="loginBtn"
                  className="signin-submit"
                  onClick={handleSignIn}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="col signinright text-center">
            <div className="signinrightinner">
              <a href="https://www.speakerengage.com/">
                <img src={logo} className="whitelogo-login" />
              </a>
              <p className="dont-login">Don't have a login?</p>
              <div className="signuplink">
                <a className="signup1" href="/signup">
                  Sign up to start your journey
                </a>
                <br />
                <p className="signup2">
                  <a href="#">See Plans and Pricing</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
